import { InMemoryCache } from '@apollo/client'
import { uniqBy } from 'lodash-es'

export function createCache() {
  const cache = new InMemoryCache({
    possibleTypes: {
      Interaction: [
        'InteractionClick',
        'InteractionPoll',
        'InteractionRedemption',
        'InteractionRedemptionOffer',
        'InteractionReply',
      ],
      PollOption: ['PollTextOption'],
      ConversationsEvent: [
        'BlockEvent',
        'ConversationEvent',
        'MediaEvent',
        'MessageEvent',
        'ParticipantSettingsEvent',
        'TypingEvent',
      ],
      RedemptionLimit: ['RedemptionLimitCount', 'RedemptionLimitRate'],
    },
    typePolicies: {
      InteractionRedemptionOffer: {
        merge: true,
      },
      PollTextOption: {
        keyFields: false,
      },
      Subscription: {
        keyFields: ['__typename', 'type', ['__ref']],
        fields: {
          conversationEvent: {
            merge: false,
          },
          conversationsEvent: {
            merge: false,
          },
        },
      },
      Conversation: {
        fields: {
          messages: {
            merge(existing = [], incoming) {
              return uniqBy([...incoming, ...existing], '__ref')
            },
          },
          messagePreview: {
            merge(_existing, incoming) {
              return incoming
            },
          },
          blocks: {
            merge(_existing = [], incoming) {
              return incoming
            },
          },
        },
      },
      Message: {
        fields: {
          emojiReactions: {
            merge(_existing, incoming) {
              return incoming
            },
          },
        },
      },
      Query: {
        fields: {
          feedItems: {
            merge(_existing, incoming) {
              return incoming
            },
          },
        },
      },
      // ! We need to determine how to key off things coming across
      // ! the websocket better
      // MessageEvent: {
      //   keyFields: (obj) => {
      //     if (!obj?.messageParts) {
      //       return undefined
      //     }
      //     if (typeof obj.messageParts !== 'object') {
      //       return undefined
      //     }
      //     const id = (obj.messageParts as any).id
      //     return id ? `Message:${id}` : undefined
      //   },
      // },
      Wallet: {
        fields: {
          adjustments: {
            keyArgs: ['type'],
            merge(_existing, incoming) {
              if (_existing) {
                return {
                  ..._existing,
                  ...incoming,
                  data: uniqBy([..._existing.data, ...incoming.data], '__ref'),
                }
              } else {
                return incoming
              }
            },
          },
        },
      },
    },
  })

  return cache
}
