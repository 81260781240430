export const ConversationRoutes = [
  {
    path: 'conversations/',
    async lazy() {
      const { ConversationsPage } = await import('./ConversationsPage')
      return {
        Component: ConversationsPage,
      }
    },
    children: [
      {
        path: '',
        async lazy() {
          const { EmptyDialogueScreen } = await import(
            './Dialogue/EmptyDialogue.screen'
          )
          return {
            Component: EmptyDialogueScreen,
          }
        },
      },
      {
        path: ':conversationId',
        async lazy() {
          const { DialogueScreen } = await import('./Dialogue/Dialogue.screen')
          return {
            Component: DialogueScreen,
          }
        },
      },
    ],
  },
]
