import { Center } from '@mantine/core'
import { ErrorBoundary } from 'react-error-boundary'
import { RequireVendor } from '../../routes/guards/RequireVendor'

export const VendorAnalyticsRoutes = [
  {
    path: 'analytics',
    async lazy() {
      const { VendorAnalyticsPage } = await import('./VendorAnalytics.page')
      return {
        element: (
          <ErrorBoundary fallback={<Center>Something went wrong</Center>}>
            <RequireVendor>
              <VendorAnalyticsPage />
            </RequireVendor>
          </ErrorBoundary>
        ),
      }
    },
  },
]
