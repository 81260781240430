// ? 🌎 https://capacitorjs.com/docs/guides/deep-links

import { App, URLOpenListenerEvent } from '@capacitor/app'
import { PluginListenerHandle } from '@capacitor/core'
import { isShortLink, resolveAndNavigate, trackUri } from '@util/linkUtils'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

const AppUrlListener: React.FC<any> = () => {
  const navigate = useNavigate()

  useEffect(() => {
    let listener: PluginListenerHandle | null = null
    App.addListener('appUrlOpen', (event: URLOpenListenerEvent) => {
      const url = new URL(event.url)
      // If the url is a short link, resolve it and redirect accordingly
      // Example https://link.sway.dm/s/3997_dO1gKY
      if (isShortLink(url)) {
        resolveAndNavigate(url, navigate)
      } else {
        // Will redirect to the path after the domain, which includes query params.
        // Example: /profile/sonicthehedgehog?rings=23
        const slug = url.href.split(url.origin).pop()

        if (slug) {
          trackUri(url.toString())
          navigate(slug)
        }
      }
    }).then((l) => {
      // There may be a better way to do this, but using await in useEffect was causing issues.
      listener = l
    })
    return () => {
      // We need to remove the listener, otherwise there will be multiple listeners, and issues start to happen.
      listener?.remove()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigate])

  return null
}

export default AppUrlListener
