import { faQrcode } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Divider, Group } from '@mantine/core'

export function QrCodeScanButton({
  onQrCodeScan,
}: {
  onQrCodeScan?: () => void
}) {
  return (
    <Group wrap="nowrap">
      <Divider orientation="vertical" />
      <Button
        variant="transparent"
        leftSection={<FontAwesomeIcon icon={faQrcode} />}
        onClick={onQrCodeScan}
        size="compact-md"
      >
        Scan
      </Button>
    </Group>
  )
}
