import { faChevronRight } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Avatar, Box, Card, Group, Text } from '@mantine/core'
import { useProfileQuery } from '@swaydm/graphql'
import { Link } from 'react-router-dom'

export function ProfileInMessagePreview({
  profileId,
  withBorder = false,
  withChevron = false,
}: {
  profileId: string
  withBorder?: boolean
  withChevron?: boolean
}) {
  const { data, loading } = useProfileQuery({
    variables: { displayName: profileId },
  })
  if (loading || !data?.profile) {
    return null
  }
  const profile = data.profile
  return (
    <Box p={8} mih={100}>
      <Card
        component={Link}
        to={`/profile/${profileId}`}
        withBorder={withBorder}
      >
        <Group justify="space-between">
          <Group>
            <Avatar
              size="md"
              src={profile.profilePictureUrl}
              // name={profile.displayName}
              mr={2}
            />
            <Text size="lg">{profile.displayName}</Text>
          </Group>
          {withChevron && <FontAwesomeIcon icon={faChevronRight} />}
        </Group>
      </Card>
    </Box>
  )
}
