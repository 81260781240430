import { createFormContext } from '@mantine/form'
import {
  BroadcastInput,
  MessageTemplate,
  MessageTemplateInput,
} from '@swaydm/graphql'

// Checks if a broadcast message template contains default values.
// Useful for determining if a user has made changes to the message template defined in the `initial values` of the form.
export function isMessageTemplateDefault(
  messageTemplate: MessageTemplate | undefined
) {
  return (
    messageTemplate?.body == '' && (messageTemplate?.options || []).length == 0
  )
}

// Broadcast form values.
export type BroadcastFormValues = Omit<BroadcastInput, 'messageTemplate'> & {
  messageTemplate: ParsedMessageTemplateInput
}

type ParsedMessageTemplateInput = Omit<
  MessageTemplateInput,
  'redemptionOffers'
> & {
  templateRedemptionOffers: string[]
}

// Form context for broadcasts forms.
export const [
  BroadcastFormProvider,
  useBroadcastFormContext,
  useBroadcastForm,
] = createFormContext<BroadcastFormValues>()
