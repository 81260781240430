import { Center } from '@mantine/core'
import { ErrorBoundary } from 'react-error-boundary'
import { RequireVendor } from '../../routes/guards/RequireVendor'

export const VendorBroadcastRoutes = [
  {
    path: 'broadcasts',
    async lazy() {
      const { BroadcastsPage } = await import('./Broadcasts.page')
      return {
        element: (
          <ErrorBoundary fallback={<Center>Something went wrong</Center>}>
            <RequireVendor>
              <BroadcastsPage />
            </RequireVendor>
          </ErrorBoundary>
        ),
      }
    },
    children: [
      {
        path: '',
        async lazy() {
          const { AllBroadcastsScreen } = await import(
            './screens/AllBroadcasts.screen'
          )
          return {
            Component: AllBroadcastsScreen,
          }
        },
      },
      {
        path: 'drafts',
        async lazy() {
          const { AllDraftBroadcastsScreen } = await import(
            './screens/AllDraftBroadcasts.screen'
          )
          return {
            Component: AllDraftBroadcastsScreen,
          }
        },
      },
      {
        path: 'active',
        async lazy() {
          const { AllActiveBroadcastsScreen } = await import(
            './screens/AllActiveBroadcasts.screen'
          )
          return {
            Component: AllActiveBroadcastsScreen,
          }
        },
      },
      {
        path: 'completed',
        async lazy() {
          const { AllCompletedBroadcastsScreen } = await import(
            './screens/AllCompletedBroadcasts.screen'
          )
          return {
            Component: AllCompletedBroadcastsScreen,
          }
        },
      },
      {
        path: 'archived',
        async lazy() {
          const { ArchivedBroadcastsScreen } = await import(
            './screens/ArchivedBroadcasts.screen'
          )
          return {
            Component: ArchivedBroadcastsScreen,
          }
        },
      },
      {
        path: 'stats/:broadcastId',
        async lazy() {
          const { BroadcastStatsScreen } = await import(
            './screens/BroadcastStats.screen'
          )
          return {
            Component: BroadcastStatsScreen,
          }
        },
      },
      {
        path: 'new',
        async lazy() {
          const { RedirectPage } = await import('../Redirect.page')
          return {
            element: <RedirectPage route="/" />,
          }
        },
      },
      {
        path: 'edit/:broadcastId',
        async lazy() {
          const { EditBroadcastScreen } = await import(
            './screens/EditBroadcast.screen'
          )
          return {
            Component: EditBroadcastScreen,
          }
        },
      },
      {
        path: '*',
        async lazy() {
          const { RedirectPage } = await import('../Redirect.page')
          return {
            element: <RedirectPage route="/" />,
          }
        },
      },
    ],
  },
]
