import { AnalyticsBrowser } from '@segment/analytics-next'
import React, { createContext, useMemo } from 'react'

export interface AnalyticsContextProps {
  analytics?: AnalyticsBrowser
}

const defaultAnalyticsContext: AnalyticsContextProps = {
  analytics: undefined,
}

export const AnalyticsContext = createContext<AnalyticsContextProps>(
  defaultAnalyticsContext
)

interface AnalyticsProviderProps {
  children: React.ReactNode
}

export const AnalyticsProvider: React.FC<AnalyticsProviderProps> = ({
  children,
}) => {
  const writeKey = import.meta.env.VITE_ROOT_SEGMENT_TOKEN ?? ''

  const value = useMemo(() => {
    if (writeKey) {
      return { analytics: AnalyticsBrowser.load({ writeKey }) }
    } else {
      return defaultAnalyticsContext // Return undefined as there is no key
    }
  }, [writeKey])

  return (
    <AnalyticsContext.Provider value={value}>
      {children}
    </AnalyticsContext.Provider>
  )
}
