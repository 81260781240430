import {
  faArrowsRotate,
  faGripVertical,
  faLink,
  faPencil,
  faTrashCan,
  faUser,
  faUsers,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { DragDropContext, Draggable, Droppable } from '@hello-pangea/dnd'
import {
  ActionIcon,
  Avatar,
  Button,
  Card,
  Flex,
  Grid,
  GridCol,
  Group,
  Select,
  Stack,
  TextInput,
  Textarea,
  Title,
} from '@mantine/core'
import { useForm, zodResolver } from '@mantine/form'
import { notifications } from '@mantine/notifications'
import {
  EditableVendor,
  ProfileLink,
  ProfileLinkInput,
  SignedUrlKey,
} from '@swaydm/graphql'
import React, { useCallback, useState } from 'react'
import isUrl from 'validator/lib/isURL'
import { z } from 'zod'
import {
  UPLOAD_STATE,
  UploadStatusIndicator,
  downloadUrlForKey,
  getCroppedImg,
} from '../..'
import {
  ImageCropper,
  ImageCropperResult,
} from '../../components/media/ImageCropper'
import { stateList, timezoneList } from './EditVendorProfileForm.constants'

export type EditProfileRequest = {
  profilePhotoMediaPointerId?: string
  profilePhotoUrl: string
  profilePhotoCrop?: string
  communityName: string
  displayName: string
  introduction?: string
  city?: string
  state?: string
  timezone?: string
  firstName?: string
  lastName?: string
  profileLinks: Array<ProfileLinkInput>
}

export interface GridProps {
  children: React.ReactNode
  columns: number
}

const calcUploadState = (media: {
  uploadProgress: number
  uploadState: UPLOAD_STATE
}) => {
  if (media.uploadState === UPLOAD_STATE.Error) {
    return UPLOAD_STATE.Error
  }

  return media.uploadProgress < 100
    ? UPLOAD_STATE.Uploading
    : UPLOAD_STATE.MediaAvailable
}

const editProfileSchemaValidation = z.object({
  communityName: z.string().min(1).max(100),
  displayName: z.string().min(1).max(100),
  introduction: z
    .string()
    .optional()
    .refine((val) => !val || (val.length >= 1 && val.length <= 500), {
      message: 'Introduction must be between 1 and 500 characters long',
    }),
  city: z
    .string()
    .optional()
    .refine((val) => !val || (val.length >= 1 && val.length <= 100), {
      message: 'City must be between 1 and 100 characters long',
    }),
  state: z
    .string()
    .optional()
    .refine((val) => !val || (val.length >= 1 && val.length <= 100), {
      message: 'State must be between 1 and 100 characters long',
    }),
  timezone: z.string().min(1).max(100),
  firstName: z.string().min(1).max(100),
  lastName: z.string().min(1).max(100),
  profileLinks: z
    .array(
      z
        .object({
          title: z.string().optional(),
          url: z
            .string()
            .optional()
            .refine((value) => !value || isUrl(value), {
              message: 'Invalid URL',
            }),
        })
        .refine(
          (obj) => {
            const bothProvided = obj.title && obj.url
            const neitherProvided = !obj.title && !obj.url
            return bothProvided || neitherProvided
          },
          {
            message: 'Title and URL are required',
            path: ['title'],
          }
        )
    )
    .optional(),
})

export const EditVendorProfileForm = ({
  editableProfile,
  uploadMediaContext,
  onSubmit,
}: {
  editableProfile: EditableVendor
  loading?: boolean
  uploadMediaContext: {
    selectMedia: () => void
    attachedMedia: any[]
    clearAttachedMedia: (mediaPointerId?: string) => void
  }
  onCancel?: () => void
  onSubmit: (data: EditProfileRequest) => Promise<boolean> | void
}) => {
  const defaultValues = {
    profilePhotoMediaPointerId:
      editableProfile.profilePhotoMediaPointerId?.valueOf(),
    profilePhotoUrl: editableProfile.profilePhotoUrl,
    profilePhotoCrop: editableProfile.profilePhotoCrop?.valueOf(),
    communityName: editableProfile.vendorName || '',
    displayName: editableProfile.displayName || '',
    introduction: editableProfile.bio?.valueOf() || '',
    city: editableProfile.city?.valueOf() || '',
    state: editableProfile.state?.valueOf() || '',
    timezone: editableProfile.timezone?.valueOf() || '',
    firstName: editableProfile.firstName?.valueOf() || '',
    lastName: editableProfile.lastName?.valueOf() || '',
    profileLinks:
      editableProfile.profileLinks !== undefined
        ? editableProfile.profileLinks?.map((link: ProfileLink) => {
            return {
              id: link.id || '',
              title: link.title || '',
              url: link.url || '',
            }
          })
        : [],
  }

  const form = useForm<EditProfileRequest>({
    initialValues: defaultValues,
    validate: zodResolver(editProfileSchemaValidation),
  })

  const onSubmitForm = useCallback(
    async (data: EditProfileRequest) => {
      form.validate()

      if (!form.isValid()) {
        const errorKeys = Object.keys(form.errors)
        const errors = errorKeys.map((key) => {
          return `${key}: ${form.errors[key]}`
        })

        if (errors.length && errors.length > 0) {
          notifications.show({
            title: 'Error',
            message: errors[0],
            autoClose: 5000,
          })
        } else {
          notifications.show({
            title: 'Error',
            message: 'There was an error submitting the form',
            autoClose: 5000,
          })
        }
        return
      }

      const success = await onSubmit(data)
      if (success) {
        form.resetDirty()
        form.resetTouched()
      }
    },
    [form, onSubmit]
  )

  const addProfileLink = () => {
    form.insertListItem('profileLinks', { id: '', title: '', url: '' })
  }

  const removeProfileLink = (index: number) => {
    form.removeListItem('profileLinks', index)
  }

  const [showManageProfilePhoto, setShowManageProfilePhoto] = useState(false)
  const [croppedImageUrl, setCroppedImageUrl] = useState<string>(
    editableProfile?.profilePhotoUrl
  )

  const revertChanges = () => {
    form.reset()
    setCroppedImageUrl(editableProfile?.profilePhotoUrl)
  }

  const uploadedAttachedMedia =
    uploadMediaContext?.attachedMedia[
      uploadMediaContext?.attachedMedia.length - 1
    ]

  let originalImageUrl

  if (editableProfile.profilePhotoMediaPointer) {
    originalImageUrl = downloadUrlForKey(
      editableProfile.profilePhotoMediaPointer,
      SignedUrlKey.Proxy
    )
  } else {
    originalImageUrl = editableProfile.profilePhotoUrl
  }

  const underEditProfileUrl =
    uploadedAttachedMedia?.original?.url || originalImageUrl

  const updateFromCrop = (imageCropperResult: ImageCropperResult) => {
    if (uploadedAttachedMedia && uploadedAttachedMedia.mediaPointerId) {
      form.setValues({
        profilePhotoMediaPointerId: uploadedAttachedMedia.mediaPointerId,
      })
    }

    if (imageCropperResult?.croppedAreaPixels) {
      getCroppedImg(
        underEditProfileUrl,
        imageCropperResult.croppedAreaPixels,
        imageCropperResult.rotation
      ).then((url) => setCroppedImageUrl(url))
      const { x, y, width, height } = imageCropperResult.croppedAreaPixels
      const result = [
        Math.trunc(x),
        Math.trunc(y),
        Math.trunc(width),
        Math.trunc(height),
      ].join(',')
      form.setValues({ profilePhotoCrop: result })
      setShowManageProfilePhoto(false)
    }
  }

  if (showManageProfilePhoto) {
    return (
      <ImageCropper
        src={underEditProfileUrl}
        onCancel={() => setShowManageProfilePhoto(false)}
        onUpdateCrop={updateFromCrop}
        onUploadMedia={uploadMediaContext?.selectMedia}
        preventUpdate={
          uploadedAttachedMedia && uploadedAttachedMedia.uploadProgress < 100
        }
      >
        <div className="flex justify-center p-4">
          {uploadedAttachedMedia && (
            <UploadStatusIndicator
              progress={uploadedAttachedMedia.uploadProgress}
              uploadState={calcUploadState(uploadedAttachedMedia)}
              onClickClose={() =>
                uploadMediaContext?.clearAttachedMedia(
                  uploadedAttachedMedia.mediaPointerId
                )
              }
              imgSrc={uploadedAttachedMedia.thumbnail.localUrl}
            />
          )}
        </div>
      </ImageCropper>
    )
  }

  return (
    <>
      <form
        className="w-full"
        onSubmit={form.onSubmit((values) => {
          onSubmitForm(values)
        })}
      >
        <Stack w="100%" gap="md">
          <Group justify="center" gap={'md'}>
            <Button
              onClick={revertChanges}
              type="button"
              variant="outline"
              disabled={!form.isDirty()}
            >
              <FontAwesomeIcon icon={faArrowsRotate} className="mr-4 h-5 w-5" />{' '}
              Revert
            </Button>
            <Button type="submit" disabled={!form.isDirty()}>
              Save
            </Button>
          </Group>
          <hr />

          {/* - Vendor Info */}
          <div className="inline-flex gap-4">
            <FontAwesomeIcon icon={faUsers} className="h-8 w-8" />
            <Title order={2}>Vendor Info</Title>
          </div>
          <Flex justify="center" pb={8}>
            <Avatar src={croppedImageUrl} className="h-24 w-24" />
            <ActionIcon
              className="-ml-6 self-end"
              type="button"
              radius="xl"
              size="lg"
              onClick={() => setShowManageProfilePhoto(true)}
            >
              <FontAwesomeIcon icon={faPencil} />
            </ActionIcon>
          </Flex>
          <Grid>
            <GridCol
              span={{
                xs: 12,
                md: 6,
              }}
            >
              <TextInput
                label="Vendor Name"
                description="Name of your company or organization"
                placeholder="Company LLC"
                {...form.getInputProps('communityName')}
              />
            </GridCol>
            <GridCol span={{ xs: 12, md: 6 }}>
              <TextInput
                label="Username"
                description="Your username for your company or organization"
                placeholder="@company"
                {...form.getInputProps('displayName')}
              />
            </GridCol>
          </Grid>

          {/* introduction */}

          <Textarea
            w="100%"
            label="Introduction"
            description="Short intro about your company"
            resize="vertical"
            minRows={3}
            autosize
            {...form.getInputProps('introduction')}
          />

          <Flex gap={'md'}>
            <TextInput
              w="100%"
              label="City"
              description="City where your company is located"
              placeholder="Austin"
              {...form.getInputProps('city')}
            />

            <Select
              w="100%"
              label="State"
              description="State where your company is located"
              placeholder="TX"
              {...form.getInputProps('state')}
              data={stateList}
              autoCapitalize="characters"
              searchable
            />
          </Flex>

          {/* Timezone */}
          <Stack w="100%">
            <Select
              label="Timezone"
              description="Timezone where your company is located"
              {...form.getInputProps('timezone')}
              data={timezoneList}
              searchable
            />
          </Stack>
          <hr />

          {/* - About You */}
          <Flex gap={16}>
            <FontAwesomeIcon icon={faUser} className="h-8 w-8" />
            <Title order={2}>About You</Title>
          </Flex>

          <Grid>
            <GridCol span={{ xs: 12, md: 6 }}>
              <TextInput
                label="First Name"
                description="Your first name"
                placeholder="John"
                {...form.getInputProps('firstName')}
              />
            </GridCol>

            <GridCol span={{ xs: 12, md: 6 }}>
              <TextInput
                label="Last Name"
                description="Your last name"
                placeholder="Doe"
                {...form.getInputProps('lastName')}
              />
            </GridCol>
          </Grid>

          <hr />
          {/* -Links */}
          <div className="inline-flex gap-4">
            <FontAwesomeIcon icon={faLink} className="h-8 w-8" />
            <Title order={2}>Links</Title>
          </div>
          <DragDropContext
            onDragEnd={({ destination, source }) =>
              form.reorderListItem('profileLinks', {
                from: source.index,
                to: destination?.index || 0,
              })
            }
          >
            <Droppable droppableId="profileLinks" direction="vertical">
              {(provided) => (
                <div {...provided.droppableProps} ref={provided.innerRef}>
                  {form.values.profileLinks.map((link, index) => (
                    <Draggable
                      key={`${link.id}-${index}`}
                      draggableId={link.title}
                      index={index}
                    >
                      {(provided) => (
                        <Card
                          shadow="md"
                          withBorder
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                        >
                          <Flex align="center" gap={12}>
                            <span
                              className="py-auto col-span-2 flex items-center p-4"
                              {...provided.dragHandleProps}
                            >
                              <FontAwesomeIcon
                                icon={faGripVertical}
                                className="my-auto"
                              />
                            </span>

                            <Grid flex={1}>
                              <GridCol
                                span={{ xs: 12, md: 6 }}
                                style={{ gridRow: '1/2' }}
                              >
                                <TextInput
                                  {...form.getInputProps(
                                    `profileLinks.${index}.title`
                                  )}
                                  label="Title"
                                  placeholder="Enter the title"
                                  description="The title of the link"
                                  data-no-dnd="true"
                                />
                              </GridCol>
                              <GridCol
                                span={{ xs: 12, md: 6 }}
                                style={{ gridRow: '1/2' }}
                              >
                                <TextInput
                                  {...form.getInputProps(
                                    `profileLinks.${index}.url`
                                  )}
                                  label="URL"
                                  placeholder="Enter the URL"
                                  description="The URL of the link"
                                  data-no-dnd="true"
                                />
                              </GridCol>
                            </Grid>

                            <span
                              className="col-span-2 flex flex-row"
                              data-no-dnd="true"
                            >
                              <a
                                data-no-dnd="true"
                                className="m-auto cursor-pointer bg-transparent"
                                type="button"
                                onClick={() => removeProfileLink(index)}
                              >
                                <FontAwesomeIcon
                                  icon={faTrashCan}
                                  className="my-auto h-6 w-6 fill-current"
                                />
                              </a>
                            </span>
                          </Flex>
                        </Card>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
          <Button type="button" onClick={() => addProfileLink()}>
            Add Link
          </Button>
        </Stack>
      </form>
    </>
  )
}
