import { ApolloClient, ApolloLink, createHttpLink, split } from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { onError } from '@apollo/client/link/error'
import { GraphQLWsLink } from '@apollo/client/link/subscriptions'
import { getMainDefinition, print } from '@apollo/client/utilities'
import { Capacitor } from '@capacitor/core'
import useAppContext from '@context/appContext/useAppContext'
import { LogoutDocument } from '@swaydm/graphql'
import { createCache } from '@util/apollo'
import { createClient } from 'graphql-ws'
import { useEffect, useMemo, useState } from 'react'
import { TEN_MINUTES } from '../constants'
import { useAuth } from './useAuth'

let hookHasBeenUsed = false

const ROOT_API_SERVER_URL = import.meta.env.VITE_ROOT_API_SERVER_URL
const ROOT_WEBSOCKET_URL = import.meta.env.VITE_ROOT_WS_SERVER_URL

/**
 * * Our version of the Apollo Client configured for Sway.
 * ! This is a hook, and so if it is used in multiple places, it will return a unique client for each place it is used.
 * ? If you need to share a client between multiple components, you should use Apollo's context provider.
 * @returns The Apollo Client with the correct configuration for the Sway API
 */
export function useSwayApolloClient() {
  useEffect(function checkIfAlreadyUsed() {
    if (hookHasBeenUsed) {
      throw new Error(
        'useSwayApolloClient has been used more than once. You should use ApolloProvider to share the client between multiple components.'
      )
    }

    hookHasBeenUsed = true

    return () => {
      hookHasBeenUsed = false
    }
  }, [])

  const { currentUser, onLogout } = useAuth()
  const { deviceId } = useAppContext()
  const authToken = currentUser?.accessToken?.value

  const [currentAuthToken, setCurrentAuthToken] = useState(authToken)
  const [currentWebsocketLink, setCurrentWebsocketLink] =
    useState<GraphQLWsLink | null>(null)
  const logoutMutation = print(LogoutDocument)

  useEffect(
    function updateAuthToken() {
      if (!authToken?.localeCompare(currentAuthToken ?? '')) {
        setCurrentAuthToken(authToken)
      }
    },
    [authToken, currentAuthToken]
  )

  const client = useMemo(() => {
    const authLink = setContext((_, { headers }) => ({
      headers: {
        ...headers,
        ...(authToken ? { Authorization: `Bearer ${authToken}` } : {}),
      },
    }))

    const cache = createCache()

    const deviceLink = setContext((_, { headers }) => ({
      headers: {
        ...headers,
        'Device-Source': Capacitor.getPlatform() || '',
        'Access-Control-Request-Headers': 'Device-Source',
      },
    }))

    const httpLink = createHttpLink({
      uri: `${ROOT_API_SERVER_URL}/graphql`,
      credentials: 'include',
      fetchOptions: {
        mode: 'cors',
      },
    })

    const webSocketLink = authToken
      ? new GraphQLWsLink(
          createClient({
            url: `${ROOT_WEBSOCKET_URL}/graphql-ws`,
            connectionParams: {
              Authorization: `Bearer ${authToken}`,
            },
            shouldRetry: () => true,
            retryAttempts: 20,
            on: {
              connected: () => {
                console.info('WebSocket connected')
              },
              closed: () => {
                console.info('WebSocket disconnected')
              },
              error: (err) => {
                console.error('[Apollo Link] WebSocket error', err)
              },
            },
          })
        )
      : null

    setCurrentWebsocketLink(webSocketLink)

    const splitLink = webSocketLink
      ? split(
          ({ query }) => {
            const definition = getMainDefinition(query)
            return (
              definition.kind === 'OperationDefinition' &&
              definition.operation === 'subscription'
            )
          },
          webSocketLink,
          httpLink
        )
      : httpLink

    const errorLink = onError(({ graphQLErrors, networkError }) => {
      if (graphQLErrors) {
        graphQLErrors.forEach(({ message, locations, path }) => {
          console.error(
            `[GraphQL error]: Message: ${message}, Location: ${locations?.toString()}, Path: ${path?.toString()}`
          )

          if (message === 'You must be logged in to execute this action') {
            onLogout(currentUser?.id, {
              isExpiredSession: true,
            })

            fetch(`${ROOT_API_SERVER_URL}/graphql`, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${authToken}`,
              },
              credentials: 'include',
              body: JSON.stringify({
                query: logoutMutation,
                variables: { deviceId },
              }),
            }).catch((error) =>
              console.error(
                `Error with logout mutation for user ${currentUser?.id}:`,
                error
              )
            )
          }
        })
      }

      if (networkError) {
        console.error(`[Network error]: ${networkError}`)
      }
    })

    return new ApolloClient({
      cache,
      link: ApolloLink.from([
        errorLink,
        authLink.concat(deviceLink).concat(splitLink),
      ]),
      connectToDevTools: process.env.NODE_ENV !== 'production',
      defaultOptions: {
        watchQuery: {
          pollInterval: TEN_MINUTES,
          fetchPolicy: 'network-only',
          nextFetchPolicy: 'cache-and-network',
        },
      },
    })
    // ! We explicitly only want this to change if the auth token changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authToken])

  useEffect(
    function handleCleanupAfterAuthTokenChanges() {
      if (!authToken && currentWebsocketLink) {
        currentWebsocketLink.client.dispose()
      }

      if (authToken !== currentAuthToken) {
        client.stop()
        client.resetStore()
      }

      return () => {
        if (currentWebsocketLink) {
          currentWebsocketLink.client.dispose()
        }
      }
    },
    // ! We explicitly only want this to run if the auth token changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [authToken]
  )

  return client
}
