import { faPlus } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  Box,
  Button,
  Card,
  Stack,
  Switch,
  Text,
  Textarea,
  Transition,
} from '@mantine/core'
import { MessageTemplate } from '@swaydm/graphql'
import { InputLabelSectionHeader } from '@swaydm/ui'
import { useEffect } from 'react'
import {
  isMessageTemplateDefault,
  useBroadcastFormContext,
} from './BroadcastContext'
import { EditFormMultipleChoiceOptions } from './EditFormMultipleChoiceOptions'

export const BroadcastFollowUpMessage = ({
  showBroadcastFollowUp,
  setShowBroadcastFollowUp,
}: {
  showBroadcastFollowUp: boolean
  setShowBroadcastFollowUp: React.Dispatch<React.SetStateAction<boolean>>
}) => {
  const form = useBroadcastFormContext()

  // The current `Follow Up Message Template` of the broadcast being edited.
  const followUpMessageTemplate = form.getInputProps('followUpMessageTemplate')
    .value as MessageTemplate | undefined

  // If the template is present, we enable the follow up message section.
  useEffect(() => {
    if (!isMessageTemplateDefault(followUpMessageTemplate)) {
      setShowBroadcastFollowUp(true)
    }
  }, [followUpMessageTemplate, setShowBroadcastFollowUp])

  const handleBroadcastFollowUpChange = () => {
    setShowBroadcastFollowUp(!showBroadcastFollowUp)

    // If we are toggling the follow up section, then we reset the `Follow Up Message Template` to the default state.
    form.getInputProps('followUpMessageTemplate').onChange({
      body: '',
      options: [],
    })
  }

  const handleAddOption = () => {
    form.insertListItem('followUpMessageTemplate.options', {
      text: '',
    })
  }

  const handleRemoveOption = (index: number) => {
    form.removeListItem('followUpMessageTemplate.options', index)
  }

  return (
    <Box>
      <InputLabelSectionHeader>Follow-Up</InputLabelSectionHeader>
      <Text mt={12}>
        When a customer responds to your broadcast, you can send an immediate
        follow up message.
      </Text>
      <Switch
        checked={showBroadcastFollowUp}
        label="Follow up message"
        onChange={handleBroadcastFollowUpChange}
        mt={12}
      />
      <Transition transition="fade" mounted={showBroadcastFollowUp}>
        {(styles) => (
          <Stack style={styles} mt={12}>
            <Textarea
              id="messageBody"
              placeholder="Enter message"
              autosize
              maxRows={5}
              required
              {...form.getInputProps('followUpMessageTemplate.body')}
            />
            <Card withBorder>
              <Stack>
                <EditFormMultipleChoiceOptions
                  handleRemoveOption={handleRemoveOption}
                  formOptionsField="followUpMessageTemplate.options"
                />
                <Button
                  variant="Outline"
                  leftSection={<FontAwesomeIcon icon={faPlus} />}
                  onClick={handleAddOption}
                >
                  Add Response
                </Button>
              </Stack>
            </Card>
          </Stack>
        )}
      </Transition>
    </Box>
  )
}
