import {
  faCheck,
  faCircleXmark,
  faMagnifyingGlass,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { TextInput } from '@mantine/core'
import { useState } from 'react'

/**
 * This is a reusable search component used on Redemption related tabs (i.e `offers` and `vendors`)
 *
 */
export const SearchInput = ({
  searchValue,
  setSearchValue,
  placeholder,
  showOnFocusConfirmButton = false,
}: {
  searchValue: string
  setSearchValue: (value: React.SetStateAction<string>) => void
  placeholder: string
  showOnFocusConfirmButton?: boolean
}) => {
  const [focused, setFocused] = useState(false)

  const searchIcon = (
    <FontAwesomeIcon icon={faMagnifyingGlass} className={'text-primary'} />
  )

  const confirmButton = (
    <FontAwesomeIcon
      icon={faCheck}
      className={'text-primary'}
      onClick={() => setFocused(false)}
    />
  )

  const clearButton = (
    <button
      onClick={() => {
        setSearchValue('')
      }}
    >
      <FontAwesomeIcon icon={faCircleXmark} />
    </button>
  )

  const getRightSection = () => {
    if (focused && showOnFocusConfirmButton) return confirmButton

    if (searchValue == '') return searchIcon

    return clearButton
  }

  return (
    <TextInput
      onFocus={() => setFocused(true)}
      onBlur={() => setFocused(false)}
      radius="md"
      placeholder={placeholder}
      onChange={(event) => {
        setSearchValue(event.target.value)
      }}
      value={searchValue}
      rightSection={getRightSection()}
    />
  )
}
