import { RequireFeatureFlag } from '../../routes/guards/RequireFeatureFlag'
import { RequireVendor } from '../../routes/guards/RequireVendor'

export const SettingsRoutes = [
  {
    path: 'settings',
    async lazy() {
      const { SettingsPage } = await import('./Settings.page')
      return {
        Component: SettingsPage,
      }
    },
    children: [
      {
        path: 'notifications',
        async lazy() {
          const { NotificationSettingsScreen } = await import(
            './screens/notificationSettings/NotificationSettings.screen'
          )
          return {
            Component: NotificationSettingsScreen,
          }
        },
      },
      {
        path: 'participant-settings',
        async lazy() {
          const { ConversationSettingsScreen } = await import(
            './screens/ConversationSettings.screen'
          )
          return {
            Component: ConversationSettingsScreen,
          }
        },
      },
      // 🦉 - Going to re-add with feed updates
      // {
      //   path: 'appearance',
      //   async lazy() {
      //     const { AppearanceSettingsScreen } = await import(
      //       './screens/AppearanceSettings.screen'
      //     )
      //     return {
      //       Component: AppearanceSettingsScreen,
      //     }
      //   },
      // },
      {
        path: 'update-email',
        async lazy() {
          const { UpdateEmailSettingsScreen } = await import(
            './screens/UpdateEmailSettings.screen'
          )
          return {
            Component: UpdateEmailSettingsScreen,
          }
        },
      },
      {
        path: 'update-password',
        async lazy() {
          const { UpdatePasswordSettingsScreen } = await import(
            './screens/UpdatePasswordSettings.screen'
          )
          return {
            Component: UpdatePasswordSettingsScreen,
          }
        },
      },
      {
        path: 'blocks',
        async lazy() {
          const { BlockedAccountSettingsScreen } = await import(
            './screens/BlockedAccountSettings.screen'
          )
          return {
            Component: BlockedAccountSettingsScreen,
          }
        },
      },
      {
        path: 'deactivate-account',
        async lazy() {
          const { DeactivateAccountSettingsScreen } = await import(
            './screens/DeactivateAccountSettings.screen'
          )
          return {
            Component: DeactivateAccountSettingsScreen,
          }
        },
      },
      {
        path: 'delete-account',
        async lazy() {
          const { DeleteAccountSettingsScreen } = await import(
            './screens/DeleteAccountSettings.screen'
          )
          return {
            Component: DeleteAccountSettingsScreen,
          }
        },
      },
      {
        path: 'edit-profile',
        async lazy() {
          const { EditProfilePageSettingsScreen } = await import(
            './screens/editProfileSettings/EditProfilePageSettings.screen'
          )
          return {
            element: (
              <RequireVendor>
                <EditProfilePageSettingsScreen />
              </RequireVendor>
            ),
          }
        },
      },
      {
        path: 'experimental-features',
        async lazy() {
          const { ExperimentalFeaturesScreen } = await import(
            './screens/ExperimentalFeatures.screen'
          )
          return {
            element: (
              <RequireFeatureFlag flag="fe_experimental_features">
                <ExperimentalFeaturesScreen />
              </RequireFeatureFlag>
            ),
          }
        },
      },
      {
        path: 'device-details',
        async lazy() {
          const { DeviceDetailsScreen } = await import(
            './screens/DeviceDetails.screen'
          )
          return {
            element: <DeviceDetailsScreen />,
          }
        },
      },
    ],
  },
]
