import {
  BackgroundImage,
  Box,
  Button,
  Center,
  Group,
  Stack,
  Text,
} from '@mantine/core'
import { BOLD } from '@swaydm/ui'
import { Link } from 'react-router-dom'
import tadaImage from '../../../../assets/tada.png'

export function AllCaughtUp() {
  return (
    <Box
      style={{
        height: '100%',
        width: '100%',
        maxHeight: '100%',
      }}
    >
      <BackgroundImage
        src={tadaImage}
        style={{
          height: '100%',
          width: '100%',
          maxHeight: '100%',
        }}
      >
        <Group
          justify="center"
          style={{
            height: '100%',
            width: '100%',
            maxHeight: '100%',
          }}
        >
          <Stack gap={0}>
            <Center>
              <Text fz={72}>🎉</Text>
            </Center>
            <Center>
              <Stack justify="center">
                <Text fz={22} fw={BOLD} ta="center">
                  You're all caught up!
                </Text>
                <Button component={Link} to="/redemptions">
                  Check out Redemptions in your area
                </Button>
              </Stack>
            </Center>
          </Stack>
        </Group>
      </BackgroundImage>
    </Box>
  )
}
