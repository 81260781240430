import { faClock } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Badge } from '@mantine/core'
import dayjs from 'dayjs'
import duration from 'dayjs/plugin/duration'
dayjs.extend(duration)

export function ValidEndLimitBadge({
  redemptionValidEnd,
}: {
  redemptionValidEnd: string
}) {
  const timeDiff = getTimeDiff(dayjs().toISOString(), redemptionValidEnd)

  const limitedReached = timeDiff <= 0

  return (
    <Badge
      color={limitedReached ? 'red' : 'cyan.6'}
      variant="filled"
      leftSection={<FontAwesomeIcon className="h-4 w-4" icon={faClock} />}
    >
      {formatDuration(timeDiff)}
    </Badge>
  )
}

function getTimeDiff(start: string, end: string) {
  const startDay = dayjs(start)
  const endDay = dayjs(end)

  return endDay.diff(startDay)
}

function formatDuration(durationToFormat: any) {
  const diffDuration = dayjs.duration(durationToFormat)

  const months = Math.floor(diffDuration.asMonths())
  const weeks = Math.floor(diffDuration.asWeeks() % 4)
  const days = Math.floor(diffDuration.asDays() % 7)
  const hours = Math.floor(diffDuration.asHours() % 24)
  const minutes = diffDuration.minutes()

  const parts = []
  if (months) parts.push(`${months}m`)
  if (weeks) parts.push(`${weeks}w`)
  if (days) parts.push(`${days}d`)
  if (hours) parts.push(`${hours}h`)
  if (minutes) parts.push(`${minutes}m`)

  return parts.join(', ')
}
