import { faCircleExclamation } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Badge } from '@mantine/core'
import { RedemptionLimitRate } from '@swaydm/graphql'

export function IntervalLimitBadge(limit: RedemptionLimitRate) {
  return (
    <Badge
      color={limit.blocking ? 'red' : 'cyan.6'}
      variant="filled"
      leftSection={
        <FontAwesomeIcon className=" h-4 w-4" icon={faCircleExclamation} />
      }
    >
      {limit.limit} per {limit.unit.toLocaleLowerCase()}
    </Badge>
  )
}
