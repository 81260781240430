import { getGradient, useMantineTheme } from '@mantine/core'
import { ProfileType } from '@swaydm/graphql'

export function useProfileGradient(
  profileType: ProfileType | undefined,
  unclaimed?: boolean,
  isNonProfit?: boolean
) {
  const theme = useMantineTheme()

  function getVendorGradient(unclaimed: boolean | undefined) {
    if (isNonProfit) {
      return getGradient({ deg: 136.23, from: '#D8E0EA', to: '#D8E0EA' }, theme)
    }

    return unclaimed
      ? getGradient({ deg: 136.23, from: '#7688A9', to: '#344B68' }, theme)
      : getGradient({ deg: 136.23, from: '#7b61ff', to: '#3ac2eb' }, theme)
  }

  function getMemberGradient() {
    return getGradient({ deg: 136.23, from: '#D8E0EA', to: '#D8E0EA' }, theme)
  }

  if (!profileType) return getMemberGradient()

  return profileType === ProfileType.Community
    ? getVendorGradient(unclaimed)
    : getMemberGradient()
}
