import { Card, Text } from '@mantine/core'
import { SwayCashIcon, cn } from '../../..'
import redemptionsPlaceholder from '../../../assets/redemptions-placeholder-square.png'

export type MessageRedemptionProps = {
  name: string
  price: number | undefined
  thumbnailUrl?: string
  vendorPhotoUrl?: string
}

export function MessageRedemption({
  name,
  price,
  thumbnailUrl,
  vendorPhotoUrl,
}: MessageRedemptionProps) {
  const isCommunityProfilePhotoDefault =
    typeof vendorPhotoUrl === 'string' &&
    vendorPhotoUrl.includes('default/avatar')

  const profilePhotoOrRedemptionsPlaceholder = isCommunityProfilePhotoDefault
    ? redemptionsPlaceholder
    : vendorPhotoUrl

  return (
    <Card className="p-2">
      <div className="grid grid-cols-9 items-center align-middle">
        <img
          className={cn(
            'col-span-2 h-[50px] w-[50px] rounded-md',
            !thumbnailUrl && 'bg-primary'
          )}
          height={50}
          width={50}
          src={thumbnailUrl || profilePhotoOrRedemptionsPlaceholder}
        />
        <div className="col-span-7 flex min-w-0 flex-col justify-start align-middle">
          <Text size="lg" truncate="end">
            {name}
          </Text>
          {price && (
            <Text>
              <SwayCashIcon className="mr-1 inline-block h-5 w-5" />
              {(price / 100).toFixed(2)} SC
            </Text>
          )}
        </div>
      </div>
    </Card>
  )
}
