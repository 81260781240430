import * as UpChunk from '@mux/upchunk'

export const uploadToS3 = async (
  url: string,
  image: Blob,
  metadata: { credentials: string; url: string },
  reportProgress: (percent: number) => void
): Promise<Event | null> => {
  const formData = new FormData()
  Object.entries(metadata.credentials).forEach(([key, val]) =>
    formData.append(key, val as string)
  )
  formData.append('content-type', image.type)
  formData.append('file', image)

  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest()
    xhr.open('POST', url, true)

    xhr.onload = () => {
      // If we get a 204, it means the upload was successful and there will be no JSON
      if (xhr.status === 204) {
        return resolve(null)
      } else {
        resolve(JSON.parse(xhr.responseText))
      }
    }
    xhr.onerror = reject

    xhr.upload.addEventListener('progress', (event) => {
      if (event.lengthComputable) {
        const percent = Math.round((event.loaded / event.total) * 100)
        reportProgress(percent)
      }
    })

    xhr.send(formData)
  })
}

export const uploadToMuxWeb = async (
  file: File,
  url: string,
  reportProgress: (percent: number) => void,
  uploadError: (error: string) => void
) => {
  const upload = UpChunk.createUpload({
    endpoint: url,
    file: file,
    chunkSize: 30720, // Uploads the file in ~30 MB chunks
  })

  // Subscribe to events
  upload.on('error', (error) => {
    uploadError(error.detail)
  })

  upload.on('progress', (progress) => {
    reportProgress(progress.detail)
  })

  upload.on('success', () => {
    reportProgress(100)
  })
}
