export const EarnRoutes = [
  {
    path: 'earn',
    async lazy() {
      const { EarnPage } = await import('./Earn.page')
      return {
        Component: EarnPage,
      }
    },
  },
]
