/* Sentry */
import * as Sentry from '@sentry/react'

/* Other */
import { ThemeContextProvider } from '@context/themeContext/Theme.context'
import { MantineProvider, localStorageColorSchemeManager } from '@mantine/core'
import { Notifications } from '@mantine/notifications'
import '@mantine/notifications/styles.css'
import { useGetDevice } from './hooks/useGetDevice'

import useSafeAreaInsets from '@hooks/useSafeAreaInserts'
import { baseSwayTheme, baseSwayThemeVariablesResolver } from '@swaydm/ui'
import React from 'react'
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom'

import { loadDevMessages, loadErrorMessages } from '@apollo/client/dev'
import { AppRouter } from './routes/AppRouter'

import { App as CapacitorApp } from '@capacitor/app'
import { Capacitor } from '@capacitor/core'
import { Geolocation } from '@capacitor/geolocation'
import { LocationPermissionFullscreenModal } from '@components/locationPermissionModal/LocationPermissionFullscreenModal'
import { useIsMobileViewport } from '@swaydm/ui/src/hooks/useIsMobileViewport'
import useAuthStore from './stores/useAuthStore'
import useGeolocationStore from './stores/useGeolocationStore'

if (
  import.meta.env.MODE === 'development' ||
  import.meta.env.MODE === 'integration'
) {
  // Adds messages only in a dev environment
  console.info('running in dev mode')
  loadDevMessages()
  loadErrorMessages()
}

const sentryDSN = import.meta.env.VITE_SENTRY_DSN
const sentryENV = import.meta.env.VITE_SENTRY_ENV

function setupSentry(buildId?: string) {
  if (sentryDSN) {
    try {
      Sentry.init({
        dsn: sentryDSN,
        environment: sentryENV,
        integrations: [
          new Sentry.BrowserTracing({
            routingInstrumentation: Sentry.reactRouterV6Instrumentation(
              React.useEffect,
              useLocation,
              useNavigationType,
              createRoutesFromChildren,
              matchRoutes
            ),
            tracingOrigins: [
              'localhost',
              'https://sway.dm',
              'https://swaydm.app',
              /^\//,
            ],
          }),
          new Sentry.Replay(),
        ],
        tracesSampleRate: 0.1,
        replaysSessionSampleRate: 0.1,
        replaysOnErrorSampleRate: 1.0,
        release: buildId,
      })
    } catch (error) {
      console.error('[App] Sentry unable to init: ', error)
    }
  }
}

if (Capacitor.isNativePlatform()) {
  // Set up Sentry for Error Reporting
  CapacitorApp.getInfo().then((result) => {
    const build = result.build
    const version = result.version

    setupSentry(`v${version}-b${build}`)
  })

  // Check for permissions for Native functionality
  Geolocation.checkPermissions().then((permission) => {
    if (permission.location === 'granted') {
      useGeolocationStore.getState().setPermissions(permission)
      useGeolocationStore.getState().setPermissionsChecked(true)
    } else {
      useGeolocationStore.getState().setPermissions(permission)
      useGeolocationStore
        .getState()
        .setPermissionsChecked(!permission.location.startsWith('prompt'))
    }
  })
} else {
  // TODO: Add a build id for web
  setupSentry()
}

const colorSchemeManager = localStorageColorSchemeManager({
  key: 'my-app-color-scheme',
})

colorSchemeManager.set('light')

const theme = baseSwayTheme
const themeResolver = baseSwayThemeVariablesResolver

const App: React.FC = () => {
  // Initialize what device we're on
  useGetDevice()
  useSafeAreaInsets()

  const { permissionsChecked, setPermissionsChecked } = useGeolocationStore()
  const { currentUser } = useAuthStore()

  const isNativePlatform = Capacitor.isNativePlatform()

  const isMobile = useIsMobileViewport()

  const fallbackHandler: Sentry.FallbackRender = (errorData) => {
    console.error(errorData.error)
    console.error(errorData.componentStack)
    console.error(errorData.eventId)
    return <p>Oops</p>
  }

  const errorHandler:
    | ((error: Error, componentStack: string, eventId: string) => void)
    | undefined = (error: Error, componentStack: string, eventId: string) => {
    console.error(error)
    console.error(componentStack)
    console.error(eventId)
  }

  return (
    <Sentry.ErrorBoundary fallback={fallbackHandler} onError={errorHandler}>
      <ThemeContextProvider storageKey="sway-color-theme">
        <MantineProvider
          theme={theme}
          colorSchemeManager={colorSchemeManager}
          cssVariablesResolver={themeResolver}
        >
          <AppRouter />
          {isNativePlatform && currentUser && (
            <LocationPermissionFullscreenModal
              opened={!permissionsChecked}
              onClose={() => {
                setPermissionsChecked(true)
              }}
            />
          )}
          <Notifications
            style={{
              ...(isMobile
                ? {
                    '--notifications-top':
                      'calc(var(--mantine-spacing-md) + env(safe-area-inset-top))',
                  }
                : {}),
              pointerEvents: 'none',
            }}
            styles={{
              notification: {
                pointerEvents: 'auto',
              },
            }}
            position={isMobile ? 'top-right' : 'bottom-right'}
          />
        </MantineProvider>
      </ThemeContextProvider>
    </Sentry.ErrorBoundary>
  )
}

export default App
