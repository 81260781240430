import { AccessToken, ProfileType } from '@swaydm/graphql'
import { create } from 'zustand'
import { createJSONStorage, devtools, persist } from 'zustand/middleware'

export interface Impersonator {
  id: string
  email: string
}

export interface UserState {
  id: string
  accessToken: AccessToken | undefined | null
  communityName: string
  displayName: string
  email: string
  firstName: string
  lastName: string
  impersonator?: Impersonator | null | undefined
  magicLink?: string | null | undefined
  profilePictureUrl: string
  profileType: ProfileType
}

type AuthStoreState = {
  currentUser: UserState | null
  allUsers: Array<UserState>
}

type AuthStoreActions = {
  onLogin: (newUser: UserState) => void
  onLogout: (userId: string) => void
  switchUser: (userId: string) => void
  updateAllUsers: (allUsers: Array<UserState>) => void
}

type AuthStore = AuthStoreState & AuthStoreActions

const isUserAccessTokenValid = (user: UserState | null): boolean => {
  const tokenExpiresAt = new Date(user?.accessToken?.validUntil || '').getTime()

  return !!(user?.accessToken?.validForSeconds && tokenExpiresAt > Date.now())
}

export const useAuthStore = create(
  devtools(
    persist<AuthStore>(
      (set) => ({
        currentUser: null,
        allUsers: [],
        onLogin: (newUser: UserState) =>
          set((state: AuthStoreState) => {
            const userAlreadyExists = state.allUsers.find(
              (user) => user.id === newUser.id
            )
            if (userAlreadyExists) {
              return {
                currentUser: userAlreadyExists,
                allUsers: state.allUsers.map((user) => {
                  // Replace the user with an updated accessToken
                  if (user.id === newUser.id) {
                    return {
                      ...user,
                      accessToken: newUser.accessToken,
                    }
                  }
                  return user
                }),
              }
            } else {
              return {
                currentUser: newUser,
                allUsers: [...state.allUsers, newUser],
              }
            }
          }),
        onLogout: (userId: string) =>
          set((state: AuthStoreState) => {
            // Remove the user from the list of all users
            // Also removing the users with expired access tokens
            const allUsersWithoutExpiredsAndUser = state.allUsers.filter(
              (user) => user.id !== userId && isUserAccessTokenValid(user)
            )

            // Set the first user in the list as the currentUser
            const newCurrentUser = allUsersWithoutExpiredsAndUser[0] || null

            return {
              allUsers: allUsersWithoutExpiredsAndUser,
              currentUser: newCurrentUser,
            }
          }),
        switchUser: (userId: string) => {
          set((state: AuthStoreState) => {
            const user = state.allUsers.find((user) => user.id === userId)
            if (user) {
              return {
                currentUser: user,
              }
            }
            return state
          })
        },
        updateAllUsers: (updatedAllUsers: Array<UserState>) => {
          set((state: AuthStoreState) => {
            const updatedCurrentUser = updatedAllUsers.find(
              (user) => user.id === state.currentUser?.id
            )
            return {
              currentUser: updatedCurrentUser || state.currentUser,
              allUsers: updatedAllUsers,
            }
          })
        },
      }),
      {
        name: 'sway-users',
        storage: createJSONStorage(() => localStorage),
      }
    )
  )
)

export default useAuthStore
