import React from 'react'
import { LayoutContext, LayoutContextType } from './contexts/Layout.context'

export default function useLayoutContext(): LayoutContextType {
  const context = React.useContext(LayoutContext)
  if (context === undefined) {
    throw new Error('useLayoutContext must be used within a LayoutProvider')
  }
  return context
}
