import {
  FilePicker,
  PickFilesResult,
  PickMediaOptions,
} from '@capawesome/capacitor-file-picker'
import { useGetDevice } from './useGetDevice'

export const usePickMedia = (imagesOnly?: boolean) => {
  const device = useGetDevice()

  let picker: (
    options?: PickMediaOptions | undefined
  ) => Promise<PickFilesResult>
  if (imagesOnly || device?.platform !== 'web') {
    picker = FilePicker.pickImages
  } else {
    picker = FilePicker.pickMedia
  }

  const pickMedia = async () => {
    return await picker({
      limit: 1,
      readData: true,
    })
  }

  return pickMedia
}
