import { CameraPermissionState } from '@capacitor-mlkit/barcode-scanning'
import useAppContext from '@context/appContext/useAppContext'
import { useCameraContext } from '@context/cameraContext'
import { useConversationsContext } from '@context/conversationsContext'
import { useFeatureFlags } from '@context/index'
import {
  faBullhorn as faBullhornSolid,
  faHome as faHomeSolid,
  faStore as faStoreSolid,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faBullhorn,
  faHome,
  faMessages,
  faStore,
} from '@fortawesome/sharp-regular-svg-icons'
import { faMessages as faMessagesSolid } from '@fortawesome/sharp-solid-svg-icons'
import { useAuth } from '@hooks/useAuth'
import { Center, Group, Indicator, Stack } from '@mantine/core'
import { ProfileType, useWalletBalanceQuery } from '@swaydm/graphql'
import { SwayCashIcon } from '@swaydm/ui'
import { useIsMobileViewport } from '@swaydm/ui/src/hooks/useIsMobileViewport'
import { isShortLink, resolveAndNavigate, trackUri } from '@util/linkUtils'
import { notifications } from '@util/notifications/notifications'
import {
  isAllowedDomain,
  isRootApiDomain,
  isRootLinkDomain,
  removeDomain,
} from '@util/urlFuncs'
import { useCallback } from 'react'
import { NavLink, useNavigate } from 'react-router-dom'
import { WalletBalanceFooterSection } from './WalletBalanceFooterSection'

export function SwayFooter() {
  const isMobile = useIsMobileViewport()
  const { unreadMessagesCount } = useConversationsContext()
  const { data: walletBalanceData } = useWalletBalanceQuery({
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
  })
  const { currentUser } = useAuth()
  const navigate = useNavigate()

  const walletBalance = walletBalanceData?.wallet?.balance.amount

  const { scanSingleBarcode, checkPermissions, requestPermissions } =
    useCameraContext()

  const handleQRCodeScan = useCallback(async () => {
    let permissions: CameraPermissionState = await checkPermissions()
    if (permissions === 'prompt') {
      permissions = await requestPermissions()
    }

    if (permissions === 'granted' || permissions === 'limited') {
      const barcode = await scanSingleBarcode()
      if (barcode.format === 'QR_CODE') {
        if (!isAllowedDomain(barcode.displayValue)) {
          notifications.show({
            title: 'Invalid QR Code',
            message: 'Please scan a valid SwayDM QR code',
            color: 'red',
          })
        } else {
          const barcodeUrl = new URL(barcode.displayValue)
          if (isRootApiDomain(barcodeUrl)) {
            // This is a legacy case for vendors that may still have QR codes such as: https://sway.dm/VendorName
            navigate('/profile/' + removeDomain(barcodeUrl))
          } else if (isRootLinkDomain(barcodeUrl) && isShortLink(barcodeUrl)) {
            // If the QR code is a short link, resolve it and navigate accordingly
            resolveAndNavigate(barcodeUrl, navigate)
          } else {
            // For all other cases, strip the domain and navigate to the path
            trackUri(barcodeUrl.toString())
            navigate(removeDomain(barcodeUrl))
          }
        }
      }
    } else {
      notifications.show({
        title: 'Camera access denied',
        message: 'You can turn it on in your phone settings',
      })
    }
  }, [checkPermissions, navigate, requestPermissions, scanSingleBarcode])

  return (
    <>
      {isMobile && (
        <Group h="100%" justify="space-around" pb={16}>
          {currentUser?.profileType === ProfileType.CommunityFollower && (
            <MemberMobileNavbar
              unreadMessagesCount={unreadMessagesCount}
              walletBalance={walletBalance}
              onQrCodeScan={handleQRCodeScan}
            />
          )}
          {currentUser?.profileType === ProfileType.Community && (
            <VendorMobileNavbar
              unreadMessagesCount={unreadMessagesCount}
              walletBalance={walletBalance}
              onQrCodeScan={handleQRCodeScan}
            />
          )}
        </Group>
      )}
    </>
  )
}

function MemberMobileNavbar({
  unreadMessagesCount,
  walletBalance,
  onQrCodeScan,
}: {
  unreadMessagesCount: number
  walletBalance: number | undefined | null
  onQrCodeScan: () => void
}) {
  const { deviceInfo } = useAppContext()
  const { contentFeed } = useFeatureFlags()

  const memberLinks = [
    contentFeed
      ? {
          to: '/feed',
          activeIcon: <FontAwesomeIcon icon={faHomeSolid} size="lg" />,
          inactiveIcon: <FontAwesomeIcon icon={faHome} size="lg" />,
        }
      : {
          to: '/conversations',
          activeIcon: <FontAwesomeIcon icon={faMessagesSolid} size="lg" />,
          inactiveIcon: <FontAwesomeIcon icon={faMessages} size="lg" />,
          indicator: unreadMessagesCount,
        },
    {
      to: '/redemptions',
      activeIcon: <FontAwesomeIcon icon={faStoreSolid} size="lg" />,
      inactiveIcon: <FontAwesomeIcon icon={faStore} size="lg" />,
    },
    {
      to: '/earn',
      activeIcon: <SwayCashIcon className="h-6 w-6" />,
      inactiveIcon: <SwayCashIcon className="h-6 w-6" />,
    },
  ]

  return (
    <Stack w="100%" gap={0}>
      {walletBalance !== undefined && walletBalance !== null && (
        <WalletBalanceFooterSection
          platform={deviceInfo?.platform || 'web'}
          walletBalance={walletBalance}
          onQrCodeScan={onQrCodeScan}
        />
      )}
      <Group w="100%" justify="space-around" mt={12}>
        {memberLinks.map((link, index) => (
          <NavLink to={link.to} key={`footer-link-${index}`}>
            {({ isActive }) => (
              <Center>
                {link.indicator !== undefined && link.indicator !== 0 && (
                  <Indicator label={unreadMessagesCount} size={16}>
                    {isActive ? link.activeIcon : link.inactiveIcon}
                  </Indicator>
                )}
                {(!link.indicator || link.indicator === 0) && (
                  <>{isActive ? link.activeIcon : link.inactiveIcon}</>
                )}
              </Center>
            )}
          </NavLink>
        ))}
      </Group>
    </Stack>
  )
}

function VendorMobileNavbar({
  unreadMessagesCount,
  walletBalance,
  onQrCodeScan,
}: {
  unreadMessagesCount: number
  walletBalance: number | undefined | null
  onQrCodeScan: () => void
}) {
  const { deviceInfo } = useAppContext()

  const vendorLinks = [
    {
      to: '/conversations',
      activeIcon: <FontAwesomeIcon icon={faMessagesSolid} size="lg" />,
      inactiveIcon: <FontAwesomeIcon icon={faMessages} size="lg" />,
      indicator: unreadMessagesCount,
    },
    {
      to: '/vendor/redemptions',
      activeIcon: <FontAwesomeIcon icon={faStoreSolid} size="lg" />,
      inactiveIcon: <FontAwesomeIcon icon={faStore} size="lg" />,
    },
    {
      to: '/vendor/broadcasts',
      activeIcon: <FontAwesomeIcon icon={faBullhornSolid} size="lg" />,
      inactiveIcon: <FontAwesomeIcon icon={faBullhorn} size="lg" />,
    },
    {
      to: '/earn',
      inactiveIcon: <SwayCashIcon className="h-6 w-6" />,
      activeIcon: <SwayCashIcon className="h-6 w-6" />,
    },
  ]

  return (
    <Stack w="100%" gap={0}>
      {walletBalance !== undefined && walletBalance !== null && (
        <WalletBalanceFooterSection
          platform={deviceInfo?.platform || 'web'}
          walletBalance={walletBalance}
          onQrCodeScan={onQrCodeScan}
        />
      )}
      <Group w="100%" justify="space-around" mt={12}>
        {vendorLinks.map((link, index) => (
          <NavLink to={link.to} key={`footer-link-${index}`}>
            {({ isActive }) => {
              return (
                <Center>
                  {link.indicator !== undefined && link.indicator !== 0 && (
                    <Indicator label={unreadMessagesCount} size={16}>
                      {isActive ? link.activeIcon : link.inactiveIcon}
                    </Indicator>
                  )}
                  {(!link.indicator || link.indicator === 0) && (
                    <>{isActive ? link.activeIcon : link.inactiveIcon}</>
                  )}
                </Center>
              )
            }}
          </NavLink>
        ))}
      </Group>
    </Stack>
  )
}
