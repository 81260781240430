import {
  differenceInDays,
  differenceInHours,
  differenceInMinutes,
  differenceInMonths,
  differenceInYears,
} from 'date-fns'

// A very simple conversion function that only checks years, months, and days
export const convertTimeToLastSeen = (lastMessageAt: Date): string => {
  const now = new Date()
  if (differenceInYears(now, lastMessageAt) >= 1) {
    return `${differenceInYears(now, lastMessageAt)}y`
  }
  if (differenceInMonths(now, lastMessageAt) >= 1) {
    return `${differenceInMonths(now, lastMessageAt)}mo`
  }
  if (differenceInDays(now, lastMessageAt) >= 1) {
    return `${differenceInDays(now, lastMessageAt)}d`
  }
  if (differenceInHours(now, lastMessageAt) >= 1) {
    return `${differenceInHours(now, lastMessageAt)}h`
  }
  if (differenceInMinutes(now, lastMessageAt) >= 1) {
    return `${differenceInMinutes(now, lastMessageAt)}m`
  }

  // Always return at least <1m as we dont do seconds
  return `<1m`
}

function formatTimeUnit(value: number, unit: string): string {
  return `${value} ${value === 1 ? unit : unit + 's'}`
}

export function formatRemainingTime(expirationTimestamp: string) {
  const now = new Date()
  const expirationDate = new Date(expirationTimestamp)

  const daysRemaining = differenceInDays(expirationDate, now)
  const hoursRemaining = differenceInHours(expirationDate, now)
  const minutesRemaining = differenceInMinutes(expirationDate, now)

  if (daysRemaining > 0) {
    const hours = hoursRemaining % 24
    return `${formatTimeUnit(daysRemaining, 'day')} and ${formatTimeUnit(hours, 'hour')}`
  } else if (hoursRemaining > 0) {
    const minutes = minutesRemaining % 60
    return `${formatTimeUnit(hoursRemaining, 'hour')} and ${formatTimeUnit(minutes, 'minute')}`
  } else {
    return formatTimeUnit(minutesRemaining, 'minute')
  }
}
