import { useAuth } from '@hooks/useAuth'
import * as Sentry from '@sentry/react'
import { useUserQuery } from '@swaydm/graphql'
import { useEffect } from 'react'
import { Navigate, useLocation } from 'react-router-dom'

const sentryDSN = import.meta.env.VITE_SENTRY_DSN

export const RequireAuth = ({ children }: { children?: React.ReactNode }) => {
  const { currentUser, onLogout } = useAuth()
  const location = useLocation()

  if (!currentUser?.accessToken) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    if (currentUser?.id) onLogout(currentUser?.id)
    return <Navigate to="/login" state={{ from: location }} replace />
  }

  if (sentryDSN) {
    return <RequireSentry>{children}</RequireSentry>
  }

  return children || null
}

const RequireSentry = ({ children }: { children: React.ReactNode }) => {
  const { currentUser } = useAuth()
  const { data } = useUserQuery({ skip: !currentUser?.accessToken })

  useEffect(() => {
    if (sentryDSN && data?.user && data.user.email) {
      Sentry.setUser({
        email: data?.user.email,
      })
    }
  })

  return children || null
}
