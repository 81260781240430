import { useFeatureFlags } from '@context/index'
import { useAuth } from '@hooks/useAuth'
import { ProfileType } from '@swaydm/graphql'
import { Navigate } from 'react-router-dom'

/**
 * Based on the user's profile type and feature flags, redirect the user to:
 * - `/feed` if they are a community follower and the content feed is enabled
 * - `/conversations` if they are a vendor or community follower without content feed
 * - `/login` if they are not logged in
 */
export function RootRedirect() {
  const { currentUser } = useAuth()
  const { contentFeed } = useFeatureFlags()

  if (
    currentUser?.profileType === ProfileType.CommunityFollower &&
    contentFeed === true
  ) {
    return <Navigate to="/feed" />
  } else if (currentUser) {
    return <Navigate to="/conversations" />
  } else {
    return <Navigate to="/login" />
  }
}
