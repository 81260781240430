import { faFireFlameCurved } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Badge } from '@mantine/core'
import { RedemptionLimitPartsFragment } from '@swaydm/graphql'

export function GlobalLimitBadge(limit: RedemptionLimitPartsFragment) {
  const redeemLimit = limit.limit || 0
  const redeemRedeemed = limit.count || 0

  return (
    <Badge
      color={limit.blocking ? 'red' : 'cyan.6'}
      variant="filled"
      leftSection={
        <FontAwesomeIcon className="mb-1 h-4 w-4" icon={faFireFlameCurved} />
      }
    >
      {redeemLimit - redeemRedeemed} of {redeemLimit} remaining
    </Badge>
  )
}
