import { Box, Group, Stack, noop } from '@mantine/core'
import {
  Broadcast,
  BroadcastInput,
  InteractionStatus,
  MessageType,
  PollMediaOption,
  PollOption,
  PollOptionType,
  PollTextOption,
  Profile,
} from '@swaydm/graphql'
import {
  ClickInteraction,
  MediaPreview,
  MessageContainer,
  MessageTextContent,
  PollInteraction,
  ReplyInteraction,
} from '@swaydm/ui'
import {
  MessageRedemption,
  MessageRedemptionProps,
} from '@swaydm/ui/src/components/messages/Interactions/MessageRedemption'
import dayjs from 'dayjs'
import { convertBroadcast2BroadcastInput } from '../utils/broadcast.utils'

export function EditFormBroadcastMessagePreview({
  isMultipleChoice,
  redemptions,
  thumbnailUrl,
  values,
  isForFollowUp = false,
}: {
  isMultipleChoice?: boolean
  redemptions?: Array<MessageRedemptionProps>
  thumbnailUrl?: string
  values: BroadcastInput
  isForFollowUp?: boolean
}) {
  const template = isForFollowUp
    ? values.followUpMessageTemplate
    : values.messageTemplate

  const previewValues: BroadcastInput = {
    ...values,
    messageTemplate: {
      ...template,
      price: template?.price ? template?.price / 2 : 0,
    },
  }

  return (
    <Group>
      <MessageContainer direction="incoming" hasEarningOpportunity={true}>
        {thumbnailUrl && <MediaPreview thumbnailUrl={thumbnailUrl} />}
        <MessageTextContent
          text={template?.body?.toString() || 'Example Message'}
        />
        {template?.type === MessageType.View && (
          <ViewInteractionPreview values={previewValues} />
        )}
        {template?.type === MessageType.Reply && !isMultipleChoice && (
          <ReplyInteractionPreview values={previewValues} />
        )}
        {template?.type === MessageType.Reply && isMultipleChoice && (
          <MultipleChoiceInteractionPreview values={previewValues} />
        )}
        {redemptions && (
          <Box p={8}>
            <Stack gap={8}>
              {redemptions.map((redemption, idx) => (
                <MessageRedemption
                  key={`redemption-preview-${idx}`}
                  {...redemption}
                />
              ))}
            </Stack>
          </Box>
        )}
      </MessageContainer>
    </Group>
  )
}

export function BroadcastMessagePreview({
  broadcast,
  isForFollowUp = false,
}: {
  broadcast: Broadcast
  userProfile: Profile
  isForFollowUp?: boolean
}) {
  const messageTemplate = isForFollowUp
    ? broadcast.followUpMessageTemplate
    : broadcast.messageTemplate
  const broadcastValues = convertBroadcast2BroadcastInput(broadcast)
  const isMultipleChoice = !!messageTemplate?.options
  const redemptions = messageTemplate?.redemptionOffers?.map((redemption) => {
    return {
      name: redemption?.name,
      price: redemption?.price.amount || 0,
      thumbnailUrl: redemption?.primaryMedia?.downloadUrl?.url,
      vendorPhotoUrl: redemption?.vendor?.photoUrl,
    }
  }) as Array<MessageRedemptionProps>

  return (
    <EditFormBroadcastMessagePreview
      isMultipleChoice={isMultipleChoice}
      values={broadcastValues}
      redemptions={redemptions}
      isForFollowUp={isForFollowUp}
    />
  )
}

export const ViewInteractionPreview = ({
  values,
}: {
  values: BroadcastInput
}) => {
  const expiresAt = dayjs(new Date()).add(2, 'day')
  return (
    <ClickInteraction
      direction="incoming"
      earningPotential={{ amount: values.messageTemplate?.price }}
      expiresAt={expiresAt.toISOString()}
      interactionId="-1"
      receiver={{
        firstName: 'John',
        lastName: 'Doe',
        id: '-1',
      }}
      status={InteractionStatus.Pending}
      onClickInteraction={() => noop()}
    />
  )
}

export const ReplyInteractionPreview = ({
  values,
}: {
  values: BroadcastInput
}) => {
  const expiresAt = dayjs(new Date()).add(2, 'day')
  return (
    <ReplyInteraction
      direction="incoming"
      earningPotential={{ amount: values.messageTemplate?.price }}
      expiresAt={expiresAt.toISOString()}
      interactionId="-1"
      receiver={{
        firstName: 'John',
        lastName: 'Doe',
        id: '-1',
      }}
      status={InteractionStatus.Pending}
      onReplyInteraction={() => noop()}
    />
  )
}

export const MultipleChoiceInteractionPreview = ({
  values,
}: {
  values: BroadcastInput
}) => {
  const pollOptions: Array<PollOption | PollTextOption | PollMediaOption> =
    values.messageTemplate?.options?.map((option, index) => {
      return {
        id: index.toString(),
        order: index,
        text: option?.text || '',
        type: PollOptionType.PollText,
      } as PollTextOption
    }) || []

  const expiresAt = dayjs(new Date()).add(2, 'day')

  return (
    <PollInteraction
      direction="incoming"
      earningPotential={{ amount: values.messageTemplate?.price }}
      expiresAt={expiresAt.toISOString()}
      options={pollOptions}
      receiver={{
        firstName: 'John',
        lastName: 'Doe',
        id: '-1',
      }}
      status={InteractionStatus.Pending}
      onPollOptionSelected={() => noop()}
    />
  )
}
