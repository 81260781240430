import {
  Barcode,
  BarcodeFormat,
  BarcodeScanner,
  CameraPermissionState,
  LensFacing,
} from '@capacitor-mlkit/barcode-scanning'
import {
  faCircleExclamation,
  faClose,
  faSun,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Portal } from '@mantine/core'
import { notifications } from '@util/notifications/notifications'
import { noop } from 'lodash-es'
import React, { createContext, useCallback, useMemo } from 'react'

type CameraContextProps = {
  startScan: () => Promise<void>
  stopScan: () => Promise<void>
  scanSingleBarcode: () => Promise<Barcode>
  scan: () => Promise<Barcode[]>
  isSupported: () => Promise<boolean>
  enableTorch: () => Promise<void>
  disableTorch: () => Promise<void>
  toggleTorch: () => Promise<void>
  isTorchEnabled: () => Promise<boolean>
  isTorchAvailable: () => Promise<boolean>
  openSettings: () => Promise<void>
  isGoogleBarcodeScannerModuleAvailable: () => Promise<boolean>
  installGoogleBarcodeScannerModule: () => Promise<void>
  checkPermissions: () => Promise<CameraPermissionState>
  requestPermissions: () => Promise<CameraPermissionState>
}

export const CameraContext = createContext<CameraContextProps>({
  startScan: async () => {
    noop()
  },
  stopScan: async () => {
    noop()
  },
  scanSingleBarcode: async () => {
    return {} as Barcode
  },
  scan: async () => [],
  isSupported: async () => false,
  enableTorch: async () => {
    noop()
  },
  disableTorch: async () => {
    noop()
  },
  toggleTorch: async () => {
    noop()
  },
  isTorchEnabled: async () => false,
  isTorchAvailable: async () => false,
  openSettings: async () => {
    noop()
  },
  isGoogleBarcodeScannerModuleAvailable: async () => false,
  installGoogleBarcodeScannerModule: async () => {
    noop()
  },
  checkPermissions: async () => {
    return {} as CameraPermissionState
  },
  requestPermissions: async () => {
    return {} as CameraPermissionState
  },
})

export const CameraProvider: React.FC<{
  children: React.ReactNode
}> = ({ children }) => {
  const [isActive, setIsActive] = React.useState(false)
  const startScan = async () => {
    throw new Error('Not fully implemented')

    // The camera is visible behind the WebView, so that you can customize the UI in the WebView.
    // However, this means that you have to hide all elements that should not be visible.
    // You can find an example in our demo repository.
    // In this case we set a class `barcode-scanner-active`, which then contains certain CSS rules for our app.
    document.querySelector('body')?.classList.add('barcode-scanner-active')
    document
      .querySelector('qr-code-portal')
      ?.classList.add('barcode-scanner-modal')

    // Add the `barcodeScanned` listener
    await BarcodeScanner.addListener('barcodeScanned', async (result) => {
      console.log(result.barcode)
    })

    // Start the barcode scanner
    await BarcodeScanner.startScan()
  }

  const stopScan = async () => {
    // Make all elements in the WebView visible again
    document.querySelector('body')?.classList.remove('barcode-scanner-active')
    document.querySelector('#root')?.classList.remove('hidden')

    // Remove all listeners
    await BarcodeScanner.removeAllListeners()

    // Stop the barcode scanner
    await BarcodeScanner.stopScan()
    setIsActive(false)
  }

  const scanSingleBarcode = useCallback(() => {
    return new Promise<Barcode>((resolve) => {
      document.querySelector('body')?.classList.add('barcode-scanner-active')
      document.querySelector('#root')?.classList.add('hidden')
      setIsActive(true)

      const addListenerAndStartScan = async () => {
        const scanListener = await BarcodeScanner.addListener(
          'barcodeScanned',
          async (result) => {
            setIsActive(false)
            await scanListener.remove()
            document
              .querySelector('body')
              ?.classList.remove('barcode-scanner-active')
            document.querySelector('#root')?.classList.remove('hidden')

            await BarcodeScanner.stopScan()
            resolve(result.barcode)
          }
        )

        const errorListener = await BarcodeScanner.addListener(
          'scanError',
          async () => {
            setIsActive(false)
            await errorListener.remove()
            document
              .querySelector('body')
              ?.classList.remove('barcode-scanner-active')
            document.querySelector('#root')?.classList.remove('hidden')

            await BarcodeScanner.stopScan()
            notifications.show({
              title: 'Invalid QR Code',
              message: 'Please scan a valid SwayDM QR code',
            })
          }
        )

        await BarcodeScanner.startScan({
          formats: [BarcodeFormat.QrCode],
          lensFacing: LensFacing.Back,
        })
      }

      addListenerAndStartScan()
    })
  }, [])

  const scan = async () => {
    setIsActive(true)
    const { barcodes } = await BarcodeScanner.scan({
      formats: [BarcodeFormat.QrCode],
    })
    setIsActive(false)
    return barcodes
  }

  const isSupported = async () => {
    const { supported } = await BarcodeScanner.isSupported()
    return supported
  }

  const enableTorch = async () => {
    await BarcodeScanner.enableTorch()
  }

  const disableTorch = async () => {
    await BarcodeScanner.disableTorch()
  }

  const toggleTorch = async () => {
    await BarcodeScanner.toggleTorch()
  }

  const isTorchEnabled = async () => {
    const { enabled } = await BarcodeScanner.isTorchEnabled()
    return enabled
  }

  const isTorchAvailable = async () => {
    const { available } = await BarcodeScanner.isTorchAvailable()
    return available
  }

  const openSettings = async () => {
    await BarcodeScanner.openSettings()
  }

  const isGoogleBarcodeScannerModuleAvailable = async () => {
    const { available } =
      await BarcodeScanner.isGoogleBarcodeScannerModuleAvailable()
    return available
  }

  const installGoogleBarcodeScannerModule = async () => {
    await BarcodeScanner.installGoogleBarcodeScannerModule()
  }

  const checkPermissions = async () => {
    const { camera } = await BarcodeScanner.checkPermissions()
    return camera
  }

  const requestPermissions = async () => {
    const { camera } = await BarcodeScanner.requestPermissions()
    return camera
  }

  const memoedValue = useMemo(
    () => ({
      startScan,
      stopScan,
      scanSingleBarcode,
      scan,
      isSupported,
      enableTorch,
      disableTorch,
      toggleTorch,
      isTorchEnabled,
      isTorchAvailable,
      openSettings,
      isGoogleBarcodeScannerModuleAvailable,
      installGoogleBarcodeScannerModule,
      checkPermissions,
      requestPermissions,
    }),
    [scanSingleBarcode]
  )

  return (
    <CameraContext.Provider value={memoedValue}>
      {isActive && (
        <Portal>
          <div className="barcode-scanner-modal padding-safe-bottom absolute left-0 top-0 h-full min-h-screen w-full">
            <div className="padding-safe-bottom fixed bottom-0 flex w-full flex-col justify-center bg-black align-middle opacity-70">
              <div className="flex h-24 flex-row items-center justify-around">
                <FontAwesomeIcon
                  icon={faSun}
                  className="h-16 w-16 text-primary"
                  onClick={toggleTorch}
                />
                <FontAwesomeIcon
                  icon={faClose}
                  className="h-16 w-16 text-primary"
                  onClick={stopScan}
                />
              </div>
            </div>
          </div>
          <FontAwesomeIcon icon={faCircleExclamation} />
        </Portal>
      )}
      {children}
    </CameraContext.Provider>
  )
}
