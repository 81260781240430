import { faComment } from '@fortawesome/free-regular-svg-icons'
import { faClock } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faShareNodes } from '@fortawesome/sharp-regular-svg-icons'
import {
  ActionIcon,
  Avatar,
  Box,
  Button,
  Center,
  Drawer,
  Flex,
  Group,
  Image,
  Loader,
  Stack,
  Text,
} from '@mantine/core'
import { useDisclosure } from '@mantine/hooks'
import {
  InteractionClick,
  InteractionPoll,
  InteractionRedemptionOffer,
  InteractionReply,
  InteractionType,
  MediaType,
  MessagePreviewMediaPointerPartsFragment,
  useFeedGetSingleInteractionQuery,
} from '@swaydm/graphql'
import { BOLD, SwayCashIcon } from '@swaydm/ui'
import { useIsMobileViewport } from '@swaydm/ui/src/hooks/useIsMobileViewport'
import { formatRemainingTime } from '@util/time'
import { uniqueId } from 'lodash-es'
import { useCallback, useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import { FeedRedemptionPreview } from '../FeedRedemptionPreview'
import { ClickInteraction } from './Click.interaction'
import { FeedCardTextContent } from './FeedCardTextContent'
import { PollInteraction } from './Poll.interaction'
import { ReplyInteraction } from './Reply.interaction'

const BODY_BREAKPOINT_LENGTH = 70
const EXPANDED_HEIGHT = 100
const COLLAPSED_HEIGHT = 225

export function InteractionContainer({
  id,
  scrollToNextItem,
}: {
  id: string
  scrollToNextItem: () => void
}) {
  const [isExpanded, setIsExpanded] = useState(false)
  const [isOverflowing, setIsOverflowing] = useState(false)
  const isMobile = useIsMobileViewport()
  const [followUpOpened, { open: openFollowUp, close: closeFollowUp }] =
    useDisclosure(false)
  const [containerUUID] = useState<string>(uniqueId('interactionContainer-'))

  const middleSectionRef = useRef<HTMLDivElement>(null)

  const { data, loading } = useFeedGetSingleInteractionQuery({
    variables: { id },
    fetchPolicy: 'network-only',
  })

  const interaction = data?.oneInteraction as
    | InteractionClick
    | InteractionPoll
    | InteractionReply

  const { data: followUpData } = useFeedGetSingleInteractionQuery({
    variables: { id: interaction?.followUpInteraction?.id ?? '' },
    skip: !interaction?.followUpInteraction?.id,
  })

  useEffect(() => {
    const middleSection = middleSectionRef.current
    if (
      middleSection &&
      middleSection.scrollHeight > middleSection.clientHeight
    ) {
      setIsOverflowing(true)
    } else {
      setIsOverflowing(false)
    }
  }, [data, middleSectionRef])

  const toggleExpand = () => {
    setIsExpanded(!isExpanded)
  }

  // We have to do this because of a type difference between the interactions for the Message property
  const interactionToUse = interaction as any

  const message =
    interactionToUse?.message ||
    interactionToUse?.replyMessage ||
    interactionToUse?.pollMessage ||
    interactionToUse?.clickMessage ||
    interactionToUse?.redemptionMessage ||
    interactionToUse?.redemptionOfferMessage

  const body = message ? message?.body : ''
  const authorProfile = message?.author

  const mediaAttachment = message?.attachments?.[0]
  const imageAttachment =
    mediaAttachment?.mediaType === MediaType.Image
      ? (mediaAttachment as MessagePreviewMediaPointerPartsFragment)
      : undefined
  const videoAttachment =
    mediaAttachment?.mediaType === MediaType.Video ? mediaAttachment : undefined

  const redemptionOffers = (interaction as InteractionRedemptionOffer)
    ?.redemptions

  const followUpInteraction = followUpData?.oneInteraction as
    | InteractionClick
    | InteractionPoll
    | InteractionReply
  const followUpMessage =
    (followUpInteraction as any)?.message ||
    (followUpInteraction as any)?.replyMessage ||
    (followUpInteraction as any)?.pollMessage ||
    (followUpInteraction as any)?.clickMessage ||
    (followUpInteraction as any)?.redemptionMessage ||
    (followUpInteraction as any)?.redemptionOfferMessage

  const handleCompletedInteraction = useCallback(
    (followUpId: string | undefined) => {
      if (followUpId) {
        // ! This is temporarily commented out until the BE is ready
        // refetch()
        openFollowUp()
      } else {
        closeFollowUp()
        scrollToNextItem()
      }
    },
    [closeFollowUp, openFollowUp, scrollToNextItem]
  )

  return (
    <>
      {loading ? (
        <Loader color="swurple" size="xl" type="dots" />
      ) : (
        <Stack h="100%" w="100%" mah="100%" id={containerUUID}>
          <Stack
            h="100%"
            w="100%"
            mah="100%"
            gap={0}
            justify="space-between"
            pt={isMobile && body.length > BODY_BREAKPOINT_LENGTH ? '5vh' : 0}
          >
            <Box>
              <div style={{ flex: '0 0 auto' }}>
                {body.length <= BODY_BREAKPOINT_LENGTH && (
                  <>
                    {imageAttachment && (
                      <Image
                        src={imageAttachment.thumbnail?.url}
                        mah={isExpanded ? EXPANDED_HEIGHT : COLLAPSED_HEIGHT}
                        className="transition-all"
                      />
                    )}
                    {!imageAttachment && !videoAttachment && (
                      <Center
                        h={isExpanded ? EXPANDED_HEIGHT : COLLAPSED_HEIGHT}
                        bg="swurple"
                        className="transition-all"
                        px={16}
                      >
                        <Text c="white" fw={BOLD} size="xl" lineClamp={2}>
                          {body}
                        </Text>
                      </Center>
                    )}
                  </>
                )}
                <Flex
                  px={8}
                  py={8}
                  justify="space-between"
                  align="center"
                  className="flex-shrink-0"
                >
                  <Flex>
                    <Avatar src={authorProfile?.profilePictureUrl} size="md" />
                    <Stack justify="center" h="100%" gap={4} ml={4}>
                      <Text size="sm">{authorProfile?.communityName}</Text>
                      <Text size="10px">
                        <FontAwesomeIcon icon={faClock} />
                        <Text span fw={BOLD} ml={2}>
                          {formatRemainingTime(interaction.expiresAt)}
                        </Text>
                        {interaction.earningPotential?.amount ? (
                          <>
                            <Text span ml={2}>
                              to earn
                            </Text>
                            <SwayCashIcon className="mb-0.5 ml-1 inline-block h-3 w-3" />
                            <Text span ml={2}>
                              {(
                                interaction.earningPotential?.amount / 100
                              ).toFixed(2)}
                            </Text>
                          </>
                        ) : null}
                      </Text>
                    </Stack>
                  </Flex>
                  <Flex>
                    <ActionIcon
                      component={Link}
                      variant="transparent"
                      to={`/conversations/${message.conversationId}`}
                      c="dark"
                      size="lg"
                    >
                      <FontAwesomeIcon icon={faComment} className="h-6 w-6" />
                    </ActionIcon>
                    <ActionIcon
                      component={Link}
                      variant="transparent"
                      to={`/profile/${message.author.displayName}`}
                      c="dark"
                      size="lg"
                    >
                      <FontAwesomeIcon
                        icon={faShareNodes}
                        className="h-6 w-6"
                      />
                    </ActionIcon>
                  </Flex>
                </Flex>
                {followUpInteraction && (
                  <Box px={8} pb={8}>
                    <Button fullWidth onClick={openFollowUp}>
                      Follow Up Message
                    </Button>
                  </Box>
                )}
              </div>
              <Box
                ref={middleSectionRef}
                px={8}
                mb={8}
                pos="relative"
                style={{
                  flex: '1 1 auto',
                  overflowY: isExpanded ? 'auto' : 'hidden',
                  maxHeight: '150px',
                  transition: 'max-height 0.3s ease',
                }}
              >
                <FeedCardTextContent body={body} />
                {redemptionOffers &&
                  redemptionOffers.length > 0 &&
                  redemptionOffers.map((redemptionOffer) => (
                    <FeedRedemptionPreview
                      key={redemptionOffer.id}
                      redemption={redemptionOffer}
                    />
                  ))}
              </Box>
              {isOverflowing && (
                <Box>
                  <Group justify="flex-end">
                    <Button variant="transparent" onClick={toggleExpand}>
                      {isExpanded ? 'Show Less' : 'Show More'}
                    </Button>
                  </Group>
                </Box>
              )}
            </Box>

            {interaction.interactionType === InteractionType.Poll && (
              <PollInteraction
                id={interaction.id}
                interaction={interaction as InteractionPoll}
                completedInteraction={(followUpId: string | undefined) =>
                  handleCompletedInteraction(followUpId)
                }
              />
            )}
            {interaction.interactionType === InteractionType.Reply && (
              <ReplyInteraction
                id={interaction.id}
                body={body}
                interaction={interaction as InteractionReply}
                completedInteraction={(followUpId: string | undefined) =>
                  handleCompletedInteraction(followUpId)
                }
              />
            )}
            {interaction.interactionType === InteractionType.Click && (
              <ClickInteraction
                id={interaction.id}
                interaction={interaction as InteractionClick}
                completedInteraction={(followUpId: string | undefined) =>
                  handleCompletedInteraction(followUpId)
                }
              />
            )}
          </Stack>

          <Drawer.Root
            offset={8}
            radius="md"
            opened={followUpOpened}
            onClose={closeFollowUp}
            position="bottom"
            portalProps={{ target: `#${containerUUID}` }}
          >
            <Drawer.Overlay
              backgroundOpacity={0.5}
              blur={4}
              pos="absolute"
              radius={isMobile ? undefined : 'lg'}
            />
            <Drawer.Content
              style={{ width: '98%', position: 'absolute', height: 'auto' }}
            >
              <Drawer.Header>
                <Drawer.Title>Follow Up Message</Drawer.Title>
                <Drawer.CloseButton />
              </Drawer.Header>
              <Drawer.Body>
                {followUpMessage?.body && (
                  <FeedCardTextContent body={followUpMessage.body} />
                )}
                {followUpInteraction?.interactionType ===
                  InteractionType.Poll && (
                  <PollInteraction
                    id={followUpInteraction.id}
                    interaction={followUpInteraction as InteractionPoll}
                    completedInteraction={(followUpId: string | undefined) =>
                      handleCompletedInteraction(followUpId)
                    }
                  />
                )}
                {followUpInteraction?.interactionType ===
                  InteractionType.Reply && (
                  <ReplyInteraction
                    id={followUpInteraction.id}
                    body={body}
                    interaction={followUpInteraction as InteractionReply}
                    completedInteraction={(followUpId: string | undefined) =>
                      handleCompletedInteraction(followUpId)
                    }
                  />
                )}
                {followUpInteraction?.interactionType ===
                  InteractionType.Click && (
                  <ClickInteraction
                    id={followUpInteraction.id}
                    interaction={followUpInteraction as InteractionClick}
                    completedInteraction={(followUpId: string | undefined) =>
                      handleCompletedInteraction(followUpId)
                    }
                  />
                )}
              </Drawer.Body>
            </Drawer.Content>
          </Drawer.Root>
        </Stack>
      )}
    </>
  )
}
