import { useGetLocation } from '@hooks/useGetLocation'
import { Modal, useMantineTheme } from '@mantine/core'
import { LocationPermissionModalContent } from './LocationPermissionModalContent'

export const LocationPermissionModal = ({
  opened,
  onClose,
}: {
  opened: boolean
  onClose: () => void
}) => {
  const theme = useMantineTheme()
  const { requestLocationPermission } = useGetLocation()

  return (
    <Modal.Root opened={opened} onClose={onClose} centered>
      <Modal.Overlay />
      <Modal.Content bg={theme.primaryColor}>
        <Modal.Body className="overflow-hidden" pos={'relative'}>
          <LocationPermissionModalContent
            onClose={onClose}
            requestLocationPermission={requestLocationPermission}
          />
        </Modal.Body>
      </Modal.Content>
    </Modal.Root>
  )
}
