import { Text } from '@mantine/core'
import React from 'react'

interface MessageTextContentProps {
  text: string
}

export const MessageTextContent = ({ text }: MessageTextContentProps) => {
  const isUrl = (word: string) => {
    const urlPattern = /^https?:\/\/[^\s$.?#].[^\s]*$/gm
    return urlPattern.test(word)
  }

  const addMarkup = (word: string, index: number) => {
    if (isUrl(word)) {
      return (
        <a
          href={word}
          target="_blank"
          rel="noopener noreferrer"
          key={index}
          className="break-words underline"
          style={{ wordBreak: 'break-all' }}
        >
          {word}
        </a>
      )
    }
    return <>{word}</>
  }

  const formatText = (text: string) => {
    // Split text into words and map each word to a JSX element
    return text.split(/\s+/).map((word, index, _words) => {
      // Insert a space before each word except the first one in a paragraph
      const space = index > 0 ? ' ' : ''
      return (
        <React.Fragment key={index}>
          {space}
          {addMarkup(word, index)}
        </React.Fragment>
      )
    })
  }

  const paragraphs = text.split('\n').map((paragraph, index) => (
    <Text key={index} mb={2}>
      {formatText(paragraph)}
    </Text>
  ))

  return (
    <div
      className="whitespace-pre-wrap break-words p-2 pt-3 text-base"
      style={{ wordBreak: 'break-word' }}
    >
      {paragraphs}
    </div>
  )
}
