import { faX } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { cn } from '../../utils'
import { UPLOAD_STATE } from './Media.types'

const numFormatter = Intl.NumberFormat('en-US', {
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
})

export const UploadStatusIndicator = ({
  uploadState,
  progress,
  onClickClose,
  imgSrc,
}: {
  uploadState: UPLOAD_STATE
  progress: number
  onClickClose: () => void
  imgSrc: string
}) => {
  let status = 'unknown'
  let state = 'unknown'

  switch (uploadState) {
    case UPLOAD_STATE.Uploaded:
    case UPLOAD_STATE.Uploading:
      if (progress == 100) {
        status = 'Processing'
        state = 'processing'
      } else {
        status = numFormatter.format(progress)
        state = 'uploading'
      }
      break

    case UPLOAD_STATE.MediaAvailable:
      status = '👍'
      state = 'complete'
      break

    case UPLOAD_STATE.Error:
      status = 'error'
      state = 'error'
      break

    default:
      status = 'Error'
  }

  return (
    <div className="relative h-24 w-24 overflow-hidden rounded-t bg-muted shadow-xl md:h-48 md:w-48">
      <div
        className={cn(
          'absolute left-0 top-0 w-full',
          'opacity-75 hover:opacity-100',
          'text-white',
          'rounded-t',
          'pl-2.5',
          {
            'bg-primary': state == 'uploading' || state == 'processing',
            'bg-success': state == 'complete',
            'bg-destructive': state == 'error',
          }
        )}
      >
        {(state == 'uploading' || state == 'processing') && (
          <div className="pointer-events-none absolute inset-0 z-10 h-full w-full overflow-hidden">
            <div className="z-5 pointer-events-none absolute -inset-full top-0 block h-full w-full -skew-x-6 transform animate-shine-fast bg-gradient-to-r from-transparent to-white opacity-40" />
          </div>
        )}
        <div className="flex">
          <div>{status}</div>
          <div className="flex-1">
            <button
              className="float-right rounded-tr bg-white"
              onClick={() => onClickClose()}
            >
              <FontAwesomeIcon icon={faX} className="w-6 text-black" />
            </button>
          </div>
        </div>
      </div>
      <div className="grid h-full place-content-center">
        <div className="">
          <img
            src={imgSrc}
            alt="Attached media"
            className="object-fill object-center"
          />
        </div>
      </div>
    </div>
  )
}
