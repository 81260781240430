import React from 'react'
import {
  ConversationsContext,
  ConversationsContextType,
} from './Conversations.context'

export function useConversationsContext(): ConversationsContextType {
  const context = React.useContext(ConversationsContext)
  if (context === undefined) {
    throw new Error(
      'useConversationsContext must be used within a ConversationsContextProvider'
    )
  }
  return context
}
