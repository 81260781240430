export function extractProfileLink(paragraph: string): {
  text: string
  id: string | undefined
} {
  const regex =
    // eslint-disable-next-line no-useless-escape
    /\$PROFILE:(https?:\/\/[^\s\/]+(?:\/[^\s\/]+)*(?:\/profile\/|\/vendors\/)([^\s\/]+))/

  const match = paragraph.match(regex)

  if (match) {
    const fullMatch = match[0]
    const id = match[2]
    const cleanedParagraph = paragraph.replace(fullMatch, '').trim()
    return {
      text: cleanedParagraph,
      id: id,
    }
  } else {
    return {
      text: paragraph,
      id: undefined,
    }
  }
}
