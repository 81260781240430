import { faPaperPlane } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  Box,
  Button,
  Center,
  Divider,
  Group,
  Modal,
  Stack,
  Text,
  Textarea,
} from '@mantine/core'
import { useField } from '@mantine/form'
import { useDisclosure } from '@mantine/hooks'
import { InteractionReply, useInteractionReplyMutation } from '@swaydm/graphql'
import { BOLD, SwayCashIcon } from '@swaydm/ui'
import { notifications } from '@util/notifications/notifications'
import { useCallback, useState } from 'react'
import Confetti from 'react-confetti'
import { BaseFeedInteraction } from './Interactions.types'

export function ReplyInteraction({
  body,
  interaction,
  completedInteraction,
}: BaseFeedInteraction & { body: string; interaction: InteractionReply }) {
  const textField = useField({
    initialValue: '',
  })
  const [replyModalOpened, { open: openReplyModal, close: closeReplyModal }] =
    useDisclosure(false)
  const [
    celebrationModalOpened,
    { open: openCelebrationModal, close: closeCelebrationModal },
  ] = useDisclosure(false)
  const [completed, setCompleted] = useState(false)

  const [interactionReply, { loading: replyMutationLoading }] =
    useInteractionReplyMutation()

  const handleReplyInteraction = useCallback(
    async (interactionId: string, replyMessage: string) => {
      await interactionReply({
        variables: {
          interactionId: interactionId,
          body: replyMessage,
        },
        onCompleted: (response) => {
          if (response.interactionReply.successful) {
            closeReplyModal()
            openCelebrationModal()
            setCompleted(true)

            setTimeout(() => {
              completedInteraction(
                response.interactionReply.result?.followUpInteraction?.id ??
                  undefined
              )
              closeCelebrationModal()
            }, 2000)
          } else {
            notifications.show({
              color: 'red',
              title: 'Error replying to interaction',
              message: 'Please try again later',
            })
          }
        },
        onError: (error) => {
          notifications.show({
            color: 'red',
            title: 'Error replying to interaction',
            message: `${error.message}`,
          })
        },
      })
    },
    [
      closeCelebrationModal,
      closeReplyModal,
      completedInteraction,
      interactionReply,
      openCelebrationModal,
    ]
  )

  return (
    <>
      {completed && (
        <Box px={8}>
          <Text fw={BOLD}>You replied with:</Text>
          <Divider />
          <Text>{textField.getValue()}</Text>
        </Box>
      )}
      <Stack pb={8} px={8} className="flex-shrink-0" mt="auto">
        <Button fullWidth onClick={openReplyModal} disabled={completed}>
          Reply
        </Button>
      </Stack>
      <Modal
        opened={replyModalOpened}
        onClose={closeReplyModal}
        title={interaction.ctaDetails || ''}
      >
        <Text>{body}</Text>
        <Group mt={8}>
          <Textarea
            placeholder="New reply message"
            maxLength={500}
            maxRows={5}
            className="flex-grow rounded-b-none"
            autosize
            resize="vertical"
            {...textField.getInputProps()}
          />
          <Button
            disabled={
              textField.getValue().length === 0 ||
              completed ||
              replyMutationLoading
            }
            onClick={() => {
              handleReplyInteraction(interaction.id, textField.getValue())
            }}
          >
            <FontAwesomeIcon icon={faPaperPlane} className="ml-2 h-4 w-4" />
          </Button>
        </Group>
      </Modal>
      <Modal
        opened={celebrationModalOpened}
        onClose={closeCelebrationModal}
        radius={0}
        centered
        transitionProps={{ transition: 'fade', duration: 200 }}
        withCloseButton={false}
        styles={{
          header: {
            background: 'green',
          },
          content: {
            background: 'green',
          },
        }}
      >
        <Stack justify="center">
          <Center>
            <Box style={{ borderRadius: 5000 }} bg="white" p={4}>
              <SwayCashIcon className="h=10 w-10" />
            </Box>
          </Center>

          <Text size="xl" fw={BOLD} c="white" ta="center">
            You{' '}
            {textField.getValue() && (
              <>
                <Text px={2} fw={BOLD} span>
                  replied
                </Text>
                <Text px={2} span>
                  {textField.getValue()}
                </Text>
                <Text px={2} fw={BOLD} span>
                  and
                </Text>
              </>
            )}
            <Text px={2} span fw={BOLD}>
              earned{' '}
              {interaction?.earningPotential?.amount
                ? `${(interaction.earningPotential?.amount / 100).toFixed(2)} SwayCash`
                : 'SwayCash'}
              !
            </Text>
          </Text>
        </Stack>
        {celebrationModalOpened && <Confetti initialVelocityY={40} />}
      </Modal>
    </>
  )
}
