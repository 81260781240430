import { AttachedImage } from '@context/mediaContext/MediaTypes.types'

export const resizeImage = async (
  sourceImg: Blob,
  height: number
): Promise<string> => {
  const imgElement = document.createElement('img')
  imgElement.src = URL.createObjectURL(sourceImg)
  await imgElement.decode()

  const canvas = document.createElement('canvas')
  const ctx = canvas.getContext('2d')
  const scaleFactor = height / imgElement.height
  canvas.width = imgElement.width * scaleFactor
  canvas.height = height

  ctx?.drawImage(imgElement, 0, 0, canvas.width, canvas.height)

  // const dataurl = canvas.toDataURL('image/jpeg')
  const dataurl = canvas.toDataURL(sourceImg.type)

  return dataurl
}

export const getImageDimensions = async (
  sourceImg: Blob
): Promise<{ width: number; height: number }> => {
  return new Promise((resolve, reject) => {
    const img = new Image()
    img.onload = () => {
      resolve({ width: img.width, height: img.height })
    }
    img.onerror = reject
    img.src = URL.createObjectURL(sourceImg)
  })
}

export const getThumbnailImageForWeb = async (
  sourceImg: Blob,
  name: string,
  height = 200
): Promise<AttachedImage> => {
  const resizedImage = await resizeImage(sourceImg, height)
  const dimensions = await getImageDimensions(sourceImg)
  const blob = await fetch(resizedImage).then((res) => res.blob())
  const thumbnailImage = {
    url: resizedImage,
    blob,
    name,
    height,
    width: dimensions.width,
    mimeType: sourceImg.type,
    localUrl: resizedImage,
    size: sourceImg.size,
  }

  return thumbnailImage
}

export const getOriginalImageForWeb = async (
  sourceImg: Blob,
  name: string
): Promise<AttachedImage> => {
  const dimensions = await getImageDimensions(sourceImg)
  const originalImage = {
    url: URL.createObjectURL(sourceImg),
    blob: sourceImg,
    name,
    height: dimensions.height,
    width: dimensions.width,
    mimeType: sourceImg.type,
    localUrl: URL.createObjectURL(sourceImg),
    size: sourceImg.size,
  }

  return originalImage
}
