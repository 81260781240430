import { ConnectionStatus, ConnectionType, Network } from '@capacitor/network'
import { createContext, useEffect, useState } from 'react'

interface ContextProps {
  connected: boolean
  connectionType: ConnectionType
}

export const NetworkContext = createContext<ContextProps>({
  connected: false,
  connectionType: 'unknown',
})

export const NetworkProvider: React.FC<{
  children: React.ReactNode
}> = ({ children }) => {
  const [connectionStatus, setConnectionStatus] = useState<ConnectionStatus>({
    connected: false,
    connectionType: 'unknown',
  })

  useEffect(() => {
    Network.getStatus().then((status) => {
      setConnectionStatus((prev) => ({
        ...prev,
        ...status,
      }))
    })
  }, [])

  useEffect(() => {
    Network.addListener('networkStatusChange', (status) => {
      setConnectionStatus((prev) => ({
        ...prev,
        ...status,
      }))
    })

    return () => {
      Network.removeAllListeners()
    }
  }, [])

  return (
    <NetworkContext.Provider value={connectionStatus}>
      {children}
    </NetworkContext.Provider>
  )
}
