import { Center } from '@mantine/core'
import { ErrorBoundary } from 'react-error-boundary'

export const MemberReferralsRoutes = [
  {
    path: 'referrals',
    async lazy() {
      const { MemberReferralsPage } = await import('./MemberReferrals.page')
      return {
        element: (
          <ErrorBoundary fallback={<Center>Something went wrong</Center>}>
            <MemberReferralsPage />
          </ErrorBoundary>
        ),
      }
    },
  },
]

export const VendorReferralsRoutes = []
