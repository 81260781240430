import { PickedFile } from '@capawesome/capacitor-file-picker'
import {
  AttachedImageCombined,
  AttachedMedia,
  AttachedVideo,
} from '@context/mediaContext/MediaTypes.types'
import {
  MediaPointerStatus,
  MediaType,
  SignedUploadUrlPartsFragment,
  SignedUrlKey,
} from '@swaydm/graphql'
import { UPLOAD_STATE } from '@swaydm/ui'
import { base64ToArrayBuffer } from '@util/dataTransformUtils'
import { uploadToMuxWeb, uploadToS3 } from '@util/fileUpload'
import { getOriginalImageForWeb, getThumbnailImageForWeb } from '@util/images'
import videoPlaceholder from '../../assets/video-placeholder-square.png'

/*
 * Gets and sets the image when on a web platform
 */
export const handleWebImage = async ({
  addAttachedMedia,
  setMediaPointerStatus,
  updateUploadProgress,
  uploadError,
  pickedFile,
  mediaPointerId,
  uploadUrls,
  mediaType,
}: {
  addAttachedMedia: (media: AttachedMedia) => void
  setMediaPointerStatus: ({
    variables,
  }: {
    variables: {
      mediaPointerId: string
      status: MediaPointerStatus
    }
  }) => void
  updateUploadProgress: (mediaPointerId: string, progress: number) => void
  uploadError: (mediaPointerId: string, error: any) => void
  pickedFile: PickedFile
  mediaPointerId: string
  uploadUrls: SignedUploadUrlPartsFragment[]
  mediaType: string
}) => {
  const originalUploadUrl = uploadUrls.find(
    (url) => url.key === SignedUrlKey.Original
  )

  if (!pickedFile.blob || !originalUploadUrl) return

  const thumbnail = await getThumbnailImageForWeb(
    pickedFile.blob,
    pickedFile.name,
    200
  )

  const original = await getOriginalImageForWeb(
    pickedFile.blob,
    pickedFile.name
  )

  addAttachedMedia({
    mediaPointerId: mediaPointerId,
    thumbnail,
    original,
    uploadProgress: 0,
    mediaType: mediaType === 'image' ? MediaType.Image : MediaType.Video,
  } as AttachedImageCombined)

  const metadata = JSON.parse(originalUploadUrl.metadata || '')
  metadata['credentials'] =
    JSON.parse(metadata['metadata'] || '')['credentials'] || {}

  await Promise.all([
    uploadToS3(
      originalUploadUrl.url,
      original.blob,
      metadata,
      (percent: number) => {
        updateUploadProgress(mediaPointerId, percent)
      }
    ),
  ])
    .then((values) => {
      setMediaPointerStatus({
        variables: {
          mediaPointerId: mediaPointerId,
          status: MediaPointerStatus.Available,
        },
      })
      return values
    })
    .catch((err) => {
      uploadError(mediaPointerId, err)
    })
}

export const handleNativeImage = async ({
  addAttachedMedia,
  setMediaPointerStatus,
  updateUploadProgress,
  uploadError,
  pickedFile,
  mediaPointerId,
  uploadUrls,
  mediaType,
}: {
  addAttachedMedia: (media: AttachedMedia) => void
  setMediaPointerStatus: ({
    variables,
  }: {
    variables: {
      mediaPointerId: string
      status: MediaPointerStatus
    }
  }) => void
  updateUploadProgress: (mediaPointerId: string, progress: number) => void
  uploadError: (mediaPointerId: string, error: any) => void
  pickedFile: PickedFile
  mediaPointerId: string
  uploadUrls: SignedUploadUrlPartsFragment[]
  mediaType: string
}) => {
  const [originalUploadUrl] = uploadUrls

  const byteData = await base64ToArrayBuffer(pickedFile.data || '')

  const fileBlob = new Blob([new Uint8Array(byteData)], {
    type: pickedFile.mimeType,
  })

  const thumbnail = await getThumbnailImageForWeb(
    fileBlob,
    pickedFile.name,
    200
  )
  const original = await getOriginalImageForWeb(fileBlob, pickedFile.name)

  addAttachedMedia({
    mediaPointerId: mediaPointerId,
    thumbnail,
    original,
    uploadProgress: 0,
    mediaType: mediaType === 'image' ? MediaType.Image : MediaType.Video,
    uploadState: UPLOAD_STATE.Uploading,
  } as AttachedImageCombined)

  const metadata = JSON.parse(originalUploadUrl.metadata || '')
  metadata['credentials'] =
    JSON.parse(metadata['metadata'] || '')['credentials'] || {}

  await Promise.all([
    uploadToS3(
      originalUploadUrl.url,
      original.blob,
      metadata,
      (percent: number) => {
        updateUploadProgress(mediaPointerId, percent)
      }
    ),
  ])
    .then((values) => {
      setMediaPointerStatus({
        variables: {
          mediaPointerId: mediaPointerId,
          status: MediaPointerStatus.Available,
        },
      })
      return values
    })
    .catch((err) => {
      uploadError(mediaPointerId, err)
    })
}

export const handleVideo = async ({
  addAttachedMedia,
  updateUploadProgress,
  uploadError,
  platform,
  pickedFile,
  mediaPointerId,
  uploadUrls,
}: {
  addAttachedMedia: (media: AttachedMedia) => void
  updateUploadProgress: (mediaPointerId: string, progress: number) => void
  uploadError: (mediaPointerId: string, error: any) => void
  platform: string
  pickedFile: PickedFile
  mediaPointerId: string
  uploadUrls: SignedUploadUrlPartsFragment[]
}) => {
  // add a new AttachedVideo to the attachedMedia state
  addAttachedMedia({
    mediaPointerId: mediaPointerId,
    uploadProgress: 0,
    mediaType: MediaType.Video,
    thumbnailUrl: videoPlaceholder, // placeholder since we don't have the actual URL yet
    originalUrl: '', // placeholder since we don't have the actual URL yet
    uploadState: UPLOAD_STATE.Uploading,
  } as AttachedVideo)

  // Since uploadToMux does not return anything and doesn't block the thread, we don't need to await it.
  if (platform == 'web') {
    uploadToMuxWeb(
      pickedFile.blob as File,
      uploadUrls[0].url,
      (progress: number) => {
        updateUploadProgress(mediaPointerId, progress)
      },
      (error: string) => {
        uploadError(mediaPointerId, error)
      }
    )
  } else {
    console.error('Video upload not supported on Native platforms.')
  }
}
