export enum FILTERS {
  SORT_BY = 'sortBy',
  PRICE_BETWEEN = 'priceBetween',
  VENDORS_ID = 'vendorsId',
  FULL_TEXT = 'fullText',
  RADIUS_KM = 'radiusKm',
  ONLY_DONATIONS = 'onlyDonations',
  EXCLUDE_EXPIRED = 'excludeExpired',
}

export const SORT_BY = {
  PRICE_HIGH_TO_LOW: 'priceHighToLow',
  PRICE_LOW_TO_HIGH: 'priceLowToHigh',
  MOST_POPULAR: 'mostPopular',
  MOST_VIEWED: 'mostViewed',
  CLOSEST: 'closest',
}

// Minimum allowable filter price.
export const MIN_FILTER_PRICE = 10
// Maximum allowable filter price.
export const MAX_FILTER_PRICE = 100
// Conversion factor for changing filter prices to cents.
export const SWAYCASH_CENT_CONVERSION_FACTOR = 10
// Conversion factor to remove decimals from a filter price.
export const SWAYCASH_WITHOUT_DECIMALS_FACTOR = 10
// Minimum radius for the filter by distance slider.
export const MIN_RADIUS = 10
// Maximum radius for the filter by distance slider.
export const MAX_RADIUS = 100
