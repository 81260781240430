import { PluginListenerHandle } from '@capacitor/core'
import { Device } from '@capacitor/device'
import { Keyboard, KeyboardInfo } from '@capacitor/keyboard'
import { SafeArea } from 'capacitor-plugin-safe-area'
import { isEqual } from 'lodash-es'
import { useEffect, useState } from 'react'

const useSafeAreaInsets = () => {
  const [screenDimensions, setScreenDimensions] = useState({
    safeAreaInsets: {
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
    },
    keyboardHeight: 0,
  })

  const handleKeyboardWillShow = (info: KeyboardInfo) => {
    setScreenDimensions((prev) => {
      const newDimensions = {
        ...prev,
        keyboardHeight: info.keyboardHeight,
      }

      if (!isEqual(prev, newDimensions)) {
        return newDimensions
      } else {
        return prev
      }
    })
  }

  const handleKeyboardDidShow = (info: KeyboardInfo) => {
    setScreenDimensions((prev) => {
      const newDimensions = {
        ...prev,
        keyboardHeight: info.keyboardHeight,
      }

      if (!isEqual(prev, newDimensions)) {
        return newDimensions
      } else {
        return prev
      }
    })
  }

  const handleKeyboardWillHide = () => {
    setScreenDimensions((prev) => {
      const newDimensions = {
        ...prev,
        keyboardHeight: 0,
      }

      if (!isEqual(prev, newDimensions)) {
        return newDimensions
      } else {
        return prev
      }
    })
  }

  useEffect(() => {
    Device.getInfo().then((info) => {
      if (info.platform === 'web') {
        return
      } else {
        Keyboard.addListener('keyboardWillShow', handleKeyboardWillShow)
        Keyboard.addListener('keyboardDidShow', handleKeyboardDidShow)
        Keyboard.addListener('keyboardWillHide', handleKeyboardWillHide)
        Keyboard.addListener('keyboardDidHide', handleKeyboardWillHide)

        if (info.platform === 'android') {
          SafeArea.setImmersiveNavigationBar()
        }
      }
    })

    SafeArea.getSafeAreaInsets().then((insets) => {
      setScreenDimensions((prev) => ({
        ...prev,
        safeAreaInsets: insets.insets,
      }))
    })

    return () => {
      Device.getInfo().then((info) => {
        if (info.platform === 'web') {
          return
        } else {
          Keyboard.removeAllListeners()
        }
      })
    }
  }, [])

  useEffect(() => {
    let eventListener: PluginListenerHandle

    SafeArea.addListener('safeAreaChanged', (data) => {
      const { insets } = data
      setScreenDimensions((prev) => ({
        ...prev,
        safeAreaInsets: insets,
      }))
    }).then((listener) => {
      eventListener = listener
    })

    return () => {
      if (eventListener) {
        eventListener.remove()
      }
    }
  }, [])

  useEffect(() => {
    for (const [key, value] of Object.entries(
      screenDimensions.safeAreaInsets
    )) {
      if (key === 'bottom') {
        document.documentElement.style.setProperty(
          `--safe-area-${key}`,
          `${screenDimensions.keyboardHeight ? 0 : value}px`
        )
      } else {
        document.documentElement.style.setProperty(
          `--safe-area-${key}`,
          `${value}px`
        )
      }
    }
  }, [screenDimensions])

  return screenDimensions
}

export default useSafeAreaInsets
