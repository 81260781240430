import { Capacitor } from '@capacitor/core'
import { LocationPermissionModal } from '@components/locationPermissionModal/LocationPermissionModal'
import { useRedemptionFiltersContext } from '@context/redemptionFiltersContext'
import {
  FILTERS,
  MAX_FILTER_PRICE,
  MAX_RADIUS,
  MIN_FILTER_PRICE,
  MIN_RADIUS,
  SORT_BY,
} from '@context/redemptionFiltersContext/redemptionFilters.constants'
import { useGetLocation } from '@hooks/useGetLocation'
import useSafeAreaInsets from '@hooks/useSafeAreaInserts'
import {
  Button,
  Checkbox,
  Drawer,
  Group,
  Radio,
  RangeSlider,
  Slider,
  Stack,
  Text,
  Title,
} from '@mantine/core'
import { useDisclosure } from '@mantine/hooks'
import { SwayCashIcon } from '@swaydm/ui'
import { useIsMobileViewport } from '@swaydm/ui/src/hooks/useIsMobileViewport'
import { useEffect } from 'react'
import { VendorsMultiSelect } from './VendorsMultiSelect'

// One mark per 10% of the slider
const priceSliderMarks = [
  { value: MIN_FILTER_PRICE },
  { value: 10 },
  { value: 20 },
  { value: 30 },
  { value: 40 },
  { value: 50 },
  { value: 60 },
  { value: 70 },
  { value: 80 },
  { value: 90 },
  { value: MAX_FILTER_PRICE },
]

const distanceSliderMarks = [
  { value: MIN_RADIUS },
  { value: 20 },
  { value: 30 },
  { value: 40 },
  { value: 50 },
  { value: 60 },
  { value: 70 },
  { value: 80 },
  { value: 90 },
  { value: MAX_RADIUS },
]

export const RedemptionsFilters = ({
  opened,
  close,
}: {
  opened: boolean
  close: () => void
}) => {
  const isMobile = useIsMobileViewport()
  const { filtersForm, defaultFilters } = useRedemptionFiltersContext()
  const { safeAreaInsets } = useSafeAreaInsets()
  const { permissions, setPermissionsChecked, requestAndGetLocationForWeb } =
    useGetLocation()

  const [
    locationModalOpened,
    { open: openLocationModal, close: closeLocationModal },
  ] = useDisclosure(false)

  const isNativePlatform = Capacitor.isNativePlatform()

  // Automatically check or uncheck the current location checkbox if the user location permission changes
  useEffect(() => {
    filtersForm.setFieldValue(
      'useCurrentLocation',
      permissions.location === 'granted'
    )

    // Set the initial values so when form.reset() is called the useCurrentLocation is set to the correct value
    filtersForm.setInitialValues({
      ...defaultFilters,
      useCurrentLocation: permissions.location === 'granted',
    })

    // Only run this effect when the permissions.location changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [permissions.location])

  const isLocationFeaturesDisabled = permissions.location === 'denied'

  const handleOpenLocationModal = (checked = true): boolean => {
    if (
      isNativePlatform &&
      checked &&
      permissions.location.startsWith('prompt')
    ) {
      openLocationModal()
      return true
    }
    return false
  }

  return (
    <Drawer
      opened={opened}
      onClose={close}
      position={isMobile ? 'bottom' : 'right'}
      size={'lg'}
    >
      <form style={{ paddingBottom: `${safeAreaInsets.bottom}px` }}>
        <Group justify={'center'}>
          <Title order={4}>Filters</Title>
        </Group>
        <Stack px={isMobile ? 18 : 32} gap={24}>
          <Radio.Group
            name={FILTERS.SORT_BY}
            {...filtersForm.getInputProps('sortBy')}
            onChange={(value) => {
              if (value === SORT_BY.CLOSEST && handleOpenLocationModal()) {
                return
              }

              filtersForm.setFieldValue('sortBy', value)
            }}
          >
            <Stack gap={8}>
              <Text size="sm" fw={600}>
                Sort By
              </Text>
              <Radio
                value={SORT_BY.CLOSEST}
                label="Closest"
                disabled={isLocationFeaturesDisabled}
              />
              <Radio value={SORT_BY.MOST_POPULAR} label="Most Popular" />
              <Radio value={SORT_BY.MOST_VIEWED} label="Most Viewed" />
              <Radio
                value={SORT_BY.PRICE_HIGH_TO_LOW}
                label="Price - High to Low"
              />
              <Radio
                value={SORT_BY.PRICE_LOW_TO_HIGH}
                label="Price - Low to High"
              />
            </Stack>
          </Radio.Group>

          <Stack gap={8}>
            <Text size="sm" fw={600}>
              Expiration
            </Text>
            <Checkbox
              label="Exclude expired"
              {...filtersForm.getInputProps('excludeExpired', {
                type: 'checkbox',
              })}
            />
          </Stack>

          <Stack>
            <Text size="sm" fw={600}>
              Donations
            </Text>
            <Checkbox
              label="Donations Only"
              {...filtersForm.getInputProps('onlyDonations', {
                type: 'checkbox',
              })}
            />
          </Stack>

          <Stack>
            <Group align="center" gap={32}>
              <Text size="sm" fw={600}>
                Distance
              </Text>
              <Text size="xs">
                {filtersForm.values['radiusKm'] === MAX_RADIUS ||
                !filtersForm.values['radiusKm']
                  ? '< ' + MAX_RADIUS
                  : '< ' + filtersForm.values['radiusKm'] / 10}{' '}
                miles
              </Text>
            </Group>
            <Slider
              step={10}
              label={null}
              size={'sm'}
              {...filtersForm.getInputProps('radiusKm')}
              value={filtersForm.values['radiusKm'] || 100}
              marks={distanceSliderMarks}
              min={10}
            />
            <Checkbox
              label="Use my current location"
              disabled={isLocationFeaturesDisabled}
              {...filtersForm.getInputProps('useCurrentLocation', {
                type: 'checkbox',
              })}
              onChange={(e) => {
                if (handleOpenLocationModal(e.currentTarget.checked)) {
                  return
                }

                if (
                  !isNativePlatform &&
                  e.currentTarget.checked &&
                  permissions.location.startsWith('prompt')
                ) {
                  requestAndGetLocationForWeb()
                  return
                }

                filtersForm.setFieldValue(
                  'useCurrentLocation',
                  e.currentTarget.checked
                )
              }}
            />
          </Stack>

          <Group w={'100%'} justify="space-between" mb={0}>
            <Text size="sm" fw={600}>
              Price
            </Text>
            <Text size="xs" c="dimmed">
              Prices in SwayCash
            </Text>
          </Group>
          <RangeSlider
            labelAlwaysOn
            step={10}
            min={MIN_FILTER_PRICE}
            size="sm"
            minRange={10}
            defaultValue={[MIN_FILTER_PRICE, MAX_FILTER_PRICE]}
            marks={priceSliderMarks}
            {...filtersForm.getInputProps('priceBetween')}
            label={(val) => {
              return (
                <Group wrap="nowrap" gap={4} mt={48}>
                  <SwayCashIcon className={isMobile ? 'h-3 w-3' : 'h-4 w-4'} />
                  <Text ml={2} size={isMobile ? 'xs' : 'sm'}>
                    {val !== MAX_FILTER_PRICE
                      ? `${(val / 10).toFixed(0)}`
                      : `Max`}
                  </Text>
                </Group>
              )
            }}
            styles={{
              label: {
                background: 'transparent',
                color: 'black',
              },
              markLabel: {
                display: 'none',
              },
            }}
          />
          <VendorsMultiSelect
            name={FILTERS.VENDORS_ID}
            {...filtersForm.getInputProps('vendorsId')}
          />
          <Group justify={'space-between'} grow>
            <Button
              variant="outline"
              size="md"
              onClick={() => {
                filtersForm.reset()
              }}
            >
              Reset
            </Button>
          </Group>
        </Stack>
      </form>
      <LocationPermissionModal
        opened={locationModalOpened}
        onClose={() => {
          setPermissionsChecked(true)
          closeLocationModal()
        }}
      />
    </Drawer>
  )
}
