import { LoginPage } from './login'

export const AuthRoutes = [
  {
    path: 'login',
    element: <LoginPage />,
  },
  {
    path: 'register',
    async lazy() {
      const { RegisterMemberPage } = await import(
        './register/RegisterMember.page'
      )

      return {
        Component: RegisterMemberPage,
      }
    },
  },
  {
    path: 'register/vendor/:displayName',
    async lazy() {
      const { RegisterMemberPage } = await import(
        './register/RegisterMember.page'
      )

      return {
        Component: RegisterMemberPage,
      }
    },
  },
  {
    path: 'register/vendor',
    async lazy() {
      const { RegisterVendorPage } = await import(
        './register/RegisterVendor.page'
      )

      return {
        Component: RegisterVendorPage,
      }
    },
  },
  {
    path: 'register/redemption/:redemptionId',
    async lazy() {
      const { RegisterMemberPage } = await import(
        './register/RegisterMember.page'
      )

      return {
        Component: RegisterMemberPage,
      }
    },
  },
  {
    path: 'confirm',
    async lazy() {
      const { ConfirmAccountPage } = await import('./ConfirmAccount.page')
      return {
        Component: ConfirmAccountPage,
      }
    },
  },
  {
    path: 'token-auth',
    async lazy() {
      const { TokenAuth } = await import('./TokenAuth.page')
      return {
        Component: TokenAuth,
      }
    },
  },
  {
    path: 'users/reset_password',
    async lazy() {
      const { ResetPasswordPage } = await import(
        './resetPassword/ResetPassword.page'
      )
      return {
        Component: ResetPasswordPage,
      }
    },
    children: [
      {
        path: ':token',
        async lazy() {
          const { ResetPasswordPage } = await import(
            './resetPassword/ResetPassword.page'
          )
          return {
            Component: ResetPasswordPage,
          }
        },
      },
    ],
  },
  {
    path: 'thankYou/:email',
    async lazy() {
      const { RegisterEmailSentPage } = await import('./RegisterEmailSent.page')
      return {
        Component: RegisterEmailSentPage,
      }
    },
  },
]
