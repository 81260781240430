export const VendorQRCodesRoutes = [
  {
    path: 'qrcodes',
    async lazy() {
      const { QRCodesPage } = await import('./QRCodes.page')
      return {
        Component: QRCodesPage,
      }
    },
  },
]
