import {
  faCircleExclamation,
  faClock,
  faReply,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Stack, Text } from '@mantine/core'
import { InteractionStatus, Money } from '@swaydm/graphql'
import { SEMI_BOLD, SwayCashIcon, timeDifference } from '../../../..'
import { Direction } from '../message.types'
import { InteractionTimeRemaining } from './InteractionTimeRemaining'

type ReplyInteractionProps = {
  direction: Direction
  earningPotential?: Money
  expiresAt: string
  interactionId: string
  receiver: {
    firstName: string
    lastName: string
    id: string
  }
  status: InteractionStatus
  onReplyInteraction: (interactionId: string) => void
}

export const ReplyInteraction = ({
  direction,
  earningPotential,
  expiresAt,
  interactionId,
  receiver,
  status,
  onReplyInteraction,
}: ReplyInteractionProps) => {
  return (
    <>
      {direction === 'incoming' && status === InteractionStatus.Pending && (
        <PendingReplyInteraction
          earningPotential={earningPotential}
          expiresAt={expiresAt}
          interactionId={interactionId}
          onReplyInteraction={onReplyInteraction}
        />
      )}
      {direction === 'incoming' && status === InteractionStatus.Complete && (
        <CompleteReplyInteraction earningPotential={earningPotential} />
      )}
      {direction === 'incoming' && status === InteractionStatus.Expired && (
        <ExpiredReplyInteraction expiresAt={expiresAt} />
      )}
      {direction === 'outgoing' && status === InteractionStatus.Pending && (
        <OutgoingPendingReplyInteraction
          earningPotential={earningPotential}
          expiresAt={expiresAt}
          receiver={receiver}
        />
      )}
      {direction === 'outgoing' && status === InteractionStatus.Complete && (
        <OutgoingCompleteReplyInteraction
          earningPotential={earningPotential}
          receiver={receiver}
        />
      )}
      {direction === 'outgoing' && status === InteractionStatus.Expired && (
        <OutgoingExpiredReplyInteraction />
      )}
    </>
  )
}

function PendingReplyInteraction({
  earningPotential,
  expiresAt,
  interactionId,
  onReplyInteraction,
}: {
  earningPotential?: Money
  expiresAt: string
  interactionId: string
  onReplyInteraction: (interactionId: string) => void
}) {
  if (!earningPotential?.amount) return null

  return (
    <Stack px={8} pb={4} gap={4}>
      <InteractionTimeRemaining
        earningPotential={earningPotential}
        expiresAt={expiresAt}
      />
      <Button
        onClick={() => onReplyInteraction(interactionId)}
        leftSection={
          <FontAwesomeIcon className="inline-block h-5 w-5" icon={faReply} />
        }
        fullWidth
      >
        Reply to earn
      </Button>
    </Stack>
  )
}

function CompleteReplyInteraction({
  earningPotential,
}: {
  earningPotential?: Money
}) {
  if (!earningPotential?.amount) return null

  return (
    <Text size="sm" className="my-2 flex flex-wrap items-center p-2">
      <Text span inline c={'green.8'}>
        <FontAwesomeIcon icon={faReply} className="mr-1 inline-block h-5 w-5" />
      </Text>
      You replied to earn
      <SwayCashIcon className="mx-1 inline-block h-5 w-5" />
      <Text span fw={SEMI_BOLD}>
        {(earningPotential?.amount / 100).toFixed(2)} SC
      </Text>
    </Text>
  )
}

function ExpiredReplyInteraction({ expiresAt }: { expiresAt: string }) {
  return (
    <Text size="sm" className="my-2 flex flex-wrap items-center p-2" c="red">
      <FontAwesomeIcon
        icon={faCircleExclamation}
        className="mr-1 inline-block h-5 w-5"
      />
      Expired at{' '}
      {new Date(expiresAt).toLocaleString('en-US', {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        hour12: true,
      })}
    </Text>
  )
}

function OutgoingPendingReplyInteraction({
  earningPotential,
  expiresAt,
  receiver,
}: {
  earningPotential?: Money
  expiresAt: string
  receiver: {
    firstName: string
    lastName: string
    id: string
  }
}) {
  if (!earningPotential?.amount) return null
  const expiresIn = timeDifference(new Date(expiresAt))

  return (
    <Text size="sm" className="my-2 flex flex-wrap items-center p-2">
      <FontAwesomeIcon icon={faClock} className="mr-1 inline-block h-5 w-5" />
      {receiver.firstName} has
      <Text span className="mx-1 font-bold">
        {`${expiresIn.hours} hours ${expiresIn.minutes} minutes `}
      </Text>
      remaining to reply to your message
    </Text>
  )
}

function OutgoingCompleteReplyInteraction({
  earningPotential,
  receiver,
}: {
  earningPotential?: Money
  receiver: {
    firstName: string
    lastName: string
    id: string
  }
}) {
  if (!earningPotential?.amount) return null

  return (
    <Text c="white" size="sm" className="my-2 flex flex-wrap items-center p-2">
      <FontAwesomeIcon icon={faReply} className="mr-1 inline-block h-5 w-5" />
      {receiver.firstName} replied to your message and you paid
      <SwayCashIcon color="white" className="mx-1 inline-block h-5 w-5" />
      <Text span fw={SEMI_BOLD}>
        {(earningPotential?.amount / 100).toFixed(2)} SC
      </Text>
    </Text>
  )
}

function OutgoingExpiredReplyInteraction() {
  return (
    <Text size="sm" className="my-2 flex flex-wrap items-center p-2">
      <FontAwesomeIcon
        icon={faCircleExclamation}
        className="mr-1 inline-block h-4 w-4"
      />
      <Text span fw={SEMI_BOLD} mx={4}>
        Message Expired
      </Text>{' '}
      and you were not charged
    </Text>
  )
}
