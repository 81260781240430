import { cn } from '../utils/utils'

interface Props {
  className?: string
  height?: number
  width?: number
}

export const Logo = ({ className = '', height, width }: Props) => (
  <svg
    aria-hidden="true"
    className={cn(`text-primary`, className)}
    style={{
      width: width ? `${width}px` : '120px',
      height: height ? `${height}px` : '33px',
    }}
    viewBox="0 0 120 33"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M33.0996 0.900117C32.4669 0.328085 31.6435 0.00757984 30.7872 0H3.22037C2.45227 0.0159431 1.71336 0.294735 1.12895 0.789103C0.777584 1.10385 0.495855 1.48738 0.301544 1.91549C0.107232 2.3436 0.00455757 2.807 0 3.27643V7.80102C3.83781 11.3055 6.99765 12.0196 9.15869 12.0346C15.6781 12.0856 18.6412 5.81176 25.2394 5.78475C29.0499 5.76975 32.1614 7.84302 34.159 9.55025V3.39644C34.1605 2.93116 34.0675 2.47033 33.8855 2.04145C33.7035 1.61257 33.4362 1.22441 33.0996 0.900117V0.900117Z"></path>
    <path d="M25.3786 8.10104C18.7805 8.07404 15.8174 14.2668 9.15869 14.2938C6.85843 14.2938 3.73188 13.5738 0 10.4353V23.103C0.0886115 23.927 0.458014 24.6964 1.04723 25.2843C2.03392 26.2594 3.46856 26.3224 4.11929 26.3614C4.35218 26.3737 4.58556 26.3737 4.81845 26.3614H5.06059C5.45708 26.3614 5.50248 26.3314 5.54183 26.3614C5.69316 26.4904 5.25732 27.2075 4.89412 27.7866C4.63383 28.2007 4.33419 28.6687 4.00125 29.1758L2.29421 31.5761C2.24579 31.6271 2.18525 31.6931 2.12169 31.7741C2.01879 31.9061 1.46793 32.6142 1.66164 32.8903C1.85535 33.1663 2.95403 32.8393 3.06299 32.8003C3.2277 32.744 3.38761 32.6748 3.5412 32.5932L15.1848 27.5586C16.0778 27.1579 17.0126 26.856 17.9723 26.6585C18.941 26.4521 19.9296 26.3514 20.9203 26.3584H30.2334C30.7674 26.4037 31.305 26.3371 31.8114 26.1629C32.3177 25.9887 32.7814 25.7108 33.1723 25.3473C33.752 24.763 34.102 23.9926 34.159 23.175V11.8155C32.0191 10.0033 29.0045 8.11604 25.3786 8.10104Z"></path>
    <path d="M46.4158 19.3069C45.5574 19.3069 44.7622 19.2194 44.03 19.0442C43.3105 18.869 42.6983 18.625 42.1934 18.3121L42.9697 16.1535C43.4367 16.4163 43.9795 16.6353 44.5981 16.8105C45.2166 16.9732 45.8288 17.0545 46.4348 17.0545C46.9018 17.0545 47.2363 16.9919 47.4383 16.8668C47.6403 16.7291 47.7413 16.554 47.7413 16.3412C47.7413 16.1535 47.6718 16.0159 47.533 15.9283C47.4067 15.8282 47.2237 15.7531 46.9839 15.703L44.8821 15.3652C44.0995 15.24 43.4936 14.9585 43.0644 14.5205C42.6352 14.0825 42.4206 13.5131 42.4206 12.8124C42.4206 12.1617 42.6036 11.6048 42.9697 11.1418C43.3358 10.6663 43.847 10.3034 44.5034 10.0531C45.1598 9.79033 45.9109 9.65894 46.7567 9.65894C47.4257 9.65894 48.0884 9.74028 48.7448 9.90296C49.4012 10.0531 49.9756 10.3034 50.4679 10.6538L49.6347 12.7748C49.2687 12.5246 48.8142 12.3181 48.2714 12.1554C47.7286 11.9802 47.2426 11.8926 46.8135 11.8926C46.2959 11.8926 45.9298 11.9677 45.7152 12.1179C45.5006 12.2555 45.3933 12.4245 45.3933 12.6247C45.3933 12.9625 45.6269 13.169 46.0939 13.2441L48.1957 13.6007C49.0036 13.7259 49.6221 14.0012 50.0513 14.4266C50.4931 14.8396 50.714 15.4027 50.714 16.116C50.714 17.1296 50.3227 17.9179 49.5401 18.481C48.7574 19.0316 47.716 19.3069 46.4158 19.3069Z"></path>
    <path d="M54.5829 19.1005L50.6634 9.86541H54.0717L56.1356 15.215L58.1806 9.86541H60.2823L62.3462 15.3088L64.4101 9.86541H67.6101L63.7285 19.1005H60.945L59.1084 14.558L57.3664 19.1005H54.5829Z"></path>
    <path d="M71.4046 19.3069C70.6851 19.3069 70.0476 19.1756 69.4922 18.9128C68.9494 18.65 68.5202 18.2933 68.2046 17.8429C67.9017 17.3799 67.7502 16.8543 67.7502 16.2661C67.7502 15.6029 67.9269 15.0773 68.2804 14.6894C68.6338 14.289 69.2019 14.0074 69.9845 13.8447C70.7671 13.6696 71.8086 13.582 73.1087 13.582H73.8283V13.3192C73.8283 12.8687 73.7084 12.5496 73.4685 12.3619C73.2413 12.1742 72.8437 12.0803 72.2756 12.0803C71.7959 12.0803 71.2658 12.1554 70.6851 12.3056C70.1044 12.4557 69.5364 12.6872 68.981 13.0001L68.1289 10.8039C68.4445 10.5787 68.8484 10.3785 69.3407 10.2033C69.833 10.0281 70.3506 9.8967 70.8934 9.8091C71.4362 9.70899 71.9474 9.65894 72.4271 9.65894C73.9798 9.65894 75.1285 9.99055 75.8732 10.6538C76.6306 11.317 77.0093 12.3619 77.0093 13.7884V19.1005H73.8661V17.7865C73.702 18.2621 73.4054 18.6375 72.9762 18.9128C72.547 19.1756 72.0232 19.3069 71.4046 19.3069ZM72.162 17.1296C72.6291 17.1296 73.0204 16.9794 73.336 16.6791C73.6642 16.3788 73.8283 15.9846 73.8283 15.4965V15.1587H73.1087C72.364 15.1587 71.8149 15.24 71.4614 15.4027C71.1206 15.5529 70.9502 15.7969 70.9502 16.1347C70.9502 16.4226 71.0512 16.6603 71.2531 16.848C71.4677 17.0357 71.7707 17.1296 72.162 17.1296Z"></path>
    <path d="M79.4041 22.4792L81.2787 18.4623L77.4728 9.86541H81.0136L83.0586 15.1024L85.1793 9.86541H88.5118L82.8124 22.4792H79.4041Z"></path>
    <path d="M88.0406 19.1005V15.6843H91.5436V19.1005H88.0406Z"></path>
    <path d="M96.7652 19.3069C95.9699 19.3069 95.263 19.113 94.6444 18.7251C94.0385 18.3371 93.5589 17.7803 93.2054 17.0545C92.8646 16.3287 92.6942 15.4715 92.6942 14.4829C92.6942 13.4819 92.8646 12.6247 93.2054 11.9114C93.5589 11.1856 94.0385 10.6287 94.6444 10.2408C95.263 9.8529 95.9699 9.65894 96.7652 9.65894C97.3584 9.65894 97.9012 9.78408 98.3935 10.0343C98.8985 10.2721 99.2835 10.5849 99.5486 10.9729V5.86731H102.919V19.1005H99.6243V17.8241C99.3845 18.2746 99.0058 18.6375 98.4882 18.9128C97.9707 19.1756 97.3963 19.3069 96.7652 19.3069ZM97.8444 16.848C98.3494 16.848 98.7659 16.6603 99.0941 16.2849C99.4223 15.9095 99.5864 15.3088 99.5864 14.4829C99.5864 13.6445 99.4223 13.0439 99.0941 12.681C98.7659 12.3056 98.3494 12.1179 97.8444 12.1179C97.3269 12.1179 96.904 12.3056 96.5758 12.681C96.2476 13.0439 96.0835 13.6445 96.0835 14.4829C96.0835 15.3088 96.2476 15.9095 96.5758 16.2849C96.904 16.6603 97.3269 16.848 97.8444 16.848Z"></path>
    <path d="M104.909 19.1005V9.86541H108.185V11.1043C108.475 10.6663 108.86 10.3159 109.34 10.0531C109.832 9.79033 110.394 9.65894 111.025 9.65894C111.656 9.65894 112.199 9.79659 112.654 10.0719C113.121 10.3472 113.474 10.7727 113.714 11.3483C114.03 10.8102 114.459 10.3972 115.002 10.1094C115.544 9.8091 116.144 9.65894 116.8 9.65894C117.886 9.65894 118.688 9.98429 119.205 10.635C119.735 11.2732 120 12.2743 120 13.6383V19.1005H116.63V13.7321C116.63 13.194 116.548 12.8061 116.384 12.5684C116.22 12.3306 115.948 12.2117 115.57 12.2117C115.103 12.2117 114.743 12.3744 114.49 12.6998C114.251 13.0251 114.131 13.4881 114.131 14.0888V19.1005H110.76V13.7321C110.76 13.194 110.678 12.8061 110.514 12.5684C110.35 12.3306 110.079 12.2117 109.7 12.2117C109.245 12.2117 108.892 12.3744 108.639 12.6998C108.4 13.0251 108.28 13.4881 108.28 14.0888V19.1005H104.909Z"></path>
  </svg>
)
