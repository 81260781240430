import { faArrowsRotate, faPlus } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button } from '@mantine/core'
import * as Slider from '@radix-ui/react-slider'
import { useState } from 'react'
import Cropper from 'react-easy-crop'

export type ImageCroppedArea = {
  x: number
  y: number
  width: number
  height: number
}

export type ImageCropperResult = {
  croppedArea: ImageCroppedArea
  croppedAreaPixels: ImageCroppedArea
  rotation: number
  zoom: number
}

export const ImageCropper = ({
  src,
  onCancel,
  onUpdateCrop,
  onUploadMedia,
  preventUpdate = false,
  children,
}: {
  src: string
  onCancel: () => void
  onUpdateCrop: (imageCropperResult: ImageCropperResult) => void
  onUploadMedia?: () => void
  preventUpdate: boolean
  children?: React.ReactNode
}) => {
  const [croppedArea, setCroppedArea] = useState<ImageCroppedArea>(
    {} as ImageCroppedArea
  )
  const [croppedAreaPixels, setCroppedAreaPixels] = useState<ImageCroppedArea>(
    {} as ImageCroppedArea
  )
  const [crop, setCrop] = useState({ x: 0, y: 0 })
  const [zoom, setZoom] = useState(1)
  const [rotation, setRotation] = useState(0)

  const onCropChange = (crop: { x: number; y: number }) => {
    setCrop(crop)
  }

  const onCropComplete = (
    croppedArea: ImageCroppedArea,
    croppedAreaPixels: ImageCroppedArea
  ) => {
    setCroppedArea(croppedArea)
    setCroppedAreaPixels(croppedAreaPixels)
  }

  const updateCrop = () => {
    onUpdateCrop({
      croppedAreaPixels: croppedAreaPixels,
      croppedArea: croppedArea,
      rotation: rotation,
      zoom: zoom,
    })
  }

  const rotateRight = () => {
    setRotation((rotation + 45) % 360)
  }

  return (
    <div>
      <div className="relative h-64 w-full">
        <Cropper
          image={src}
          crop={crop}
          minZoom={1}
          maxZoom={10}
          zoom={zoom}
          rotation={rotation}
          aspect={1}
          cropShape="round"
          showGrid={false}
          onCropChange={onCropChange}
          onCropComplete={onCropComplete}
          onZoomChange={(zoom: number) => setZoom(zoom)}
        />
      </div>
      <div className="flex items-center space-x-8 px-8 pb-2 pt-6">
        <div className="grow">
          <Slider.Root
            className="w-50 relative flex h-5 touch-none select-none items-center"
            defaultValue={[1]}
            value={[zoom]}
            onValueChange={(zoom: number[]) => setZoom(zoom[0])}
            max={10}
            min={1}
            step={0.1}
          >
            <Slider.Track className="relative h-0.5 flex-grow rounded-full bg-muted bg-opacity-30">
              <Slider.Range className="absolute h-full rounded-full bg-primary" />
            </Slider.Track>
            <Slider.Thumb
              className="block h-6 w-6 rounded-full bg-violet-300 shadow-lg hover:bg-primary focus:outline-none focus:ring-4 focus:ring-popover focus:ring-opacity-50"
              aria-label="Zoom"
            />
          </Slider.Root>
        </div>
        <div>
          <Button
            variant="outline"
            className="hidden h-10 w-10 rounded-full p-2"
            size="icon"
            onClick={rotateRight}
          >
            <FontAwesomeIcon icon={faArrowsRotate} className="h-5 w-5" />
          </Button>
        </div>
      </div>
      <div className="flex justify-center space-x-4 pt-4">
        <Button variant="outline" onClick={onCancel}>
          Cancel
        </Button>
        <Button disabled={preventUpdate} onClick={updateCrop}>
          Update
        </Button>
        {onUploadMedia && (
          <Button
            variant="outline"
            className="h-10 w-10 rounded-full p-2"
            size="icon"
            onClick={onUploadMedia}
          >
            <FontAwesomeIcon icon={faPlus} />
          </Button>
        )}
      </div>
      {children}
    </div>
  )
}
