import { PermissionState } from '@capacitor/core'
import { useAnalyticsContext } from '@context/analyticsContext'
import { useCallback } from 'react'

export interface SwayAnaltyicsAdditionalProperties {
  additional_properties?: object
}

export interface UserLogin {
  userId: string
}

export interface UserLogout {
  userId: string
}

export interface UserSwitch {
  fromUserId: string
  toUserId: string
}

export interface FeedItem {
  itemId: string
  type:
    | 'broadcast'
    | 'persistent-broadcast'
    | 'redemption-suggestion'
    | 'vendor-suggestion'
}

export interface AnalyticsDuration {
  durationMs: number
}

export interface FeedItemAction extends FeedItem, AnalyticsDuration {
  action: 'seen' | 'skipped'
}

export interface PushNotificationAction {
  userId: string
  route: string
  event: string
  action: string
}

export interface LocationPermission {
  locationPermission: PermissionState
  platform: 'ios' | 'android' | 'web'
}

export interface SwayAnaltyics {
  userLogin(
    userLogin: UserLogin,
    additional_properties?: SwayAnaltyicsAdditionalProperties
  ): void
  userLogout(
    userLogout: UserLogout,
    additional_properties?: SwayAnaltyicsAdditionalProperties
  ): void
  userSwitch(
    userSwitch: UserSwitch,
    additional_properties?: SwayAnaltyicsAdditionalProperties
  ): void
  feedItemAction(
    feedItemAction: FeedItemAction,
    additional_properties?: SwayAnaltyicsAdditionalProperties
  ): void
  pushNotificationAction(
    pushNotificationAction: PushNotificationAction,
    additional_properties?: SwayAnaltyicsAdditionalProperties
  ): void
  locationPermission(
    locationPermission: LocationPermission,
    additional_properties?: SwayAnaltyicsAdditionalProperties
  ): void
}

const useAnalytics = (): SwayAnaltyics => {
  const { analytics } = useAnalyticsContext()

  const userLogin = useCallback(
    (
      userLogin: UserLogin,
      additional_properties?: SwayAnaltyicsAdditionalProperties
    ) => {
      if (analytics) {
        analytics.identify(userLogin.userId, additional_properties)
        analytics.track('user:login', {
          ...userLogin,
          ...additional_properties,
        })
      }
    },
    [analytics]
  )

  const userLogout = useCallback(
    (
      userLogout: UserLogout,
      additional_properties?: SwayAnaltyicsAdditionalProperties
    ) => {
      if (analytics) {
        analytics.track('user:logout', {
          ...userLogout,
          ...additional_properties,
        })
      }
    },
    [analytics]
  )

  const userSwitch = useCallback(
    (
      userSwitch: UserSwitch,
      additional_properties?: SwayAnaltyicsAdditionalProperties
    ) => {
      if (analytics) {
        analytics.track('user:switch', {
          from_user_id: userSwitch.fromUserId,
          to_user_id: userSwitch.toUserId,
          ...additional_properties,
        })
        analytics.identify(userSwitch.toUserId, additional_properties)
      }
    },
    [analytics]
  )

  const feedItemAction = useCallback(
    (
      feedItemAction: FeedItemAction,
      additional_properties?: SwayAnaltyicsAdditionalProperties
    ) => {
      if (analytics) {
        analytics.track('feed:item-action', {
          ...feedItemAction,
          ...additional_properties,
        })
      }
    },
    [analytics]
  )

  const locationPermission = useCallback(
    (
      locationPermission: LocationPermission,
      additional_properties?: SwayAnaltyicsAdditionalProperties
    ) => {
      if (analytics) {
        analytics.track('location:permission', {
          ...locationPermission,
          ...additional_properties,
        })
      }
    },
    [analytics]
  )

  const pushNotificationAction = useCallback(
    (
      pushNotificationAction: PushNotificationAction,
      additional_properties?: SwayAnaltyicsAdditionalProperties
    ) => {
      if (analytics) {
        analytics.track('push:action', {
          ...pushNotificationAction,
          ...additional_properties,
        })
      }
    },
    [analytics]
  )

  return {
    userLogin,
    userLogout,
    userSwitch,
    feedItemAction,
    pushNotificationAction,
    locationPermission,
  }
}

export default useAnalytics
