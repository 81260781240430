import { createContext, useEffect, useMemo, useState } from 'react'
import { useLocation } from 'react-router-dom'

export interface LocationHistoryContextType {
  hasHistory: boolean
}

export const LocationHistoryContext = createContext<LocationHistoryContextType>(
  {
    hasHistory: false,
  }
)

export function LocationHistoryProvider({
  children,
}: {
  children: React.ReactNode
}) {
  const [locationHistory, setLocationHistory] = useState(new Set<string>())

  const location = useLocation()

  useEffect(() => {
    const path = location.pathname

    if (path) {
      setLocationHistory((prev) => new Set([path, ...prev]))
    }
  }, [location.pathname, location])

  const context = useMemo(() => {
    return {
      hasHistory: locationHistory.size > 1,
    }
  }, [locationHistory])

  return (
    <LocationHistoryContext.Provider value={context}>
      {children}
    </LocationHistoryContext.Provider>
  )
}
