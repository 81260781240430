import { useAuth } from '@hooks/useAuth'
import { FeatureFlag, useFeaturesQuery } from '@swaydm/graphql'
import { noop } from 'lodash-es'
import { createContext, useCallback, useEffect, useMemo, useState } from 'react'

export interface FeatureFlagContextType {
  allFlags: Array<
    | (FeatureFlag & {
        override?: boolean
        overrideValue?: boolean
      })
    | null
  >
  contentFeed: boolean
  experimentalFeatures: boolean
  removeOverride: (flagName: string) => void
  setFlagOverride: (flagName: string, value: boolean) => void
}

export const FeatureFlagContext = createContext<FeatureFlagContextType>({
  allFlags: [],
  contentFeed: false,
  experimentalFeatures: false,
  removeOverride: () => noop,
  setFlagOverride: () => noop,
})

export function FeatureFlagContextProvider({
  children,
}: {
  children: React.ReactNode
}): JSX.Element {
  const { currentUser } = useAuth()

  const { data, refetch } = useFeaturesQuery({
    fetchPolicy: 'cache-first',
  })

  const [featureFlags, setFeatureFlags] = useState<FeatureFlagContextType>({
    allFlags: [],
    contentFeed: false,
    experimentalFeatures: false,
    removeOverride: () => noop,
    setFlagOverride: () => noop,
  })

  useEffect(() => {
    refetch()
  }, [currentUser, refetch])

  const applyOverrides = useCallback(
    (
      features: Array<FeatureFlag | null> | undefined,
      overrides: { [key: string]: boolean }
    ) => {
      if (features) {
        const updatedFeatures = features.map((flag) => {
          if (flag && overrides[flag.name] !== undefined) {
            return {
              ...flag,
              enabled: overrides[flag.name],
              override: true,
              overrideValue: overrides[flag.name],
            }
          }
          return flag
        })

        const contentFeed = updatedFeatures.some(
          (flag: FeatureFlag | null) =>
            flag?.name === 'fe_content_feed' && flag.enabled
        )
        const experimentalFeatures = updatedFeatures.some(
          (flag: FeatureFlag | null) =>
            flag?.name === 'fe_experimental_features' && flag.enabled
        )

        setFeatureFlags((prev) => ({
          ...prev,
          allFlags: updatedFeatures,
          contentFeed,
          experimentalFeatures,
        }))
      }
    },
    []
  )

  const setFlagOverride = useCallback(
    (flagName: string, value: boolean) => {
      const userOverrides = localStorage.getItem(
        `featureFlags_${currentUser?.id}`
      )
      let overrides: { [key: string]: boolean } = {}
      if (userOverrides) {
        overrides = JSON.parse(userOverrides) as { [key: string]: boolean }
      }
      overrides[flagName] = value
      localStorage.setItem(
        `featureFlags_${currentUser?.id}`,
        JSON.stringify(overrides)
      )
      applyOverrides(data?.features, overrides)
    },
    [currentUser?.id, applyOverrides, data?.features]
  )

  const removeOverride = useCallback(
    (flagName: string) => {
      const userOverrides = localStorage.getItem(
        `featureFlags_${currentUser?.id}`
      )
      let overrides: { [key: string]: boolean } = {}
      if (userOverrides) {
        overrides = JSON.parse(userOverrides) as { [key: string]: boolean }
      }
      delete overrides[flagName]

      localStorage.setItem(
        `featureFlags_${currentUser?.id}`,
        JSON.stringify(overrides)
      )
      applyOverrides(data?.features, overrides)
    },
    [currentUser?.id, applyOverrides, data?.features]
  )

  useEffect(() => {
    if (data) {
      const overrides = JSON.parse(
        localStorage.getItem(`featureFlags_${currentUser?.id}`) || '{}'
      )
      applyOverrides(data.features, overrides)
    }
  }, [data, currentUser, applyOverrides])

  const value = useMemo(
    () => ({
      ...featureFlags,
      removeOverride,
      setFlagOverride,
    }),
    [featureFlags, removeOverride, setFlagOverride]
  )

  if (!data) {
    return <></>
  }

  return (
    <FeatureFlagContext.Provider value={value}>
      {children}
    </FeatureFlagContext.Provider>
  )
}
