import { App as CapacitorApp } from '@capacitor/app'
import { Capacitor } from '@capacitor/core'
import * as LiveUpdates from '@capacitor/live-updates'
import { Preferences } from '@capacitor/preferences'
import { ScreenOrientation } from '@capacitor/screen-orientation'
import { SplashScreen } from '@capacitor/splash-screen'
import { StatusBar } from '@capacitor/status-bar'
import {
  AnalyticsProvider,
  AppProvider,
  NetworkProvider,
} from '@context/index.ts'
import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App.tsx'
import './index.css'

async function initializeLiveUpdates() {
  CapacitorApp.addListener('resume', async () => {
    console.info('App Resumed, checking for updates')
    const shouldUpdate = await Preferences.get({ key: 'sway-should-update' })

    if (shouldUpdate.value === 'true') {
      await LiveUpdates.reload()
    } else {
      const result = await LiveUpdates.sync()
      await Preferences.set({
        key: 'sway-should-update',
        value: result.activeApplicationPathChanged ? 'true' : 'false',
      })
    }
  })

  const result = await LiveUpdates.sync()
  await Preferences.set({
    key: 'sway-should-update',
    value: result.activeApplicationPathChanged ? 'true' : 'false',
  })
}

if (Capacitor.getPlatform() === 'android') {
  StatusBar.setOverlaysWebView({ overlay: true }).then(() => {
    console.info('setting overlay to true')
  })
}

// Set up mobile specific settings
if (
  Capacitor.getPlatform() === 'ios' ||
  Capacitor.getPlatform() === 'android'
) {
  ScreenOrientation.lock({ orientation: 'portrait' })

  // Initialize Live Updates
  try {
    initializeLiveUpdates()
  } catch (error) {
    console.error('[Main] Live Update Error: ', error)
    SplashScreen.hide()
  }
}

SplashScreen.hide()

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <React.StrictMode>
    <AppProvider>
      <NetworkProvider>
        <AnalyticsProvider>
          <App />
        </AnalyticsProvider>
      </NetworkProvider>
    </AppProvider>
  </React.StrictMode>
)
