import { Button } from '@mantine/core'

export const ButtonsLocationPermissionModal = ({
  requestLocationPermission,
  onClose,
}: {
  requestLocationPermission: () => void
  onClose: () => void
}) => {
  return (
    <>
      <Button
        fullWidth
        color={'deep-blue.9'}
        onClick={async () => {
          await requestLocationPermission()
          onClose()
        }}
      >
        Share Location
      </Button>
      <Button color="white" variant="transparent" fullWidth onClick={onClose}>
        Skip
      </Button>
    </>
  )
}
