import type { ImgixUrl } from 'typescript-imgix-url-params'

export type GqlImgixOptions = Array<{ key: string; value: any }>

/**
 *
 * @param params : ImgixUrl.Params
 * @returns Array<{key: string, value: any}>[]
 * @example
 * const imgixOpts = getImgixOptions({
  w: 256,
  h: 256,
  fit: 'crop',
  auto: 'compress',
})
 */
export const generateImgixOptions = (
  params: ImgixUrl.Params
): GqlImgixOptions => {
  return Object.entries(params).map(([key, value]) => ({
    key,
    value: String(value),
  }))
}
