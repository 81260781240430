import { create } from 'zustand'

type State = {
  lastSeenItemId: string | undefined
}

type Action = {
  updateLastSeenItemId: (lastItemId: string | undefined) => void
}

const useFeedStore = create<State & Action>((set) => ({
  lastSeenItemId: undefined,

  updateLastSeenItemId: (lastSeenItemId) => set({ lastSeenItemId }),
}))

export default useFeedStore
