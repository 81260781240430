import useUploadMediaContext from '@context/mediaContext/useUploadMediaContext'
import { faCalendar, faClose } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useAuth } from '@hooks/useAuth'
import {
  Box,
  Button,
  Card,
  CloseIcon,
  ComboboxItem,
  ComboboxLikeRenderOptionInput,
  Divider,
  Group,
  Image,
  Indicator,
  LoadingOverlay,
  Modal,
  NumberInput,
  Popover,
  Progress,
  Select,
  Stack,
  Switch,
  Text,
  TextInput,
  Textarea,
} from '@mantine/core'
import { DateTimePicker } from '@mantine/dates'
import { useField, useForm, zodResolver } from '@mantine/form'
import { useDisclosure } from '@mantine/hooks'
import {
  CommunityDiscoveryQueryAttributes,
  MediaType,
  MoneyInput,
  PageFilterOp,
  RedemptionDonationProgress,
  RedemptionLimitInput,
  RedemptionLimitPartsFragment,
  RedemptionLimitScope,
  RedemptionLimitType,
  RedemptionLimitUnit,
  useCommunitiesDiscoveryQuery,
  useRedemptionUpdateMutation,
  useUserQuery,
} from '@swaydm/graphql'
import {
  InputLabelSectionHeader,
  RedemptionDonationSection,
  SwayCashIcon,
} from '@swaydm/ui'
import { notifications } from '@util/notifications/notifications'
import dayjs from 'dayjs'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { z } from 'zod'
import { RedemptionMainPreview } from './RedemptionMainPreview'

const MIN_DESCRIPTION_SIZE = 20
const MAX_DESCRIPTION_SIZE = 1000

const redemptionFormValidation = z.object({
  amountInCents: z.object({
    currency: z.string(),
    amount: z.number().int().min(1),
  }),
  description: z.string().min(MIN_DESCRIPTION_SIZE).max(MAX_DESCRIPTION_SIZE),
  name: z.string().min(1).max(50),
  primaryMediaId: z.string().optional(),
  linkedProfileId: z.string().optional(),
})

export type EditRedemptionFormShape = {
  id: string
  amountInCents: MoneyInput | undefined
  description: string | undefined
  limits: Array<RedemptionLimitInput>
  name: string | undefined
  primaryMediaId: string | undefined
  validEnd: Date | undefined
  linkedProfileId: string | undefined
  donationGoal: number | string | undefined
}

type EditRedemptionFormProps = {
  initialValues: EditRedemptionFormShape | undefined
  redemptionLoading: boolean
}

type DonationGoalInputProps = {
  isProfileNonProfit: boolean
  nonProfitCommunities:
    | Array<{
        label: string
        value: string
        image: string
      }>
    | undefined
  handleDonationGoalOnBlur: () => void
}

type FormProps = {
  linkedProfileIdValue?: string | undefined
  linkedProfileIdOnChange: (value: string | null) => void
  donationGoalValue?: number | string | undefined
  donationGoalOnChange: (value: number | string | undefined) => void
  values: EditRedemptionFormShape
  setValues: (
    value: React.SetStateAction<Partial<EditRedemptionFormShape>>
  ) => void
}

type DonationGoalNumberInputProps = Pick<
  DonationGoalInputProps,
  'handleDonationGoalOnBlur'
> &
  Pick<
    FormProps,
    'donationGoalValue' | 'donationGoalOnChange' | 'values' | 'setValues'
  >

function DonationGoalNumberInput({
  handleDonationGoalOnBlur,
  values,
  setValues,
  donationGoalValue,
  donationGoalOnChange,
}: DonationGoalNumberInputProps) {
  return (
    <NumberInput
      placeholder="Enter Goal"
      label="Donation Goal"
      styles={{
        label: {
          fontSize: '0.8rem',
        },
      }}
      step={values.amountInCents?.amount as number}
      leftSection={<SwayCashIcon className="h-4 w-4" />}
      allowNegative={false}
      allowDecimal={false}
      rightSection={
        <CloseIcon
          onClick={() => {
            setValues({
              ...values,
              donationGoal: '',
              limits: values.limits.filter(
                (limit) =>
                  limit.limitType !== RedemptionLimitType.Count ||
                  limit.limitScope !== RedemptionLimitScope.Global
              ),
            })
          }}
          className="cursor-pointer"
        />
      }
      onChange={donationGoalOnChange}
      value={donationGoalValue}
      min={1}
      description="Must be a multiple of the redemption cost"
      onBlur={handleDonationGoalOnBlur}
    />
  )
}

interface ExtendedComboboxItem extends ComboboxItem {
  image: string
}

function DonationGoalInput({
  isProfileNonProfit,
  nonProfitCommunities,
  handleDonationGoalOnBlur,
  linkedProfileIdValue,
  linkedProfileIdOnChange,
  donationGoalValue,
  donationGoalOnChange,
  values,
  setValues,
}: DonationGoalInputProps & FormProps) {
  return (
    <Card withBorder>
      <Stack gap={8}>
        {!isProfileNonProfit && (
          <Select
            label="Who are you donating to?"
            required
            styles={{
              label: {
                fontSize: '0.8rem',
              },
            }}
            data={nonProfitCommunities}
            renderOption={(option) =>
              renderLinkProfileOption(
                option as ComboboxLikeRenderOptionInput<ExtendedComboboxItem>
              )
            }
            placeholder={'Select a Non-Profit account'}
            value={linkedProfileIdValue}
            onChange={(value) => {
              linkedProfileIdOnChange(value)
            }}
          />
        )}
        <DonationGoalNumberInput
          handleDonationGoalOnBlur={handleDonationGoalOnBlur}
          values={values}
          setValues={setValues}
          donationGoalValue={donationGoalValue}
          donationGoalOnChange={donationGoalOnChange}
        />
      </Stack>
    </Card>
  )
}

const renderLinkProfileOption = (
  option: ComboboxLikeRenderOptionInput<ExtendedComboboxItem>
) => {
  return (
    <Group>
      <Box h={32} w={32}>
        <Image
          src={option.option.image}
          alt="Redemption Image"
          width={32}
          height={32}
        />
      </Box>
      <Text>{option.option.label}</Text>
    </Group>
  )
}

export function EditRedemptionForm({
  initialValues,
  redemptionImage,
  redemptionLoading,
}: EditRedemptionFormProps & {
  redemptionImage?: {
    src: string
    srcSet: string
  }
}) {
  const { currentUser } = useAuth()

  const { data: userProfileData } = useUserQuery({
    skip: !currentUser,
  })

  const isProfileNonProfit = !!userProfileData?.user.profile?.isNonProfit

  const navigate = useNavigate()
  const { attachedMedia, clearAttachedMedia, selectMedia } =
    useUploadMediaContext()

  const [showConfirmationModal, setShowConfirmationModal] = useState(false)
  const [showAttachedImageModal, setShowAttachedImageModal] = useState(false)
  const [popoverOpened, { open, close }] = useDisclosure(false, {
    onOpen: () => {
      setTimeout(() => {
        close()
      }, 3000)
    },
  })

  const form = useForm<EditRedemptionFormShape>({
    initialValues: initialValues || {
      id: '',
      amountInCents: {
        currency: 'USD',
        amount: 1.0,
      },
      limits: [],
      description: '',
      name: '',
      primaryMediaId: undefined,
      validEnd: undefined,
      linkedProfileId: undefined,
      donationGoal: undefined,
    },
    validate: zodResolver(redemptionFormValidation),
    onValuesChange(values, previous) {
      if (
        previous.amountInCents?.amount !== values.amountInCents?.amount &&
        showNonProfitSectionField.getValue()
      ) {
        // Update the donation goal based on the new cost but preserving the same global limit
        const globalLimit = values.limits.find(
          (limit) =>
            limit.limitType === RedemptionLimitType.Count &&
            limit.limitScope === RedemptionLimitScope.Global
        )

        if (globalLimit && form.isTouched('amountInCents.amount')) {
          // Open the popover if the donation goal is set
          open()

          const newDonationGoal =
            (globalLimit.limit as number) *
            (values.amountInCents?.amount as number)

          form.setFieldValue('donationGoal', newDonationGoal)
        }
      }
    },
  })

  const showNonProfitSectionField = useField({
    type: 'checkbox',
    initialValue: isProfileNonProfit || !!form.values.linkedProfileId,
    onValueChange: (value) => {
      if (!value) {
        form.setValues({
          linkedProfileId: undefined,
          donationGoal: undefined,
        })
      }
    },
  })

  const { data: communitiesData } = useCommunitiesDiscoveryQuery({
    variables: {
      query: {
        filters: [
          {
            attribute: CommunityDiscoveryQueryAttributes.IsNonProfit,
            op: PageFilterOp.Eq,
            value: {
              boolean: true,
            },
          },
        ],
      },
    },
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    notifyOnNetworkStatusChange: true,
    skip: isProfileNonProfit,
  })

  const nonProfitCommunities = communitiesData?.discoverCommunities?.data.map(
    (entry) => ({
      label: entry.community.communityName || entry.community.displayName,
      value: entry.community.id,
      image: entry.community.profilePictureUrl,
    })
  )

  const [updateRedemption, { loading: updateRedemptionLoading }] =
    useRedemptionUpdateMutation()

  const handleUpdateRedemption = async (goLive = false) => {
    const valid = form.validate()

    if (valid.hasErrors) {
      notifications.show({
        title: 'Failed to update redemption',
        message: 'Please check the redemption form for errors',
      })

      if (valid.errors && Object.keys(valid.errors).length > 0) {
        const elementWithError = document.querySelector(
          `#${Object.keys(valid.errors)[0]}`
        )
        if (elementWithError) {
          elementWithError.scrollIntoView({
            behavior: 'smooth',
            block: 'center',
          })
        }
      }

      return
    }

    if (
      form.values.name &&
      form.values.description &&
      form.values.amountInCents
    ) {
      try {
        const result = await updateRedemption({
          variables: {
            redemptionId: form.values.id,
            redemption: {
              active: goLive,
              amountInCents: {
                amount: form.values.amountInCents.amount * 100,
                currency: form.values.amountInCents.currency,
              },
              limits: [...form.values.limits],
              description: form.values.description,
              name: form.values.name,
              primaryMediaId: form.values.primaryMediaId,
              validEnd: form.values.validEnd?.toISOString() || null,
              // If the user is a non-profit and the non-profit toggle is enabled, we want to link the redemption to the user's profile
              // Otherwise, we want to link it to the selected non-profit profile
              linkedProfileId:
                showNonProfitSectionField.getValue() && isProfileNonProfit
                  ? currentUser?.id
                  : form.values.linkedProfileId || null,
            },
          },
        })

        if (result.errors || !result.data?.redemptionUpdate.successful) {
          notifications.show({
            title: 'Failed to update redemption',
            message: 'Please check the redemption form for errors',
          })
          return
        }

        if (goLive) {
          notifications.show({
            title: 'Redemption is now live',
            message: 'Redemption is now live and available to members',
          })
          navigate('/vendor/redemptions')
        } else {
          notifications.show({
            title: 'Redemption updated',
            message: 'Redemption has been updated',
          })
          navigate('/vendor/redemptions/drafts')
        }
      } catch (e) {
        notifications.show({
          title: 'Failed to update redemption',
          message: "We couldn't update the redemption",
        })
      }
    }
  }

  useEffect(
    function handleInitialValues() {
      if (initialValues) {
        form.setValues({
          ...initialValues,
          amountInCents: {
            currency: 'USD',
            amount: initialValues.amountInCents?.amount
              ? initialValues.amountInCents.amount / 100
              : 0.0,
          },
          validEnd: initialValues.validEnd,
        })
      }

      const globalLimit = initialValues?.limits.find(
        (limit) =>
          limit.limitType === RedemptionLimitType.Count &&
          limit.limitScope === RedemptionLimitScope.Global
      )

      if (initialValues?.linkedProfileId && globalLimit) {
        form.setFieldValue(
          'donationGoal',
          (globalLimit?.limit as number) *
            ((form.values.amountInCents?.amount as number) / 100)
        )
      }

      showNonProfitSectionField.setValue(!!initialValues?.linkedProfileId)
    },
    // We only want to have this effect run when the initial values changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [initialValues]
  )

  useEffect(
    function handleUpdatingFormWithAttachedMediaPointer() {
      if (attachedMedia && attachedMedia.length > 0) {
        form.setValues({
          ...form.values,
          primaryMediaId: attachedMedia[0].mediaPointerId,
        })
      }
    },
    // We only want to have this effect run when the attachedMedia changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [attachedMedia]
  )

  const hasLocallyAttachedMedia = attachedMedia && attachedMedia.length > 0
  const locallyAttachedMediaIsUploading =
    hasLocallyAttachedMedia && attachedMedia[0].uploadProgress < 100
  const hasPrimaryMedia =
    form.values.primaryMediaId && redemptionImage && !hasLocallyAttachedMedia

  const attachedMediaThumbnail =
    hasLocallyAttachedMedia && attachedMedia[0].mediaType === MediaType.Image
      ? attachedMedia[0].original.localUrl
      : undefined

  function addLimit(
    type: RedemptionLimitType,
    scope: RedemptionLimitScope,
    opts: {
      unit?: RedemptionLimitUnit
      interval?: number
      limit?: number
    } = { limit: 1 }
  ) {
    const { unit, interval, limit } = opts

    form.setValues({
      ...form.values,
      limits: [
        ...form.values.limits,
        {
          limitType: type,
          limitScope: scope,
          unit,
          limit,
          interval,
          // If the limit is per-period, we want to set endOfDay to true because all available units by now are >= day
          ...(RedemptionLimitType.RateLimit === type && { endOfDay: true }),
        },
      ],
    })
  }

  function removeLimit(type: RedemptionLimitType, scope: RedemptionLimitScope) {
    const indexOfLimitToRemove = form.values.limits.findIndex(
      (limit) => limit.limitType === type && limit.limitScope === scope
    )

    if (indexOfLimitToRemove > -1) {
      form.setValues({
        ...form.values,
        limits: [
          ...form.values.limits.slice(0, indexOfLimitToRemove),
          ...form.values.limits.slice(indexOfLimitToRemove + 1),
        ],
      })
    }
  }

  function LimitedQuantityInput() {
    const limitIndex = form.values.limits.findIndex(
      (limit) =>
        limit &&
        limit.limitType &&
        limit.limitType === RedemptionLimitType.Count &&
        limit.limitScope === RedemptionLimitScope.Global
    )

    if (limitIndex === -1 || showNonProfitSectionField.getValue()) {
      return null
    }

    return (
      <Card withBorder>
        <NumberInput
          placeholder="Enter Limit"
          required
          step={1}
          allowNegative={false}
          allowDecimal={false}
          min={1}
          {...form.getInputProps(`limits.${limitIndex}.limit`)}
        />
      </Card>
    )
  }

  const handleDonationGoalOnBlur = () => {
    const amount = form.values.amountInCents?.amount as number
    const donationGoal = form.values.donationGoal

    if (!donationGoal || !amount) return

    const adjustedValue = Math.ceil((donationGoal as number) / amount) * amount

    let limitUpdated = false
    const updatedLimits = form.values.limits.map((limit) => {
      if (
        limit.limitType === RedemptionLimitType.Count &&
        limit.limitScope === RedemptionLimitScope.Global
      ) {
        limitUpdated = true
        return {
          ...limit,
          limit: adjustedValue / amount,
        }
      }

      return limit
    })

    if (!limitUpdated) {
      updatedLimits.push({
        limitType: RedemptionLimitType.Count,
        limitScope: RedemptionLimitScope.Global,
        limit: adjustedValue / amount,
      })
    }

    form.setValues({
      limits: updatedLimits,
      donationGoal: adjustedValue as number,
    })
  }

  const previewDonationProgress = (): RedemptionDonationProgress => {
    const limitEntry = form.values.limits.find(
      (limitEntry) =>
        limitEntry.limitType === RedemptionLimitType.Count &&
        limitEntry.limitScope === RedemptionLimitScope.Global
    )

    if (limitEntry) {
      const goalAmount =
        (limitEntry.limit as number) *
        (form.values.amountInCents?.amount as number) *
        100

      return {
        goal: {
          amount: goalAmount,
          currency: 'USD',
        },
        current: {
          amount: 0,
          currency: 'USD',
        },
        progress: 0,
      }
    } else {
      return {
        goal: undefined,
        current: { amount: 0, currency: 'USD' },
        progress: undefined,
      }
    }
  }

  function LimitedPerPeriodInputs() {
    const limitIndex = form.values.limits.findIndex(
      (limit) =>
        limit &&
        limit.limitType &&
        limit.limitType === RedemptionLimitType.RateLimit &&
        limit.limitScope === RedemptionLimitScope.User
    )

    if (limitIndex === -1) {
      return null
    }

    return (
      <Card withBorder>
        <Group>
          <NumberInput
            placeholder="Enter Limit"
            required
            step={1}
            allowNegative={false}
            allowDecimal={false}
            min={1}
            {...form.getInputProps(`limits.${limitIndex}.limit`)}
          />
          <Select
            data={[
              { label: 'per day', value: RedemptionLimitUnit.Day },
              { label: 'per week', value: RedemptionLimitUnit.Week },
              { label: 'per month', value: RedemptionLimitUnit.Month },
            ]}
            defaultValue={RedemptionLimitUnit.Day}
            placeholder="Select Time Unit"
            {...form.getInputProps(`limits.${limitIndex}.unit`)}
          />
        </Group>
      </Card>
    )
  }

  const selectedLinkedProfile = communitiesData?.discoverCommunities?.data.find(
    (entry: any) => entry.community.id === form.values.linkedProfileId
  )?.community

  return (
    <>
      <Box pos="relative" w="100%">
        <LoadingOverlay visible={redemptionLoading} />
        <form>
          <Stack mb="xl">
            <TextInput
              label={
                <InputLabelSectionHeader>
                  Redemption Name
                </InputLabelSectionHeader>
              }
              description="Add a short, descriptive title to attract Members"
              placeholder="Enter Redemption Name"
              id="name"
              required
              {...form.getInputProps('name')}
            />
            <Box>
              <Group justify="space-between">
                <InputLabelSectionHeader>Cover Image</InputLabelSectionHeader>
                <Text size="sm" c="gray.7">
                  Optional
                </Text>
              </Group>
              {!hasLocallyAttachedMedia && !hasPrimaryMedia && (
                <Button
                  variant="outline"
                  type="button"
                  onClick={() => selectMedia()}
                  fullWidth
                >
                  Add Photo
                </Button>
              )}
              {(hasLocallyAttachedMedia || hasPrimaryMedia) && (
                <Box mt={12}>
                  <Indicator
                    inline
                    label={
                      <FontAwesomeIcon
                        onClick={() => {
                          clearAttachedMedia()
                          form.setValues({
                            ...form.values,
                            primaryMediaId: '',
                          })
                        }}
                        icon={faClose}
                      />
                    }
                    classNames={{
                      indicator: 'h-6 w-6 cursor-pointer',
                    }}
                  >
                    <Image
                      src={
                        hasPrimaryMedia
                          ? redemptionImage?.src
                          : attachedMediaThumbnail
                      }
                      radius="md"
                      w={250}
                      h={150}
                      fit="cover"
                      srcSet={
                        hasPrimaryMedia ? redemptionImage?.srcSet : undefined
                      }
                      alt="Redemption Image"
                      onClick={() => setShowAttachedImageModal(true)}
                    />
                    {hasLocallyAttachedMedia && (
                      <Progress.Root
                        size={20}
                        className="absolute bottom-0 left-0 right-0"
                      >
                        <Progress.Section
                          value={attachedMedia[0].uploadProgress}
                          animated={attachedMedia[0].uploadProgress < 100}
                          color="blue"
                        >
                          {attachedMedia[0].uploadProgress >= 100 && (
                            <Text c="white">Upload Complete</Text>
                          )}
                        </Progress.Section>
                      </Progress.Root>
                    )}
                  </Indicator>
                </Box>
              )}
            </Box>
            <Box>
              <Textarea
                id="description"
                placeholder="Start typing..."
                label={
                  <InputLabelSectionHeader>Description</InputLabelSectionHeader>
                }
                required
                autosize
                minRows={2}
                maxRows={5}
                {...form.getInputProps('description')}
              />
              <Group justify="flex-end" mt={4} align="flex-start">
                <Text
                  size="xs"
                  c={
                    (form.values.description?.length || 0) <
                    MAX_DESCRIPTION_SIZE * 0.8
                      ? 'gray'
                      : 'red'
                  }
                >
                  {form.values.description?.length || 0} /{' '}
                  {MAX_DESCRIPTION_SIZE}
                </Text>
              </Group>
            </Box>
            <Popover
              width={300}
              withArrow
              position="bottom"
              opened={popoverOpened}
            >
              <Popover.Target>
                <NumberInput
                  label={
                    <InputLabelSectionHeader>Cost</InputLabelSectionHeader>
                  }
                  placeholder="Cost in SwayCash"
                  leftSection={<SwayCashIcon className="h-4 w-4" />}
                  required
                  step={1}
                  allowNegative={false}
                  allowDecimal={false}
                  min={1}
                  {...form.getInputProps('amountInCents.amount')}
                />
              </Popover.Target>
              <Popover.Dropdown
                style={{ background: '#000065', color: 'white' }}
              >
                <Text size="xs">Donation goal has been updated</Text>
              </Popover.Dropdown>
            </Popover>
            <Box>
              <InputLabelSectionHeader>Donations</InputLabelSectionHeader>
              <Stack>
                <Switch
                  label="Enable donation options"
                  defaultChecked={!!initialValues?.linkedProfileId}
                  {...showNonProfitSectionField.getInputProps()}
                />
                {showNonProfitSectionField.getValue() && (
                  <DonationGoalInput
                    nonProfitCommunities={nonProfitCommunities}
                    isProfileNonProfit={isProfileNonProfit}
                    handleDonationGoalOnBlur={handleDonationGoalOnBlur}
                    linkedProfileIdValue={
                      form.getInputProps('linkedProfileId').value
                    }
                    linkedProfileIdOnChange={
                      form.getInputProps('linkedProfileId').onChange
                    }
                    donationGoalValue={form.getInputProps('donationGoal').value}
                    donationGoalOnChange={
                      form.getInputProps('donationGoal').onChange
                    }
                    values={form.values}
                    setValues={form.setValues}
                  />
                )}
              </Stack>
            </Box>

            <Box>
              <InputLabelSectionHeader>Limits</InputLabelSectionHeader>
              <Group wrap="nowrap">
                <Stack>
                  <Switch
                    defaultChecked={form.values.validEnd !== undefined}
                    label="Expiration date"
                    onChange={(event) => {
                      event.currentTarget.checked
                        ? form.setValues({
                            ...form.values,
                            validEnd: dayjs(new Date().toISOString())
                              .add(7, 'day')
                              .toDate(),
                          })
                        : form.setValues({
                            ...form.values,
                            validEnd: undefined,
                          })
                    }}
                  />

                  <Box ml={52}>
                    {form.values.validEnd !== undefined && (
                      <Card withBorder>
                        <Text size="sm">This redemption will expire on:</Text>
                        <DateTimePicker
                          leftSection={<FontAwesomeIcon icon={faCalendar} />}
                          minDate={new Date()}
                          valueFormat="DD ddd MMM YYYY hh:mm A"
                          {...form.getInputProps('validEnd')}
                        />
                      </Card>
                    )}
                  </Box>

                  <Switch
                    label="Limited Quantity"
                    disabled={showNonProfitSectionField.getValue()}
                    description={
                      showNonProfitSectionField.getValue()
                        ? 'Disabled for Non-Profit redemptions, please use Donation Goal instead'
                        : 'Total number of times this Redemption can be claimed'
                    }
                    defaultChecked={
                      form.values.limits?.some(
                        (limit) =>
                          limit.limitType === RedemptionLimitType.Count &&
                          limit.limitScope === RedemptionLimitScope.Global
                      ) && !initialValues?.linkedProfileId
                    }
                    onChange={(event) =>
                      event.currentTarget.checked
                        ? addLimit(
                            RedemptionLimitType.Count,
                            RedemptionLimitScope.Global,
                            { limit: 50 }
                          )
                        : removeLimit(
                            RedemptionLimitType.Count,
                            RedemptionLimitScope.Global
                          )
                    }
                  />
                  <Box ml={52}>
                    <LimitedQuantityInput />
                  </Box>

                  <Switch
                    label="Per-Member Limit"
                    defaultChecked={initialValues?.limits.some(
                      (limit) =>
                        limit.limitType === RedemptionLimitType.RateLimit &&
                        limit.limitScope === RedemptionLimitScope.User
                    )}
                    description="Frequency that each member can claim this Redemption"
                    onChange={(event) =>
                      event.currentTarget.checked
                        ? addLimit(
                            RedemptionLimitType.RateLimit,
                            RedemptionLimitScope.User,
                            {
                              unit: RedemptionLimitUnit.Day,
                              interval: 1,
                              limit: 1,
                            }
                          )
                        : removeLimit(
                            RedemptionLimitType.RateLimit,
                            RedemptionLimitScope.User
                          )
                    }
                  />
                  <Box ml={52}>
                    <LimitedPerPeriodInputs />
                  </Box>
                </Stack>
              </Group>
            </Box>
            {/* Auto Confirmation toggle will go here in the future */}
            <Box>
              <InputLabelSectionHeader>
                Redemption Preview
              </InputLabelSectionHeader>
              <Card withBorder>
                <RedemptionMainPreview
                  community={{
                    displayName: 'Community Name',
                  }}
                  hasExpired={dayjs().isAfter(dayjs(form.values.validEnd))}
                  image={{
                    src: attachedMediaThumbnail
                      ? attachedMediaThumbnail
                      : hasPrimaryMedia
                        ? redemptionImage?.src || ''
                        : '',
                    srcSet: attachedMediaThumbnail
                      ? attachedMediaThumbnail
                      : hasPrimaryMedia
                        ? redemptionImage?.srcSet || ''
                        : '',
                  }}
                  limits={
                    (form.values.limits as RedemptionLimitPartsFragment[]) || []
                  }
                  name={form.values.name || ''}
                  price={{
                    amount: Number.isInteger(form.values.amountInCents?.amount)
                      ? form.values.amountInCents?.amount?.toFixed(2) || 0
                      : 0,
                  }}
                  validEnd={form.values.validEnd?.toISOString() || undefined}
                  linkedProfileId={form.values.linkedProfileId}
                  ownerProfileId={currentUser?.id || ''}
                  linkedProfilePhotoUrl={
                    selectedLinkedProfile?.profilePictureUrl
                  }
                  linkedProfileDisplayName={selectedLinkedProfile?.displayName}
                />
                <Text>{form.values.description}</Text>
                {showNonProfitSectionField.getValue() && (
                  <>
                    <Divider my="1rem" />
                    <RedemptionDonationSection
                      donationProgress={previewDonationProgress()}
                      isForDetailsPage={true}
                    />
                  </>
                )}
              </Card>
            </Box>
            <Stack>
              <Button
                type="button"
                disabled={
                  redemptionLoading ||
                  updateRedemptionLoading ||
                  locallyAttachedMediaIsUploading
                }
                onClick={() => setShowConfirmationModal(true)}
              >
                Go Live
              </Button>
              <Button
                type="button"
                variant="outline"
                disabled={
                  redemptionLoading ||
                  updateRedemptionLoading ||
                  locallyAttachedMediaIsUploading
                }
                onClick={() => handleUpdateRedemption(false)}
              >
                Update Draft
              </Button>
              <Button
                type="button"
                variant="outline"
                disabled={redemptionLoading || updateRedemptionLoading}
                onClick={() => {
                  form.reset()
                  navigate('/vendor/redemptions/drafts')
                }}
              >
                Cancel
              </Button>
            </Stack>
          </Stack>
        </form>
      </Box>
      <Modal
        opened={showConfirmationModal}
        onClose={() => setShowConfirmationModal(false)}
        title="Are you sure?"
        size="md"
        centered
      >
        <Text>
          Are you sure you want this redemption to go live? This action cannot
          be undone.
        </Text>
        <Group mt={8} justify="flex-end" align="flex-end">
          <Button
            type="button"
            onClick={() => setShowConfirmationModal(false)}
            variant="outline"
          >
            Cancel
          </Button>
          <Button
            type="button"
            onClick={() => {
              handleUpdateRedemption(true)
              setShowConfirmationModal(false)
            }}
          >
            Go Live!
          </Button>
        </Group>
      </Modal>
      <Modal
        opened={showAttachedImageModal}
        onClose={() => setShowAttachedImageModal(false)}
        title="Attached Image"
        size="md"
        centered
      >
        <Image
          src={attachedMediaThumbnail}
          radius="md"
          h={400}
          fit="contain"
          alt="Redemption Image"
        />
      </Modal>
    </>
  )
}
