import {
  FilterFormValues,
  useRedemptionFiltersContext,
} from '@context/redemptionFiltersContext'
import { faFilter, faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Box, Button, Group, Text, Transition } from '@mantine/core'
import { useDisclosure } from '@mantine/hooks'
import { MediaOption } from '@swaydm/graphql'
import { cn } from '@swaydm/ui'
import { useIsMobileViewport } from '@swaydm/ui/src/hooks/useIsMobileViewport'
import { useEffect, useState } from 'react'
import { RedemptionTabs } from '../RedemptionTabs'
import { RedemptionsFilters } from './RedemptionsFilters'
import { RedemptionsFiltersBadges } from './RedemptionsFiltersBadges'
import { SearchInput } from './SearchInput'

export const RedemptionsPageHeader = ({
  refetchRedemptions,
  handleFiltersFormRefetch,
  setSearchValue,
  searchValue,
  imgixOpts,
  redemptionsLength,
}: {
  refetchRedemptions: (queryArguments: any) => void
  handleFiltersFormRefetch: (newFilters: FilterFormValues) => void
  setSearchValue: React.Dispatch<React.SetStateAction<string>>
  searchValue: string
  imgixOpts: MediaOption[]
  redemptionsLength: number
}) => {
  const [mobileSearchActive, setMobileSearchActive] = useState<boolean>(false)
  const { currentFilters, setFilters, filtersForm } =
    useRedemptionFiltersContext()

  const isMobileViewport = useIsMobileViewport()

  const [opened, { open, close }] = useDisclosure(false, {
    onClose: () => {
      // Submiting the form when the filter drawer is closed
      filtersForm.onSubmit((values: FilterFormValues) => {
        handleFiltersFormRefetch(values)
      })()
    },
  })

  // The redemptions mobile search input should only be enabled on mobile viewports.
  useEffect(() => {
    if (!isMobileViewport) setMobileSearchActive(false)
  }, [isMobileViewport])

  return (
    <Box>
      <Group justify="space-between" px={8} pt={8} gap={0} wrap="nowrap">
        <RedemptionTabs />
        <Button
          miw="auto"
          pr={4}
          ml={'auto'}
          leftSection={<FontAwesomeIcon icon={faFilter} />}
          variant="transparent"
          onClick={open}
        />
        <RedemptionsFilters opened={opened} close={close} />
        {isMobileViewport ? (
          <Button
            p={4}
            variant="transparent"
            radius={0}
            className={cn(
              mobileSearchActive
                ? 'border-0 border-primary text-primary hover:border-b-2'
                : 'border-0 border-transparent text-gray-500 hover:border-gray-200 hover:text-gray-700',
              'flex whitespace-nowrap border-b-2 text-sm font-medium hover:border-b-2'
            )}
            onClick={() => {
              setMobileSearchActive(!mobileSearchActive)
              searchValue &&
                refetchRedemptions({
                  query: {
                    limit: 100,
                  },
                  imgixOpts: imgixOpts,
                })
              setSearchValue('')
            }}
          >
            <FontAwesomeIcon
              icon={faMagnifyingGlass}
              className={'text-primary'}
            />
          </Button>
        ) : (
          <Box>
            <SearchInput
              searchValue={searchValue}
              setSearchValue={setSearchValue}
              placeholder="Search by keyword or name"
            />
          </Box>
        )}
      </Group>
      <Transition mounted={mobileSearchActive}>
        {(styles) => (
          <Box style={styles} px={4} mt={8} mb={4}>
            <SearchInput
              searchValue={searchValue}
              setSearchValue={setSearchValue}
              placeholder="Search by keyword or name"
              showOnFocusConfirmButton={true}
            />
            {searchValue !== '' && (
              <Box mt={8}>
                <Text>
                  Search results for <b>"{searchValue}"</b> ({redemptionsLength}
                  ){' '}
                </Text>
              </Box>
            )}
          </Box>
        )}
      </Transition>

      <RedemptionsFiltersBadges
        currentFilters={currentFilters}
        setFilters={setFilters}
        refetchRedemptions={refetchRedemptions}
        removeFilter={(newFilters: FilterFormValues): void =>
          handleFiltersFormRefetch(newFilters)
        }
        imgixOpts={imgixOpts}
      />
    </Box>
  )
}
