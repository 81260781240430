import { faHeart as faHeartLight } from '@fortawesome/free-regular-svg-icons'
import {
  faClock,
  faHeart as faHeartSolid,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  ActionIcon,
  Avatar,
  Box,
  Button,
  Divider,
  Drawer,
  Flex,
  Group,
  Loader,
  Stack,
  Text,
} from '@mantine/core'
import { useDisclosure } from '@mantine/hooks'
import {
  Redemption,
  useFeedGetSingleRedemptionQuery,
  useRedeemMutation,
  useWalletBalanceQuery,
} from '@swaydm/graphql'
import {
  BOLD,
  RedemptionDonationSection,
  SEMI_BOLD,
  SwayCashIcon,
} from '@swaydm/ui'

import { RedemptionFeedImage } from '@swaydm/ui/src/components/redemption/RedemptionFeedImage'
import { useIsMobileViewport } from '@swaydm/ui/src/hooks/useIsMobileViewport'
import { useSaveRedemption } from '@swaydm/ui/src/hooks/useSaveRedemption'
import { notifications } from '@util/notifications/notifications'
import { isRedemptionAvailable } from '@util/redemptionsUtils'
import { formatRemainingTime } from '@util/time'
import { useNavigate } from 'react-router-dom'

export function RedemptionSuggestion({
  id,
  skip,
}: {
  id: string
  skip: () => void
}) {
  const { data, loading } = useFeedGetSingleRedemptionQuery({
    variables: { id, evaluate: true },
  })
  const { handleSaveRedemption, handleUnsaveRedemption } = useSaveRedemption(id)
  const { data: walletBalanceData } = useWalletBalanceQuery({
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
  })
  const [redeemRedemption, { loading: redeemIsLoading }] = useRedeemMutation()

  const isMobileViewport = useIsMobileViewport()
  const navigate = useNavigate()
  const [
    redeemConfirmationOpened,
    { open: openRedeemConfirmation, close: closeRedeemConfirmation },
  ] = useDisclosure(false)

  const redemption = data?.getRedemption

  const authorProfile = redemption?.vendor
  const hasExpired = !isRedemptionAvailable(redemption as Redemption)

  const hasBalanceToRedeem =
    walletBalanceData?.wallet?.balance || 0 >= (redemption?.price?.amount || 0)

  const redemptionCostFormatted = `${(
    (redemption?.price.amount || 0) / 100
  ).toFixed(2)} SC`

  const handleRedemptionClick = () => {
    if (redemption?.isNonProfit && hasBalanceToRedeem && !hasExpired) {
      openRedeemConfirmation()
    } else {
      navigate(`/redemptions/offers/${redemption?.id}`)
    }
  }

  const handleRedemptionDonation = () => {
    if (!redemption) return

    redeemRedemption({
      variables: {
        redemptionId: redemption.id,
      },
      onCompleted: (result) => {
        if (!result.redeem.successful) {
          notifications.show({
            title: 'Something went wrong with Redeeming Redemption',
            message: "We couldn't redeem your redemption. Please try again.",
          })
          return
        }

        notifications.show({
          title: 'Donation successful',
          message: `You have successfully donated ${redemptionCostFormatted}`,
        })

        closeRedeemConfirmation()
        skip()
      },
      onError: (error) => {
        notifications.show({
          title: 'Something went wrong with Redeeming Redemption',
          message: error.message,
        })
      },
    })
  }

  return (
    <>
      {loading ? (
        <Loader color="swurple" size="xl" type="dots" />
      ) : (
        <Stack
          h="100%"
          w="100%"
          mah="100%"
          gap={0}
          justify="space-between"
          pos="relative"
        >
          <Stack gap={0}>
            <RedemptionFeedImage
              redemption={redemption as Redemption}
              isMobileViewport={isMobileViewport}
              showExpirationSection={!hasExpired}
            />
            <Flex
              px={8}
              py={8}
              justify="space-between"
              align="center"
              className="flex-shrink-0"
            >
              <Flex>
                <Avatar src={authorProfile?.photoUrl} size="md" mr={8} />
                <Stack justify="center" h="100%" gap={0} ml={4}>
                  <Text
                    size={isMobileViewport ? 'sm' : 'md'}
                    fw={SEMI_BOLD}
                    lineClamp={1}
                  >
                    {authorProfile?.name}
                  </Text>
                  <Text size={isMobileViewport ? 'xs' : 'sm'}>
                    <Text span size={isMobileViewport ? 'xs' : 'md'} fw={BOLD}>
                      Featured Offer
                    </Text>
                    {redemption?.validEnd && (
                      <>
                        <FontAwesomeIcon icon={faClock} />
                        <Text
                          span
                          size={isMobileViewport ? 'xs' : 'md'}
                          fw={BOLD}
                          ml={2}
                        >
                          {formatRemainingTime(redemption?.validEnd)}
                        </Text>
                      </>
                    )}
                    {}
                  </Text>
                </Stack>
              </Flex>
              <Flex>
                {redemption?.price.amount && (
                  <Text size={isMobileViewport ? 'lg' : 'xl'} fw={BOLD}>
                    <SwayCashIcon className="mb-1 mr-0.5 inline-block h-5 w-5" />
                    {(redemption.price.amount / 100).toFixed(2)} SC
                  </Text>
                )}
              </Flex>
            </Flex>
            <Stack px={8} py={8} gap={4}>
              <Text fw={SEMI_BOLD} size={isMobileViewport ? 'lg' : 'xl'}>
                {redemption?.name}
              </Text>
              <Divider />
              {redemption?.isNonProfit && (
                <>
                  <RedemptionDonationSection
                    donationProgress={redemption.donationProgress}
                    isForDetailsPage={isMobileViewport ? false : true}
                  />
                  <Divider />
                </>
              )}
              <Text size={isMobileViewport ? 'md' : 'lg'}>
                {redemption?.description}
              </Text>
            </Stack>
          </Stack>

          <Box id={`redemptionFeedCard-${redemption?.id}`}>
            <Stack
              p={16}
              style={{
                boxShadow:
                  '0 -4px 6px -1px rgba(0, 0, 0, 0.1), 0 -2px 4px -1px rgba(0, 0, 0, 0.06)',
              }}
            >
              <Group wrap="nowrap" gap={8}>
                <Button
                  onClick={handleRedemptionClick}
                  fullWidth
                  size={isMobileViewport ? 'compact-md' : 'lg'}
                  radius="sm"
                >
                  {redemption?.isNonProfit && hasBalanceToRedeem && !hasExpired
                    ? `Donate $${redemptionCostFormatted}`
                    : 'View Offer'}
                </Button>
                <ActionIcon
                  size={isMobileViewport ? '30' : '50'}
                  variant="outline"
                  color={'deep-blue'}
                  onClick={
                    redemption?.saved
                      ? handleUnsaveRedemption
                      : handleSaveRedemption
                  }
                >
                  {redemption?.saved ? (
                    <FontAwesomeIcon
                      icon={faHeartSolid}
                      color="deep-blue"
                      size={isMobileViewport ? 'sm' : 'lg'}
                    />
                  ) : (
                    <FontAwesomeIcon
                      icon={faHeartLight}
                      color="deep-blue"
                      size={isMobileViewport ? 'sm' : 'lg'}
                    />
                  )}
                </ActionIcon>
              </Group>
              <Button
                fullWidth
                size={isMobileViewport ? 'compact-md' : 'lg'}
                radius="sm"
                variant="outline"
                c="deep-blue"
                color="deep-blue"
                onClick={() => skip()}
              >
                Not Interested
              </Button>
            </Stack>
            <Drawer.Root
              opened={redeemConfirmationOpened}
              onClose={closeRedeemConfirmation}
              position="bottom"
              size="15%"
              portalProps={{ target: `#redemptionFeedCard-${redemption?.id}` }}
            >
              <Drawer.Overlay backgroundOpacity={0.5} blur={4} pos="absolute" />
              <Drawer.Content
                style={{ width: '100%', position: 'absolute', height: 'auto' }}
              >
                <Drawer.Body h="100%">
                  <Stack justify="center" align="center" h="100%">
                    <Button
                      radius="sm"
                      fullWidth
                      onClick={handleRedemptionDonation}
                      disabled={redeemIsLoading}
                      size={isMobileViewport ? 'compact-md' : 'lg'}
                    >
                      Confirm ${redemptionCostFormatted} Donation
                    </Button>
                    <Button
                      radius="sm"
                      c="deep-blue"
                      color="deep-blue"
                      fullWidth
                      variant="outline"
                      onClick={closeRedeemConfirmation}
                      size={isMobileViewport ? 'compact-md' : 'lg'}
                    >
                      Maybe Later
                    </Button>
                  </Stack>
                </Drawer.Body>
              </Drawer.Content>
            </Drawer.Root>
          </Box>
        </Stack>
      )}
    </>
  )
}
