import { PickedFile } from '@capawesome/capacitor-file-picker'
import {
  AttachedMedia,
  AttachedVideo,
} from '@context/mediaContext/MediaTypes.types'
import { useGetDevice } from '@hooks/useGetDevice'
import { usePickMedia } from '@hooks/usePickMedia'
import {
  MediaPointerStatus,
  MediaType,
  MessagePreviewMediaPointerPartsFragment,
  SignedUploadUrlPartsFragment,
  useConversationEventSubscription,
  useSetMediaPointerStatusMutation,
  useUploadMediaMutation,
} from '@swaydm/graphql'
import { UPLOAD_STATE } from '@swaydm/ui'
import { notifications } from '@util/notifications/notifications'
import { noop } from 'lodash-es'
import React, { createContext, useCallback, useMemo, useState } from 'react'
import {
  handleNativeImage,
  handleVideo,
  handleWebImage,
} from './UploadHandlers'

export interface UploadMediaContextType {
  maxUploads: number
  attachedMedia: AttachedMedia[]
  selectMedia: () => Promise<void>
  addAttachedMedia: (media: AttachedMedia) => void
  clearAttachedMedia: (mediaPointerId?: string) => void
  updateUploadProgress: (mediaPointerId: string, progress: number) => void
}

export const UploadMediaContext = createContext<UploadMediaContextType>({
  maxUploads: 1,
  attachedMedia: [],
  selectMedia: () => Promise.resolve(),
  addAttachedMedia: () => {
    noop()
  },
  clearAttachedMedia: () => {
    noop()
  },
  updateUploadProgress: () => {
    noop()
  },
})

export function UploadMediaContextProvider({
  broadcastId,
  children,
  conversationId,
  imagesOnly,
  maxUploadOverride,
  profileId,
  redemptionId,
  useBoundedQueue = true,
  errorMessageHandlerOverride,
}: {
  broadcastId?: string
  children: React.ReactNode
  conversationId?: string
  imagesOnly?: boolean
  maxUploadOverride?: number
  profileId?: string
  redemptionId?: string
  useBoundedQueue?: boolean
  errorMessageHandlerOverride?: (message: string) => void
}): JSX.Element {
  const maxUploads = maxUploadOverride || 1
  const pickMedia = usePickMedia(imagesOnly)
  const [uploadMedia] = useUploadMediaMutation()
  const deviceInfo = useGetDevice()
  const [attachedMedia, setAttachedMedia] = useState<Array<AttachedMedia>>([])
  const [setMediaPointerStatus] = useSetMediaPointerStatusMutation()

  let errorMessageHandler = useCallback((message: string) => {
    notifications.show({
      title: 'Upload Error',
      message: message,
      color: 'red',
    })
  }, [])

  if (errorMessageHandlerOverride) {
    errorMessageHandler = errorMessageHandlerOverride
  }

  useConversationEventSubscription({
    skip: !conversationId,
    fetchPolicy: 'no-cache',
    variables: { conversationId: conversationId?.toString() || '' },
    onData: ({ data: subscriptionData }) => {
      if (
        subscriptionData.data?.conversationEvent?.__typename === 'MediaEvent'
      ) {
        const mediaPointer = subscriptionData.data.conversationEvent
          .mediaPointer as MessagePreviewMediaPointerPartsFragment

        const media = attachedMedia.find(
          (media) =>
            'mediaPointerId' in media &&
            media.mediaPointerId === mediaPointer.id
        )

        if (mediaPointer.mediaType === 'VIDEO') {
          let uploadState = UPLOAD_STATE.Uploading
          if (
            subscriptionData.data.conversationEvent.mediaType ===
            'MEDIA_AVAILABLE'
          ) {
            uploadState = UPLOAD_STATE.MediaAvailable
            if (media) {
              updateUploadProgress(mediaPointer.id, 100)
            }
          } else if (
            subscriptionData.data.conversationEvent.mediaType === 'UPLOADED'
          ) {
            uploadState = UPLOAD_STATE.Uploaded
          }

          const updatedMedia: AttachedVideo = {
            mediaPointerId: mediaPointer.id,
            uploadProgress: media ? media.uploadProgress : 1,
            mediaType: MediaType.Video,
            thumbnailUrl: mediaPointer.thumbnailAnimated?.url || '',
            originalUrl: mediaPointer.original?.url || '',
            uploadState: uploadState,
          }

          if (media) {
            setAttachedMedia((prev) => {
              const updatedMediaList = prev.map((media) => {
                if ('mediaPointerId' in media) {
                  if (media.mediaPointerId === mediaPointer.id) {
                    return updatedMedia
                  }
                }
                return media
              })
              return updatedMediaList
            })
          } else {
            setAttachedMedia((prev) => [...prev, updatedMedia])
          }
        }
      }
    },
  })

  const setMediaPointerStatusWrapper = useCallback(
    ({
      variables,
    }: {
      variables: {
        mediaPointerId: string
        status: MediaPointerStatus
      }
    }) => {
      setMediaPointerStatus({ variables }).then(() => {
        setAttachedMedia((prev) =>
          prev.map((media) => {
            if (media.mediaPointerId === variables.mediaPointerId) {
              media.uploadState = UPLOAD_STATE.MediaAvailable
            }
            return media
          })
        )
      })
    },
    [setMediaPointerStatus, setAttachedMedia]
  )

  /** Clears attached media and revokes any url's that are local media urls */
  const clearAttachedMedia = useCallback(
    (mediaPointerId?: string) => {
      attachedMedia.forEach((media) => {
        if ('thumbnail' in media) {
          URL.revokeObjectURL(media.thumbnail.localUrl)
          URL.revokeObjectURL(media.original.localUrl)
        }
      })

      if (mediaPointerId) {
        const updatedMedia = attachedMedia.filter(
          (media) => media.mediaPointerId !== mediaPointerId
        )
        setAttachedMedia(updatedMedia)
      } else {
        setAttachedMedia([])
      }
    },
    [attachedMedia, setAttachedMedia]
  )

  /** Adds to the attached media */
  const addAttachedMedia = useCallback(
    (media: AttachedMedia) => {
      setAttachedMedia((prev) => {
        if (prev.length < maxUploads) {
          return [media, ...prev]
        } else if (useBoundedQueue) {
          return [media, ...prev.slice(0, -1)]
        } else {
          errorMessageHandler('Max uploads reached')
          return prev
        }
      })
    },
    [maxUploads, setAttachedMedia, useBoundedQueue, errorMessageHandler]
  )

  /** Update upload progress for a specific media pointer */
  const updateUploadProgress = useCallback(
    (mediaPointerId: string, progress: number) => {
      setAttachedMedia((prev) => {
        const updatedMedia = prev.map((media) => {
          if ('mediaPointerId' in media) {
            if (media.mediaPointerId === mediaPointerId) {
              return {
                ...media,
                uploadProgress: progress,
              }
            }
          }
          return media
        })
        return updatedMedia
      })
    },
    [setAttachedMedia]
  )

  const uploadError = useCallback(
    (mediaPointerId: string) => {
      setAttachedMedia((prev) => {
        const updatedMedia = prev.map((media) => {
          if ('mediaPointerId' in media) {
            if (media.mediaPointerId === mediaPointerId) {
              return {
                ...media,
                uploadState: UPLOAD_STATE.Error,
              }
            }
          }
          return media
        })
        return updatedMedia
      })
    },
    [setAttachedMedia]
  )

  const selectMedia = useCallback(async () => {
    const result = await pickMedia()

    if (!result) return

    const pickedFile: PickedFile = result.files[0]

    if (!pickedFile || !pickedFile.data) return

    const mediaType = pickedFile.mimeType.split('/')[0]

    let uploadMediaMutation

    try {
      const pickedFileMediaType =
        mediaType === 'image'
          ? MediaType.Image
          : mediaType === 'video'
            ? MediaType.Video
            : MediaType.Binary

      const { data } = await uploadMedia({
        variables: {
          broadcastId,
          conversationId,
          filesizeInBytes: pickedFile.size,
          mediaType: pickedFileMediaType,
          mimeType: pickedFile.mimeType,
          name: pickedFile.name,
          profileId,
          redemptionId,
        },
      })
      uploadMediaMutation = data
    } catch (e) {
      console.error('UploadMedia Error', e)
    }

    if (
      !uploadMediaMutation?.uploadMedia.successful ||
      !uploadMediaMutation?.uploadMedia.result
    ) {
      const toastMessage = uploadMediaMutation?.uploadMedia.messages
        ?.map((message) => {
          switch (message?.field) {
            case 'filesizeInBytes':
              return 'Filesize is too large, Max filesize is 30MB for images and 150MB for videos'
            case 'mimeType':
              return 'Unsupported file type'
            default:
              return message?.message
          }
        })
        .join(' ')

      notifications.show({
        title: 'Upload Error',
        message: toastMessage,
        color: 'red',
      })
      return
    }

    const mediaPointerId: string =
      uploadMediaMutation?.uploadMedia.result?.mediaPointer.id
    const uploadUrls: SignedUploadUrlPartsFragment[] =
      uploadMediaMutation?.uploadMedia.result?.uploadUrls

    /**
     * If the media type is an image, we need to handle it differently
     * depending on where the code is running. What is available to us
     * for the file is different depending on the platform. On web, we
     * can use the file directly. On native, we need to read the file
     * first and tehn convert it to a Blob.
     * 🌎 - https://capawesome.io/plugins/file-picker/
     */
    if (mediaType === 'image') {
      if (deviceInfo?.platform === 'web') {
        try {
          handleWebImage({
            setMediaPointerStatus: setMediaPointerStatusWrapper,
            addAttachedMedia,
            updateUploadProgress,
            uploadError,
            pickedFile,
            mediaPointerId,
            uploadUrls,
            mediaType,
          })
        } catch (e) {
          notifications.show({
            title: 'Upload Error',
            message: 'Failed to upload image',
            color: 'red',
          })
          console.error('HandleWebImage Error', e)
        }
      } else {
        try {
          handleNativeImage({
            setMediaPointerStatus: setMediaPointerStatusWrapper,
            addAttachedMedia,
            updateUploadProgress,
            uploadError,
            pickedFile,
            mediaPointerId,
            uploadUrls,
            mediaType,
          })
        } catch (e) {
          notifications.show({
            title: 'Upload Error',
            message: 'Failed to upload image',
            color: 'red',
          })
          console.error('HandleNativeImage Error', e)
        }
      }
    } else if (mediaType === 'video') {
      if (deviceInfo?.platform === 'web') {
        try {
          handleVideo({
            platform: deviceInfo?.platform,
            addAttachedMedia,
            updateUploadProgress,
            uploadError,
            pickedFile,
            mediaPointerId,
            uploadUrls,
          })
        } catch (e) {
          notifications.show({
            title: 'Upload Error',
            message: 'Failed to upload video',
            color: 'red',
          })
          console.error('HandleVideo Error', e)
        }
      } else {
        try {
          handleVideo({
            platform: deviceInfo?.platform || 'unknown',
            addAttachedMedia,
            updateUploadProgress,
            pickedFile,
            mediaPointerId,
            uploadUrls,
            uploadError,
          })
        } catch (e) {
          notifications.show({
            title: 'Upload Error',
            message: 'Failed to upload video',
            color: 'red',
          })
          console.error('HandleVideo Error', e)
        }
      }
    }
  }, [
    pickMedia,
    uploadMedia,
    broadcastId,
    conversationId,
    profileId,
    redemptionId,
    deviceInfo?.platform,
    setMediaPointerStatusWrapper,
    addAttachedMedia,
    updateUploadProgress,
    uploadError,
  ])

  const memoedValue = useMemo(() => {
    return {
      maxUploads: maxUploads,
      attachedMedia: attachedMedia,
      selectMedia: selectMedia,
      addAttachedMedia: addAttachedMedia,
      clearAttachedMedia: clearAttachedMedia,
      updateUploadProgress: updateUploadProgress,
    }
  }, [
    maxUploads,
    attachedMedia,
    selectMedia,
    addAttachedMedia,
    clearAttachedMedia,
    updateUploadProgress,
  ])

  return (
    <UploadMediaContext.Provider value={memoedValue}>
      {children}
    </UploadMediaContext.Provider>
  )
}
