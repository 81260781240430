import { Anchor, Flex, Image } from '@mantine/core'
import AppStoreBadge from '../../../../../apps/creators/src/assets/app-store-badge.png'
import GooglePlayBadge from '../../../../../apps/creators/src/assets/google-play-badge.png'

export const AppStoreLinks = () => {
  return (
    <Flex align="center" gap="md">
      <Anchor
        href="https://link.sway.dm/e/app-store/android"
        target="_blank"
        inherit
      >
        <Image src={GooglePlayBadge} w={175} alt="Get it on Google Play" />
      </Anchor>
      <Anchor
        href="https://link.sway.dm/e/app-store/ios"
        target="_blank"
        inherit
      >
        <Image src={AppStoreBadge} w={175} alt="Get it on Apple App Store" />
      </Anchor>
    </Flex>
  )
}
