import { AccessToken, useCreateAccessTokenMutation } from '@swaydm/graphql'
import { useCallback } from 'react'
import { useAuth } from './useAuth'

export const useRefreshAuthToken = () => {
  const [createAccessToken] = useCreateAccessTokenMutation()
  const { currentUser, onLogin } = useAuth()

  const accessToken = currentUser?.accessToken
  const tokenExpiresAt = new Date(accessToken?.validUntil || '').getTime()

  const handleNewAccessToken = useCallback(
    async (newAccessToken: AccessToken) => {
      if (currentUser) {
        onLogin({
          newUser: {
            ...currentUser,
            accessToken: newAccessToken,
          },
        })
      }
    },
    [currentUser, onLogin]
  )

  if (!accessToken) {
    return
  }

  // If the token is valid, but half the time to expiration is passed,
  // refresh the token by creating a new one
  if (
    accessToken.validForSeconds &&
    tokenExpiresAt - Date.now() < (accessToken?.validForSeconds / 2) * 1000 // Convert seconds to milliseconds
  ) {
    createAccessToken().then(
      ({ data }) => {
        if (data?.createAccessToken) {
          handleNewAccessToken(data.createAccessToken)
        }
      },
      (error) => {
        console.error('error refreshing the token', error)
      }
    )
  }
}
