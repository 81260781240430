export async function base64ToArrayBuffer(
  data: string | Blob
): Promise<ArrayBuffer> {
  let base64: string

  if (data instanceof Blob) {
    base64 = await blobToBase64(data)
  } else {
    base64 = data
  }

  const binaryString = window.atob(base64)
  const len = binaryString.length
  const bytes = new Uint8Array(len)
  for (let i = 0; i < len; i++) {
    bytes[i] = binaryString.charCodeAt(i)
  }

  return bytes.buffer
}

function blobToBase64(blob: Blob): Promise<string> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.onload = function () {
      resolve((reader.result as string).split(',')[1]) // Split on comma to remove the MIME type at the beginning of the data URI
    }
    reader.onerror = reject
    reader.readAsDataURL(blob)
  })
}
