import { Center } from '@mantine/core'
import { ErrorBoundary } from 'react-error-boundary'
import { RequireMember } from '../../routes/guards/RequireMember'

export const FeedRoutes = [
  {
    path: 'feed/',
    async lazy() {
      const { FeedPage } = await import('./Feed.page')
      return {
        element: (
          <ErrorBoundary fallback={<Center>Something went wrong</Center>}>
            <RequireMember>
              <FeedPage />
            </RequireMember>
          </ErrorBoundary>
        ),
      }
    },
  },
]
