import { Redemption, SignedUrlKey } from '@swaydm/graphql'
import { MessageRedemption } from '@swaydm/ui/src/components/messages/Interactions/MessageRedemption'

export function FeedRedemptionPreview({
  redemption,
}: {
  redemption: Redemption
}) {
  const downloadUrls = redemption.primaryMedia?.downloadUrls
  const thumbnailUrl = downloadUrls
    ? downloadUrls.find((url) => url.key === SignedUrlKey.Thumbnail)?.url
    : undefined

  return (
    <MessageRedemption
      name={redemption.name}
      price={redemption.price.amount || undefined}
      thumbnailUrl={thumbnailUrl}
      vendorPhotoUrl={redemption.vendor?.photoUrl}
    />
  )
}
