import { useAuth } from '@hooks/useAuth'
import { Box, Center, Group, Stack, Text, Title } from '@mantine/core'
import { ProfileType, RedemptionLimitPartsFragment } from '@swaydm/graphql'
import { SEMI_BOLD, SwayCashIcon } from '@swaydm/ui'
import { RedemptionImage } from '@swaydm/ui/src/components/redemption/RedemptionImage'
import dayjs from 'dayjs'
import duration from 'dayjs/plugin/duration'
import { Link } from 'react-router-dom'
dayjs.extend(duration)

export const RedemptionMainPreview = ({
  community,
  hasExpired,
  image,
  limits,
  name,
  price,
  validEnd,
  linkedProfileId,
  linkedProfilePhotoUrl,
  linkedProfileDisplayName,
  ownerProfileId,
  distance,
  saved,
  handleSaveRedemption,
  handleUnsaveRedemption,
}: {
  community: {
    displayName: string
  }
  hasExpired: boolean
  image: {
    src: string
    srcSet: string
  }
  limits?: RedemptionLimitPartsFragment[]
  name: string
  price: {
    amount: string | number
  }
  validEnd: string | undefined | null
  linkedProfileId?: string | null
  linkedProfilePhotoUrl?: string | null
  linkedProfileDisplayName?: string | null
  ownerProfileId: string
  distance?: string
  saved?: boolean
  handleSaveRedemption?: () => void
  handleUnsaveRedemption?: () => void
}) => {
  const { currentUser } = useAuth()

  const isCurrentUser = !!currentUser

  const isVendor = currentUser?.profileType === ProfileType.Community

  const src = image.src
  const srcSet = image.srcSet

  return (
    <Stack>
      <Center className="overflow-hidden">
        <Box pos="relative">
          <RedemptionImage
            src={src || undefined}
            srcSet={srcSet || undefined}
            isExpired={hasExpired}
            limits={limits}
            validEnd={validEnd}
            linkedProfileId={linkedProfileId}
            linkedProfilePhotoUrl={linkedProfilePhotoUrl}
            linkedProfileDisplayName={linkedProfileDisplayName}
            ownerProfileId={ownerProfileId}
            saved={saved}
            handleSaveRedemption={handleSaveRedemption}
            handleUnsaveRedemption={handleUnsaveRedemption}
            showSaveSection={isCurrentUser && !isVendor}
          />
        </Box>
      </Center>

      <Stack w="100%">
        <Box>
          <Title order={4} className="line-clamp-2">
            {name}
          </Title>
          <Group align="center">
            <Text size="sm">
              By&nbsp;
              <Link to={`/profile/${community.displayName}`}>
                {community.displayName}
              </Link>
            </Text>
            {distance && (
              <Text size="sm" c="dimmed">
                {distance}
              </Text>
            )}
          </Group>
        </Box>

        {price.amount && (
          <Group gap={0}>
            <SwayCashIcon className="h-4 w-4" />
            <Text fw={SEMI_BOLD}>&nbsp;{price.amount} SC</Text>
          </Group>
        )}
      </Stack>
    </Stack>
  )
}
