import { Capacitor } from '@capacitor/core'
import { PermissionStatus } from '@capacitor/geolocation'
import { isEqual } from 'lodash-es'
import { useCallback } from 'react'
import useGeolocationStore from '../stores/useGeolocationStore'
import useAnalytics from './useAnalytics'

export const useGeolocation = () => {
  const analytics = useAnalytics()

  const emitLocationPermission = useCallback(
    (locationPermission: PermissionStatus) => {
      analytics.locationPermission({
        locationPermission: locationPermission.location,
        platform: Capacitor.getPlatform() as 'ios' | 'android' | 'web',
      })
    },
    [analytics]
  )

  const {
    position,
    lastPositionTimestamp,
    setPermissions,
    setPermissionsChecked,
    permissions,
    permissionsChecked,
    error,
    setError,
    updatePosition,
    updateLastPositionTimestamp,
  } = useGeolocationStore((state) => ({
    position: state.position,
    lastPositionTimestamp: state.lastPositionTimestamp,
    setPermissions: state.setPermissions,
    setPermissionsChecked: state.setPermissionsChecked,
    permissions: state.permissions,
    permissionsChecked: state.permissionsChecked,
    error: state.error,
    setError: state.setError,
    updatePosition: state.updatePosition,
    updateLastPositionTimestamp: state.updateLastPositionTimestamp,
  }))

  const handleSetPermissions = (newPermissions: PermissionStatus) => {
    setPermissions(newPermissions)
    // * Only if permissions are different from the previous permissions
    if (!isEqual(permissions, newPermissions)) {
      emitLocationPermission(newPermissions)
    }
  }

  return {
    position,
    lastPositionTimestamp,
    permissions,
    permissionsChecked,
    error,
    setError,
    setPermissionsChecked,
    updatePosition,
    updateLastPositionTimestamp,
    setPermissions: handleSetPermissions,
  }
}
