import { faQrcode } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Text } from '@mantine/core'
import React from 'react'
import { SwayIcon } from '../..'

export const GetAppButtonSmall = React.forwardRef<
  HTMLButtonElement,
  React.HTMLAttributes<HTMLButtonElement>
>(({ ...props }, ref) => {
  return (
    <Button size="icon" aria-label="Open Mobile App" ref={ref} {...props}>
      <FontAwesomeIcon icon={faQrcode} className="h-5 w-5" />
    </Button>
  )
})

export const GetAppButtonFull = React.forwardRef<
  HTMLButtonElement,
  React.HTMLAttributes<HTMLButtonElement>
>(({ ...props }, ref) => {
  return (
    <Button aria-label="Open Mobile App" ref={ref} {...props}>
      <SwayIcon className="h-6 w-6" />
      <Text size="sm" pl={16}>
        Get the App
      </Text>
    </Button>
  )
})
