import { Group, Tabs, TabsList } from '@mantine/core'
import { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

const TAB_NAMES = {
  OFFERS: 'Offers',
  VENDORS: 'Vendors',
  SAVED: 'Saved',
}

export const RedemptionTabs = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const [activeTab, setActiveTab] = useState<string>(
    location.pathname.includes('vendors')
      ? TAB_NAMES.VENDORS
      : location.pathname.includes('offers')
        ? TAB_NAMES.SAVED
        : TAB_NAMES.OFFERS
  )

  useEffect(() => {
    if (location.pathname.includes('vendors')) {
      setActiveTab(TAB_NAMES.VENDORS)
    } else if (location.pathname.includes('saved')) {
      setActiveTab(TAB_NAMES.SAVED)
    } else {
      setActiveTab(TAB_NAMES.OFFERS)
    }
  }, [location.pathname])

  const handleTabsChange = (value: string | null) => {
    if (value === null) return
    if (value === TAB_NAMES.OFFERS) return navigate(`/redemptions`)
    if (value === TAB_NAMES.VENDORS) return navigate(`/redemptions/vendors`)
    if (value === TAB_NAMES.SAVED) return navigate(`/redemptions/saved`)
  }

  const tabStyles = {
    tab: {
      padding: 'var(--mantine-spacing-xs) var(--mantine-spacing-xs)',
    },
  }

  return (
    <Tabs value={activeTab} onChange={handleTabsChange} mr={20}>
      <TabsList>
        <Group wrap="nowrap">
          <Tabs.Tab style={tabStyles.tab} value={TAB_NAMES.OFFERS}>
            {TAB_NAMES.OFFERS}
          </Tabs.Tab>
          <Tabs.Tab style={tabStyles.tab} value={TAB_NAMES.VENDORS}>
            {TAB_NAMES.VENDORS}
          </Tabs.Tab>
          <Tabs.Tab style={tabStyles.tab} value={TAB_NAMES.SAVED}>
            {TAB_NAMES.SAVED}
          </Tabs.Tab>
        </Group>
      </TabsList>
    </Tabs>
  )
}
