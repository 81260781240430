import { useAuth } from '@hooks/useAuth'
import { Modal } from '@mantine/core'
import { useForm } from '@mantine/form'
import { AccessToken, ProfileType, useLoginMutation } from '@swaydm/graphql'
import { LoginForm } from '@swaydm/ui'
import { useCallback } from 'react'

export function LoginModal({
  opened,
  onClose,
}: {
  opened: boolean
  onClose: () => void
}) {
  const { currentUser, onLogin } = useAuth()
  const [login] = useLoginMutation()

  const form = useForm<{
    email: string
    password: string
  }>({
    initialValues: {
      email: '',
      password: '',
    },
  })

  const handleLogin = useCallback(
    async function handleLoginCallback({
      email,
      password,
    }: {
      email: string
      password: string
    }) {
      if (email === currentUser?.email) {
        form.setErrors({
          email: ' ',
          password: 'Already logged in',
        })
        return
      }

      try {
        const { data } = await login({ variables: { email, password } })

        if (data?.login.user && data.login.user.id) {
          onLogin({
            newUser: {
              id: data.login.user.id,
              accessToken: data.login.accessToken as AccessToken,
              communityName: data.login.user.profile?.communityName || '',
              displayName: data.login.user.profile?.displayName || '',
              email: data.login.user.email || '',
              firstName: data.login.user.profile?.firstName || '',
              lastName: data.login.user.profile?.lastName || '',
              profileType: data.login.user.profile?.profileType as ProfileType,
              profilePictureUrl:
                data.login.user.profile?.profilePictureUrl || '',
              impersonator: data.login.impersonator && {
                id: data.login.impersonator?.id || '',
                email: data.login.impersonator?.email || '',
              },
              magicLink: '',
            },
          })
          form.reset()
          onClose()
        } else {
          form.setErrors({
            email: ' ',
            password: 'Invalid email or password',
          })
        }
      } catch (error) {
        console.error('error logging in: ', error)
        form.setErrors({
          email: ' ',
          password: 'Wrong email or password',
        })
        return Promise.reject(error)
      }
    },
    [currentUser?.email, form, login, onClose, onLogin]
  )

  return (
    <Modal opened={opened} onClose={onClose} title="Login">
      <LoginForm form={form} onSubmit={handleLogin} hideRegistration />
    </Modal>
  )
}
