import { Redemption, RedemptionLimitScope } from '@swaydm/graphql'
import dayjs from 'dayjs'

/**
 * Check if a redemption is expired based on the validEnd date or the global limit
 * @param redemption
 * @returns boolean
 */
export function isRedemptionExpired(redemption: Redemption): boolean {
  if (!redemption) {
    return true
  }

  if (redemption.validEnd) {
    if (dayjs().isAfter(dayjs(redemption.validEnd))) {
      return true
    }
  }

  if (redemption.limits?.limits) {
    const { limits } = redemption.limits
    for (const limit of limits) {
      if (
        limit.limitScope == RedemptionLimitScope.Global &&
        redemption.redeemCount >= limit.limit
      ) {
        return true
      }
    }
  }

  return false
}

/**
 * Check if a redemption is available based on the validEnd date and the evaluation of the limits
 * @param redemption
 * @returns boolean
 */
export function isRedemptionAvailable(redemption: Redemption): boolean {
  if (!redemption) {
    return false
  }

  if (redemption.validEnd) {
    if (dayjs().isAfter(dayjs(redemption.validEnd))) {
      return false
    }
  }

  if (redemption.limits?.limits) {
    const { limits } = redemption.limits
    for (const limit of limits) {
      if (limit.blocking) {
        return false
      }
    }
  }

  return true
}
