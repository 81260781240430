import { Group, Stack, Text } from '@mantine/core'
import {
  Profile,
  RedemptionDonationProgress,
  RedemptionMediaPreviewPartsFragment,
} from '@swaydm/graphql'
import { RedemptionDonationSection, SwayCashIcon } from '../..'
import { useIsMobileViewport } from '../../hooks/useIsMobileViewport'
import { useSaveRedemption } from '../../hooks/useSaveRedemption'
import { RedemptionImage } from './RedemptionImage'

type RedemptionHorizontalCardProps = {
  id: string
  name: string
  description: string
  community: {
    profilePhotoUrl: string
    communityName: string
  }
  price: {
    amount: number
  }
  isNonProfit: boolean
  donationProgress?: RedemptionDonationProgress | null
  redemptionMedia: RedemptionMediaPreviewPartsFragment
  linkedProfile?: Profile | null
  isExpired?: boolean
  ownerProfileId: string
  hasLimits?: boolean
  distance?: string
  saved?: boolean
  showSaveSection: boolean
}

export const RedemptionHorizontalCard = ({
  id,
  name,
  description,
  community,
  price,
  redemptionMedia,
  isNonProfit,
  donationProgress,
  linkedProfile,
  isExpired,
  ownerProfileId,
  hasLimits,
  distance,
  saved,
  showSaveSection = false,
}: RedemptionHorizontalCardProps) => {
  const isMobile = useIsMobileViewport()
  const { handleSaveRedemption, handleUnsaveRedemption } = useSaveRedemption(id)

  return (
    <Group wrap="nowrap" w={'100%'}>
      <Group className="flex-shrink-0" pos={'relative'} gap={0}>
        <RedemptionImage
          isForCard={true}
          profilePhotoUrl={community.profilePhotoUrl}
          isExpired={isExpired}
          src={redemptionMedia.primaryMedia?.proxy?.url}
          srcSet={redemptionMedia.primaryMedia?.proxySrcSet?.url}
          linkedProfileId={linkedProfile?.id}
          linkedProfilePhotoUrl={linkedProfile?.profilePhotoUrl}
          linkedProfileDisplayName={linkedProfile?.displayName}
          ownerProfileId={ownerProfileId}
          saved={saved}
          handleSaveRedemption={handleSaveRedemption}
          handleUnsaveRedemption={handleUnsaveRedemption}
          hasLimits={hasLimits}
          showSaveSection={showSaveSection}
        />
      </Group>

      <Stack
        miw={0}
        justify="space-between"
        gap={0}
        w={'100%'}
        align="start"
        mih={isMobile ? 105 : 128}
        h={'100%'}
      >
        <Stack gap={4} w={'100%'} pr={2}>
          <Text size="lg" className="font-bold" lineClamp={1} visibleFrom="md">
            {name}
          </Text>
          <Text size="md" className="font-bold" lineClamp={1} hiddenFrom="md">
            {name}
          </Text>
          {isNonProfit && (
            <RedemptionDonationSection donationProgress={donationProgress} />
          )}
          {!isNonProfit && (
            <>
              <Text size="sm" lineClamp={2} visibleFrom="md">
                {description}
              </Text>
              <Text size="xs" lineClamp={2} hiddenFrom="md">
                {description}
              </Text>
            </>
          )}
        </Stack>
        {price.amount && (
          <Stack gap={0} w={'100%'}>
            <Text size="xs" lineClamp={1} className="mb-1" visibleFrom="md">
              {community.communityName}
            </Text>
            <Text size="xs" lineClamp={1} className="mb-1" hiddenFrom="md">
              {community.communityName}
            </Text>

            <Group justify={'space-between'} w={'100%'}>
              <Group gap={0}>
                <SwayCashIcon className="mr-2 h-4 w-4" />
                <Text size="sm">{(price.amount / 100).toFixed(2)} SC</Text>
              </Group>

              {!!distance && (
                <Text size="sm" className="ml-2" c={'dimmed'}>
                  {distance}
                </Text>
              )}
            </Group>
          </Stack>
        )}
      </Stack>
    </Group>
  )
}
