import { Capacitor } from '@capacitor/core'
import { PushNotifications, Token } from '@capacitor/push-notifications'
import useAppContext from '@context/appContext/useAppContext'
import useAnalytics from '@hooks/useAnalytics'
import { useAuth } from '@hooks/useAuth'
import { PushService, useAddDeviceTokenMutation } from '@swaydm/graphql'
import { notifications } from '@util/notifications/notifications'
import { createContext, useCallback, useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import useNotificationSettingsContext from '../../pages/settings/screens/notificationSettings/context/useNotificationSettingsContext'

const isNativePlatform = Capacitor.isNativePlatform()

interface PushNotificationsContextType {
  deviceHasPushNotificationsEnabled: boolean
  turnOnPushNotifications: () => Promise<void>
}

export const PushNotificationsContext =
  createContext<PushNotificationsContextType>({
    deviceHasPushNotificationsEnabled: false,
    turnOnPushNotifications: () => Promise.resolve(),
  })

export const PushNotificationsContextProvider = ({
  children,
}: {
  children: React.ReactNode
}): JSX.Element => {
  const { currentUser, allUsers, switchUser } = useAuth()
  const { getNotificationPreferencesForUser, notificationPreferences } =
    useNotificationSettingsContext()
  const { deviceId, deviceInfo } = useAppContext()
  const { pushNotificationAction } = useAnalytics()
  const navigate = useNavigate()

  const [pushNotificationsToken, setPushNotificationsToken] =
    useState<string>('')
  const [
    deviceHasPushNotificationsEnabled,
    setDeviceHasPushNotificationsEnabled,
  ] = useState<boolean>(false)

  const [addDeviceToken] = useAddDeviceTokenMutation()

  const register = useCallback(() => {
    if (isNativePlatform === false) {
      console.info('Push notifications are not supported in the browser')
      return
    }

    PushNotifications.removeAllListeners()

    if (pushNotificationsToken === '' || !pushNotificationsToken) {
      // Register with Apple / Google to receive push via APNS/FCM
      void PushNotifications.register()
    }

    // Clear old notifications
    void PushNotifications.removeAllDeliveredNotifications()

    // On success, we should be able to receive notifications
    void PushNotifications.addListener('registration', (token: Token) => {
      setPushNotificationsToken(token.value)
    })

    void PushNotifications.addListener('registrationError', (error) => {
      console.error('Error on registration: ' + JSON.stringify(error))
      setDeviceHasPushNotificationsEnabled(false)
    })

    void PushNotifications.addListener(
      'pushNotificationReceived',
      (notification) => {
        console.info('Push received: ', notification)
      }
    )

    void PushNotifications.addListener(
      'pushNotificationActionPerformed',
      (actionPerformed) => {
        const userId = actionPerformed.notification?.data?.any?.user_id
        const route = actionPerformed.notification?.data?.any?.route
        const { actionId: action } = actionPerformed

        pushNotificationAction(
          {
            userId,
            route,
            event: 'action-performed',
            action,
          },
          {
            additional_properties: {
              notification_id: actionPerformed.notification.id,
              knock_workflow:
                actionPerformed.notification?.data?.knock_workflow,
              knock_message_id:
                actionPerformed.notification?.data?.knock_message_id,
            },
          }
        )

        if (currentUser?.id && userId && currentUser.id !== userId) {
          switchUser(userId)
        }

        if (route) {
          navigate(route)
        }
      }
    )

    setDeviceHasPushNotificationsEnabled(true)
  }, [
    pushNotificationAction,
    pushNotificationsToken,
    currentUser?.id,
    switchUser,
    navigate,
  ])

  useEffect(
    function registerPushNotificationsEffect() {
      if (isNativePlatform === false) return
      if (!notificationPreferences) return

      const isImpersonating = !!currentUser?.impersonator?.id
      if (isImpersonating) return

      const isPushEnabled = notificationPreferences?.channels?.find(
        (c) => c?.type === 'push' && c?.enabled
      )

      if (isPushEnabled && currentUser && currentUser.accessToken) {
        void PushNotifications.checkPermissions().then((res) => {
          if (res.receive === 'granted') {
            register()
          } else {
            void PushNotifications.requestPermissions().then((res) => {
              if (res.receive === 'denied') {
                notifications.show({
                  title: 'Push notifications disabled on your device',
                  message: 'You can turn them on in your phone settings',
                  color: 'red',
                  autoClose: 10000,
                })
              } else {
                notifications.show({
                  title: 'Push notifications enabled',
                  message: 'Push notifications access has been enabled',
                  color: 'green',
                  autoClose: 2000,
                })
                register()
              }
            })
          }
        })
      }
    },
    // ! We only want to update if the values in notificationPreferences change
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [notificationPreferences]
  )

  useEffect(
    function handleLogoutEffect() {
      if (!currentUser && allUsers.length === 0) {
        void PushNotifications.unregister()
          .then(() => {
            console.info('Push notifications unregistered')
          })
          .catch((err) => {
            console.error('Error unregistering push notifications: ', err)
          })
      }
    },
    [allUsers, currentUser]
  )

  useEffect(
    function updatePushNotificationsTokenEffect() {
      if (Capacitor.isNativePlatform() === false) return
      if (!pushNotificationsToken) return
      if (!currentUser) return

      const updatePushNotificationsToken = async () => {
        allUsers?.forEach(async (user) => {
          if (!user.id) return

          const userNotificationPreferences =
            await getNotificationPreferencesForUser(user.id)

          if (!userNotificationPreferences) return
          if (
            userNotificationPreferences.notificationPreferences?.channels?.find(
              (c) => c?.type === 'push' && c?.enabled
            )
          ) {
            await addDeviceToken({
              context: {
                headers: {
                  authorization: `Bearer ${user.accessToken?.value}`,
                },
              },
              variables: {
                deviceToken: {
                  deviceId: deviceId,
                  deviceName: deviceInfo?.name,
                  deviceToken: pushNotificationsToken,
                  service:
                    deviceInfo?.platform === 'ios'
                      ? PushService.Apns
                      : PushService.Fcm,
                },
              },
              onCompleted(data) {
                if (
                  data.addDeviceToken.successful &&
                  user.id === currentUser.id
                ) {
                  notifications.show({
                    title: 'Push notifications enabled',
                    message: 'You will now receive push notifications',
                    color: 'green',
                  })
                }
              },
              onError(err) {
                console.error('Error adding device token: ', err)
              },
            })
          }
        })
      }

      updatePushNotificationsToken()
    },
    [
      addDeviceToken,
      allUsers,
      currentUser,
      deviceId,
      deviceInfo?.name,
      deviceInfo?.platform,
      getNotificationPreferencesForUser,
      pushNotificationsToken,
    ]
  )

  const turnOnPushNotifications = useCallback(async () => {
    // Not implemented
    return Promise.resolve()
  }, [])

  const memoedValue = useMemo(() => {
    return {
      deviceHasPushNotificationsEnabled,
      turnOnPushNotifications,
    }
  }, [deviceHasPushNotificationsEnabled, turnOnPushNotifications])

  return (
    <PushNotificationsContext.Provider value={memoedValue}>
      {children}
    </PushNotificationsContext.Provider>
  )
}
