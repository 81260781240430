import {
  faCircleCheck,
  faCircleExclamation,
  faClock,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Box, Button, Stack, Text } from '@mantine/core'
import {
  InteractionStatus,
  Money,
  PollMediaOption,
  PollOption,
  PollOptionType,
  PollTextOption,
  UserAnswer,
} from '@swaydm/graphql'
import { SEMI_BOLD, SwayCashIcon, timeDifference } from '../../../..'
import { Direction } from '../message.types'
import { InteractionTimeRemaining } from './InteractionTimeRemaining'

type AllPollOptions = PollOption | PollTextOption | PollMediaOption | null

type PollInteractionProps = {
  direction: Direction
  earningPotential?: Money
  expiresAt: string
  options: Array<AllPollOptions>
  receiver: {
    firstName: string
    lastName: string
    id: string
  }
  status: InteractionStatus
  userAnswer?: UserAnswer
  onPollOptionSelected: (optionId: string) => void
}

export const PollInteraction = ({
  direction,
  earningPotential,
  expiresAt,
  options,
  receiver,
  status,
  userAnswer,
  onPollOptionSelected,
}: PollInteractionProps) => {
  return (
    <>
      {direction === 'incoming' && status === InteractionStatus.Pending && (
        <PendingPollInteraction
          earningPotential={earningPotential}
          expiresAt={expiresAt}
          options={options}
          onPollOptionSelected={onPollOptionSelected}
        />
      )}
      {direction === 'incoming' && status === InteractionStatus.Complete && (
        <CompletePollInteraction
          earningPotential={earningPotential}
          userAnswer={userAnswer}
        />
      )}
      {direction === 'incoming' && status === InteractionStatus.Expired && (
        <ExpiredPollInteraction
          earningPotential={earningPotential}
          expiresAt={expiresAt}
          options={options}
        />
      )}
      {direction === 'outgoing' && status === InteractionStatus.Pending && (
        <OutgoingPendingPollInteraction
          earningPotential={earningPotential}
          expiresAt={expiresAt}
          receiver={receiver}
        />
      )}
      {direction === 'outgoing' && status === InteractionStatus.Complete && (
        <OutgoingCompletePollInteraction
          earningPotential={earningPotential}
          receiver={receiver}
          userAnswer={userAnswer}
        />
      )}
      {direction === 'outgoing' && status === InteractionStatus.Expired && (
        <OutgoingExpiredPollInteraction />
      )}
    </>
  )
}

function PendingPollInteraction({
  earningPotential,
  expiresAt,
  options,
  onPollOptionSelected,
}: {
  earningPotential?: Money
  expiresAt: string
  options: Array<AllPollOptions>
  onPollOptionSelected: (optionId: string) => void
}) {
  return (
    <>
      {!!earningPotential?.amount && (
        <Box p={8}>
          <InteractionTimeRemaining
            earningPotential={earningPotential}
            expiresAt={expiresAt}
          />
        </Box>
      )}
      <Stack px={8} pb={20}>
        {options.map((option, index) => {
          if (!option) return null

          switch (option.type) {
            case PollOptionType.PollText:
              return (
                <Button
                  variant="outline"
                  onClick={() => onPollOptionSelected(option.id)}
                  key={`interaction-${index}`}
                >
                  <Text truncate="end" ta="center">
                    {(option as PollTextOption).text}
                  </Text>
                </Button>
              )
            default:
              return null
          }
        })}
      </Stack>
    </>
  )
}

function CompletePollInteraction({
  earningPotential,
  userAnswer,
}: {
  earningPotential?: Money
  userAnswer?: UserAnswer
}) {
  if (!userAnswer) return null

  return (
    <Text size="sm" className="my-2 flex flex-wrap items-center p-2">
      <Text span inline c="green.8">
        <FontAwesomeIcon
          icon={faCircleCheck}
          className="mr-1 inline-block h-5 w-5"
        />
      </Text>
      {userAnswer.type === PollOptionType.PollText && (
        <>
          You responded
          <Text span c="green.8" className="mx-1" fw={SEMI_BOLD}>
            "{(userAnswer as PollTextOption).text}"
          </Text>
          {earningPotential?.amount && (
            <>
              and earned
              <SwayCashIcon className="mx-1 inline-block h-5 w-5" />
              <Text span fw={SEMI_BOLD}>
                {(earningPotential?.amount / 100).toFixed(2)}
              </Text>
            </>
          )}
        </>
      )}
    </Text>
  )
}

function ExpiredPollInteraction({
  earningPotential,
  expiresAt,
  options,
}: {
  earningPotential?: Money
  expiresAt: string
  options: Array<AllPollOptions>
}) {
  return (
    <>
      {earningPotential?.amount && (
        <Text
          size="sm"
          className="my-2 flex flex-row items-center p-2 text-destructive"
        >
          <FontAwesomeIcon
            icon={faCircleExclamation}
            className="mr-1 h-5 w-5"
          />
          Expired at{' '}
          {new Date(expiresAt).toLocaleString('en-US', {
            year: 'numeric',
            month: 'short',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            hour12: true,
          })}
        </Text>
      )}
      <Box className="flex min-w-0 flex-row flex-wrap gap-x-2 gap-y-2 px-2 py-4">
        {options.map((option, index) => {
          if (!option) return null

          switch (option.type) {
            case PollOptionType.PollText:
              return (
                <Button
                  variant="outline"
                  disabled
                  className="min-w-0"
                  key={`interaction-${index}`}
                >
                  <Text
                    size="sm"
                    truncate="end"
                    ta="center"
                    className="min-w-0"
                  >
                    {(option as PollTextOption).text}
                  </Text>
                </Button>
              )
            default:
              return null
          }
        })}
      </Box>
    </>
  )
}

function OutgoingPendingPollInteraction({
  earningPotential,
  expiresAt,
  receiver,
}: {
  earningPotential?: Money
  expiresAt: string
  receiver: {
    firstName: string
    lastName: string
    id: string
  }
}) {
  const expiresIn = timeDifference(expiresAt)

  if (!earningPotential?.amount) return null

  return (
    <>
      <Text
        size="sm"
        c="white"
        className="my-2 flex flex-wrap items-center p-2"
      >
        <FontAwesomeIcon icon={faClock} className="mr-1 inline-block h-5 w-5" />
        {receiver.firstName} has
        <Text span className="mx-1 font-bold">
          {`${expiresIn.hours} hours ${expiresIn.minutes} minutes `}
        </Text>
        remaining to reply to your message.
      </Text>
    </>
  )
}

function OutgoingCompletePollInteraction({
  earningPotential,
  receiver,
  userAnswer,
}: {
  earningPotential?: Money
  receiver: {
    firstName: string
    lastName: string
    id: string
  }
  userAnswer?: UserAnswer
}) {
  if (!userAnswer) return null
  if (userAnswer.type !== PollOptionType.PollText) return null

  return (
    <Text size="sm" className="my-2 flex flex-wrap items-center p-2" c="white">
      <FontAwesomeIcon icon={faCircleCheck} className="mr-1 h-5 w-5" />
      {receiver.firstName} responded with
      <Text span mx={4} fw={SEMI_BOLD}>
        "{(userAnswer as PollTextOption).text}"
      </Text>
      and you paid
      {earningPotential?.amount && (
        <>
          <SwayCashIcon color="white" className="mx-1 inline-block h-5 w-5 " />
          <Text span fw={SEMI_BOLD}>
            {(earningPotential?.amount / 100).toFixed(2)}
          </Text>
        </>
      )}
    </Text>
  )
}

function OutgoingExpiredPollInteraction() {
  return (
    <Text size="sm" className="my-2 flex flex-wrap items-center p-2">
      <FontAwesomeIcon
        icon={faCircleExclamation}
        className="inline-block h-5 w-5"
      />
      <Text span mx={4} fw={SEMI_BOLD}>
        Message Expired
      </Text>
      and you were not charged
    </Text>
  )
}
