import { faLocationDot } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Divider, Stack, Text, Title } from '@mantine/core'
import { SwayCashIcon } from '@swaydm/ui'
import { ButtonsLocationPermissionModal } from './ButtonsLocationPermissionModal'

const LocationPermissionModalCopy = ({ ...props }) => {
  return (
    <Text {...props}>
      SwayDM works best with your location! We can customize offers and maximize
      your SwayCash earnings. We never share your location with businesses or
      any third parties.
    </Text>
  )
}

const MainTextAndIcon = ({ fullscreen }: { fullscreen: boolean }) => {
  return (
    <>
      <FontAwesomeIcon
        icon={faLocationDot}
        color="white"
        className={fullscreen ? 'mb-8 h-24 w-24' : 'h-24 w-24'}
      />
      <Title order={4} c={'white'} className="text-center">
        Share your location with SwayDM
      </Title>
      <LocationPermissionModalCopy
        c={'white'}
        size="sm"
        className="text-center"
      />
    </>
  )
}

export const LocationPermissionModalContent = ({
  fullscreen = false,
  onClose,
  requestLocationPermission,
}: {
  fullscreen?: boolean
  onClose: () => void
  requestLocationPermission: () => Promise<any>
}) => {
  return (
    <>
      <SwayCashIcon
        allFilled={true}
        className="absolute inset-0 m-auto h-80 w-80 scale-200 overflow-hidden opacity-10"
        color="white"
      />
      <Stack
        {...(fullscreen
          ? { h: '100%', px: 12, justify: 'center', pt: '15vh', gap: 0 }
          : { p: 24, gap: 24 })}
        align="center"
      >
        {fullscreen ? (
          <Stack align="center" gap={14}>
            <MainTextAndIcon fullscreen={fullscreen} />
          </Stack>
        ) : (
          <MainTextAndIcon fullscreen={fullscreen} />
        )}
        <Stack w={'100%'} {...(fullscreen ? { mb: 60 } : {})} gap={12}>
          <Divider color="swurple.4" w={'100%'} my={16} />
          <ButtonsLocationPermissionModal
            onClose={onClose}
            requestLocationPermission={requestLocationPermission}
          />
        </Stack>
      </Stack>
    </>
  )
}
