import { useState } from 'react'
import { Skeleton } from '../../..'

interface MediaPreviewProps {
  alt?: string
  thumbnailUrl: string
  thumbnailSrcSet?: string
}

export const MediaPreview = ({
  alt,
  thumbnailUrl,
  thumbnailSrcSet,
}: MediaPreviewProps) => {
  const [isThumbnailLoaded, setIsThumbnailLoaded] = useState(false)

  return (
    <div className="flex justify-center">
      {!isThumbnailLoaded && (
        <Skeleton
          className="absolute left-0 top-0 h-48 rounded-none"
          style={{ zIndex: 1 }}
        >
          {/* <div className="flex items-center justify-center h-full">
            <LoadingAnimated className="h-28 w-28" />
          </div> */}
        </Skeleton>
      )}
      <img
        src={thumbnailUrl}
        srcSet={thumbnailSrcSet}
        alt={alt}
        className="h-48 object-fill object-center"
        style={{ opacity: isThumbnailLoaded ? 1 : 0 }}
        onLoad={() => setIsThumbnailLoaded(true)}
      />
    </div>
  )
}
