export const PublicProfileRoutes = [
  {
    path: 'profile/:displayName',
    async lazy() {
      const { PublicVendorProfilePage } = await import(
        './PublicVendorProfilePage'
      )
      return {
        Component: PublicVendorProfilePage,
      }
    },
  },
]

export const ProfileRoutes = [
  {
    path: 'profile/:displayName',
    async lazy() {
      const { ProfilePage } = await import('./ProfilePage')
      return {
        Component: ProfilePage,
      }
    },
  },
]
