import { faTriangleExclamation } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Box, Group, Text } from '@mantine/core'
import { SEMI_BOLD, cn } from '../..'
import { Direction } from './message.types'

type MessageProps = {
  children: React.ReactNode
  direction?: Direction
  hasEarningOpportunity?: boolean
  sentBySway?: boolean
}

export const MessageContainer = ({
  children,
  direction,
  hasEarningOpportunity,
  sentBySway,
}: MessageProps) => {
  const backgroundColor =
    direction === 'outgoing'
      ? 'var(--outgoing-message-bg)'
      : 'var(--incoming-message-bg)'

  const textColor =
    direction === 'outgoing'
      ? 'var(--outgoing-message-text-color)'
      : 'var(--incoming-message-text-color)'

  const roundedClass = cn(
    direction === 'outgoing' ? 'rounded-br-none' : 'rounded-bl-none'
  )

  return (
    <Box
      bg={backgroundColor}
      c={textColor}
      className={cn([
        'relative inline-block overflow-hidden rounded-2xl',
        roundedClass,
        direction === 'incoming' &&
          hasEarningOpportunity &&
          'border-2 border-success',
      ])}
    >
      <div className="grid h-full w-full grid-cols-1">
        {children}

        {sentBySway && (
          <Group
            bg={'yellow.4'}
            w={'100%'}
            py={4}
            pl={12}
            pr={direction === 'outgoing' ? 10 : 16}
            gap={6}
            align="center"
            c={'#313233'}
          >
            <FontAwesomeIcon icon={faTriangleExclamation} size="xs" />
            <Text size="xs" fw={SEMI_BOLD} mt={1}>
              Sent by the team at SwayDM
            </Text>
          </Group>
        )}
      </div>
    </Box>
  )
}
